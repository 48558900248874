// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCNwZRlPulE4ehpvruk6nYMJSGR54UxRDw",
    authDomain: "gdg-baguio-website.firebaseapp.com",
    databaseURL: "https://gdg-baguio-website.firebaseio.com",
    projectId: "gdg-baguio-website",
    storageBucket: "gdg-baguio-website.appspot.com",
    messagingSenderId: "460307722954",
    appId: "1:460307722954:web:1ad547887935b5a7073a33",
    measurementId: "G-WD4ZTNW4YD"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
