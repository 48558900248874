import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../../material/material.module';
import { OngoingEventsModule } from 'src/app/components/events/ongoing-events/ongoing-events.module';
import { UpcomingEventsModule } from 'src/app/components/events/upcoming-events/upcoming-events.module';
import { RecentEventsModule } from 'src/app/components/events/recent-events/recent-events.module';
import { FooterModule } from 'src/app/components/footer/footer.module';

import { EventsComponent } from './events.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    OngoingEventsModule,
    UpcomingEventsModule,
    RecentEventsModule,
    FooterModule
  ],
  exports: [EventsComponent],
  declarations: [EventsComponent],
})
export class EventsModule {}
