import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';

import { path } from 'src/app/model/constants';

import { DashboardComponent } from './site/dashboard/dashboard.component';
import { HomeComponent } from './site/home/home.component';
import { CommunityComponent } from './site/community/community.component';
import { EventsComponent } from './site/events/events.component';
import { EventDetailsComponent } from './site/events/details/event-details.component';
import { TeamComponent } from './site/team/team.component';
import { PageNotFoundComponent } from './site/404/page-not-found.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo([path.HOME]);

const routes: Routes = [
  // { path: '', component: DashboardComponent },
  {
    path: path.DASHBOARD,
    component: DashboardComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  { path: path.HOME, redirectTo: '', pathMatch: 'full' },
  {
    path: path.ROOT,
    component: HomeComponent, // children: [{ path: 'asd', component: HomeComponent }],
  },
  { path: path.COMMUNITY, component: CommunityComponent },
  { path: path.EVENTS, component: EventsComponent },
  { path: path.EVENTBYID, component: EventDetailsComponent },
  { path: path.TEAM, component: TeamComponent },

  // otherwise redirect to 404 page /
  { path: path.ANY, component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
