<div class="site-article">
  <section class="article-row">
    <div class="row-group">
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex>
          <h1>The page cannot be found.</h1>
          <h3>Perharps you are here because:</h3>
          <p>You like 404 pages</p> <br>
        </div>
      </div>
    </div>
  </section>
</div>