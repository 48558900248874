import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventProperties } from 'src/app/model/interface';
import { svgpaths } from 'src/app/model/constants';

@Component({
  selector: 'upcoming-event',
  templateUrl: 'upcoming-events.component.html',
})
export class UpcomingEventsComponent implements OnInit {
  @Input() upcoming_events: EventProperties[];
  islandingpage: boolean;
  featured_event_photo = svgpaths.featured_event;
  constructor(private router: Router) {}

  ngOnInit() {
    if (this.router.url == '/' || this.router.url.includes('#')) {
      this.islandingpage = true;
    }
  }
}
