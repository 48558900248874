import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EventProperties } from 'src/app/model/interface';
import { svgpaths } from 'src/app/model/constants';

@Component({
  selector: 'ongoing-event',
  templateUrl: 'ongoing-events.component.html',
})
export class OngoingEventsComponent {
  @Input() ongoing_events: EventProperties[];
  meetup_card_svg = '/assets/svg/active-event-card.svg';
  islandingpage: boolean;
  featured_event_photo = svgpaths.featured_event;
  constructor(private router: Router) {}

  ngOnInit() {
    if (this.router.url == '/' || this.router.url.includes('#')) {
      this.islandingpage = true;
    }
  }
}
