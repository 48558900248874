<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="50px">
  <div *ngFor="let recent_event of recent_events" fxFlex>
    <mat-card>
      <img mat-card-image src={{meetup_card_svg}} class="meetup-card-svg" alt="Meetup card" fxHide.sm fxHide.xs>
      <mat-card-header>
        <mat-card-title>
          <a href="events/{{recent_event.id}}">{{recent_event.name}}</a>
        </mat-card-title>
        <mat-card-subtitle>{{recent_event.local_date}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content [innerHTML]="recent_event.description"></mat-card-content>
      <mat-card-actions>
        <a *ngIf="recent_event.is_online_event" mat-flat-button href="{{recent_event.how_to_find_us}}" target="_blank">
          <mat-icon class="watch-icon-button" aria-hidden="false" aria-label="play icon">play_arrow</mat-icon>Watch Session
        </a>
        <a mat-flat-button href="events/{{recent_event.id}}">Read more</a>
      </mat-card-actions>
    </mat-card>
  </div>
</div>