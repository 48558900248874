<div class="site-article">
  <section class="article-row">
    <ng-container *ngIf="ongoing_events?.length">
      <div class="row-group">
        <h1>Ongoing events</h1>
        <ongoing-event [ongoing_events]="ongoing_events"></ongoing-event>
      </div>
    </ng-container>
    <ng-container *ngIf="upcoming_events?.length">
      <div class="row-group">
        <h1>Upcoming events</h1>
        <upcoming-event [upcoming_events]="upcoming_events">
        </upcoming-event>
      </div>
    </ng-container>
    <ng-container *ngIf="!ongoing_events?.length && !upcoming_events?.length">
      <div class="row-group">
        <h1>Upcoming events</h1>
        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex>
            <h3>There are no upcoming events at the moment. Please check again soon.</h3>
          </div>
        </div>
      </div>
    </ng-container>
  </section>
  <section class="article-row">
    <div class="row-group">
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex>
          <h1>Recent events</h1>
        </div>
      </div>
      <ng-container *ngIf="recent_events">
        <recent-events [recent_events]="recent_events"></recent-events>
      </ng-container>
      <ng-template #loadingRecentEvent>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="50px">
          <div *ngFor="let i of [].constructor(4)" fxFlex>
            <mat-card>
              <img mat-card-image src={{meetup_card_svg}} alt="Meetup card">
              <mat-card-header>
                <mat-card-title>
                  <span>-------------------------</span>
                </mat-card-title>
                <mat-card-subtitle>---------------</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                ---------------------------------------------------------------------------------------------------------------------------------------
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </ng-template>
    </div>
  </section>
  <section class="article-row">
    <div class="row-group">
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex>
          <h1>Directory of past events</h1>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="50px">
        <ng-container *ngIf="!past_events?.length">
          <div fxFlex>
            <p>
              -----
            </p>
          </div>
        </ng-container>
        <div *ngIf="past_events" fxFlex>
          <mat-table [dataSource]="past_events" class="mat-elevation-z1">
            <ng-container matColumnDef="local_date">
              <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.local_date}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef> Event </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <a href="events/{{element.id}}">{{element.name}}</a>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="venue.name">
              <mat-header-cell *matHeaderCellDef fxHide.xs> Venue </mat-header-cell>
              <mat-cell *matCellDef="let element" fxHide.xs> {{element.venue.name}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="venue.address_1">
              <mat-header-cell *matHeaderCellDef fxHide.sm fxHide.xs> Address </mat-header-cell>
              <mat-cell *matCellDef="let element" fxHide.sm fxHide.xs>
                <ng-container *ngIf="element.is_online_event">
                  <a href="{{element.how_to_find_us}}" target="_blank">Watch Session</a>
                </ng-container>
                <ng-container *ngIf="!element.is_online_event">
                  {{element.venue.address_1}}
                </ng-container>
              </mat-cell>

              <!-- <mat-cell *ngIf="!element.is_online_event" *matCellDef="let element"> {{element.venue.address_1}} </mat-cell> -->
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsWeb"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsWeb;"></mat-row>
          </mat-table>
        </div>
      </div>
    </div>
  </section>
  <footer></footer>
</div>