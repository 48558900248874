import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
// import { MatMenu } from '@angular/material/menu';

@Component({
  selector: 'signedin-account-menu-card',
  templateUrl: 'signedin-account-menu-card.component.html',
})
export class SignedInAccountMenuComponent {
  @Input() user: firebase.User;
  @Output() logoutClick: EventEmitter<null> = new EventEmitter<null>();
  // @Input()
  // xPosition: MenuPositionX;
  // @ViewChild('signedinAccountMenu') public menu: MatMenu;

  // OnInit() {
  //   // this.menu.overlayPanelClass('qwerty');
  // }

  logout() {
    this.logoutClick.emit();
  }
}
