import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../../material/material.module';

import { PageNotFoundComponent } from './page-not-found.component';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MaterialModule],
  exports: [PageNotFoundComponent],
  declarations: [PageNotFoundComponent],
})
export class PageNotFoundModule {}
