import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'footer',
  templateUrl: 'footer.component.html',
})
export class FooterComponent implements OnInit {
  logo = '/assets/logo/gdgbaguio-logo.svg';
  firebase = '/assets/firebase.ico';
  angular = '/assets/angular.ico';
  mdc = '/assets/mdc.ico';
  year: string;
  // pageurl: string;

  constructor(private router: Router) {}

  ngOnInit() {
    var d = new Date();
    this.year = d.getFullYear().toString();
    // this.pageurl = this.router.url.slice(1);
    console.log(this.router.url);
    // console.log(this.pageurl);

    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();
        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
        });
      });
    });

    if (this.router.url != '/') {
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.addEventListener('click', function (e) {
          // e.preventDefault();
          document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth',
          });
        });
      });
    }
  }
}
