import { Component, OnInit } from '@angular/core';
import { MaterialComponentService } from 'src/app/material/material-component.service';
import { FirestoreService } from '../../core/firestore.service';
import { Event, EventProperties } from '../../model/interface';
import { event_status } from 'src/app/model/constants';
import { map, catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'events',
  templateUrl: 'events.component.html',
})
export class EventsComponent implements OnInit {
  events: Event[];
  ongoing_events: EventProperties[];
  upcoming_events: EventProperties[];
  past_events: Event[];
  recent_events: Event[];

  meetup_card_svg = '/assets/svg/inactive-event-card.svg';

  displayedColumnsWeb: string[] = [
    'local_date',
    'name',
    'venue.name',
    'venue.address_1',
  ];

  displayedColumnsHandset: string[] = ['local_date', 'name'];

  constructor(
    private materialComponentService: MaterialComponentService,
    private firestoreService: FirestoreService
  ) {}

  ngOnInit() {
    this.getEventsCollection();
  }

  getEventsCollection() {
    this.firestoreService
      .getEventsCollection()
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            key: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        ),
        catchError((error) => {
          this.materialComponentService.errorSnackBar(error);
          return EMPTY;
        })
      )
      .subscribe((allEvents) => {
        this.events = allEvents.reverse();
        this.ongoing_events = this.getOngoingUpcomingEvents(
          this.events,
          event_status.ongoing_status
        ).reverse();
        this.upcoming_events = this.getOngoingUpcomingEvents(
          this.events,
          event_status.upcoming_status
        ).reverse();
        this.past_events = this.getPastEvents(this.events);

        this.recent_events = this.getRecentEvents(this.past_events);
      });
  }

  getOngoingUpcomingEvents(arr: Event[], status) {
    return this.firestoreService.getOngoingUpcomingEvents(arr, status);
  }

  getPastEvents(arr: Event[]) {
    return this.firestoreService.getPastEvents(arr);
  }

  getRecentEvents(arr: Event[]) {
    return this.firestoreService.getRecentEvents(arr);
  }
}
