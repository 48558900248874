import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from '../material/material.module';
import { TopBarModule } from '../components/top-bar/top-bar.module';
import { DashboardModule } from '../site/dashboard/dashboar.module';
import { HomeModule } from '../site/home/home.module';
import { CommunityModule } from '../site/community/community.module';
import { EventsModule } from '../site/events/events.module';
import { EventDetailsModule } from '../site/events/details/event-details.module';
import { TeamModule } from '../site/team/team.module';
import { FooterModule } from 'src/app/components/footer/footer.module';
import { PageNotFoundModule } from '../site/404/page-not-found.module';

import { ContainerComponent } from './container.component';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    MaterialModule,
    TopBarModule,
    DashboardModule,
    HomeModule,
    CommunityModule,
    EventsModule,
    EventDetailsModule,
    TeamModule,
    FooterModule,
    PageNotFoundModule,
  ],
  exports: [ContainerComponent],
  declarations: [ContainerComponent],
})
export class ContainerModule {}
