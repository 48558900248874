import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BreakpointObserverService } from 'src/app/core/breakpointObserver.service';
import { FirestoreService } from 'src/app/core/firestore.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormDialogData } from 'src/app/model/interface';
import { breakpointView, applicationType } from 'src/app/model/constants';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'application-form',
  templateUrl: 'application-form.component.html',
})
export class ApplicationFormComponent implements OnInit {
  breakpointView = breakpointView;
  applicationFormgroup: FormGroup;
  speakerRequestFormgroup: FormGroup;
  applicationType = applicationType;

  user: any;
  constructor(
    private breakpointObserverService: BreakpointObserverService,
    private firestoreService: FirestoreService,
    private _formBuilder: FormBuilder,
    private readonly snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ApplicationFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormDialogData
  ) {
    // this.user$.subscribe((user) => {
    //   // this.data.fullname = user.displayName;
    //   // this.data.email = user.email;
    //   // console.log('SIGNEDIN USER EMAIL', user);
    // });

    this.breakpointView = this.breakpointObserverService.breakpointView;
  }

  ngOnInit() {
    this.applicationFormgroup = this._formBuilder.group({
      formtype: [this.data.formtype],
      uid: [this.data.uid],
      fullname: [this.data.fullname, Validators.required],
      email: [this.data.email, Validators.email],
      organization: ['', Validators.required],
      position: ['', Validators.required],
      mobilenumber: ['', Validators.required],
    });

    this.speakerRequestFormgroup = this._formBuilder.group({
      formtype: [this.data.formtype],
      uid: [this.data.uid],
      email: [this.data.email, Validators.email],
      eventname: ['', Validators.length],
      location: ['', Validators.required],
      description: ['', Validators.required],
      audience: ['', Validators.required],
      audiencenumber: ['', Validators.required],
      techtodiscuss: ['', Validators.required],
      startdate: ['', Validators.required],
      enddate: ['', Validators.required],
    });
  }

  submitApplicationRequest(applicationFormgroup) {
    this.firestoreService.createApplicationRequest(applicationFormgroup.value);
    this.onNoClick();
  }

  submitSpeakerApplicationRequest(speakerRequestFormgroup) {
    this.firestoreService.createApplicationRequest(
      speakerRequestFormgroup.value
    );
    this.onNoClick();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
