import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/material/material.module';
// import { MatIcon } from '@angular/material/icon';
// import { MatCardModule } from '@angular/material/card';
// import { MatButtonModule } from '@angular/material/button';

import { RecentEventsComponent } from './recent-events.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    // MatIcon,
    // MatCardModule,
    // MatButtonModule,
  ],
  exports: [RecentEventsComponent],
  declarations: [RecentEventsComponent],
})
export class RecentEventsModule {}
