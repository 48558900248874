export const path = {
  ROOT: '',
  HOME: 'home',
  COMMUNITY: 'community',
  EVENTS: 'events',
  EVENTBYID: 'events/:eventId',
  TEAM: 'team',
  DASHBOARD: 'dashboard',
  ANY: '**',
};

export const breakpointLayout = {
  handsetportrait: 'handsetportrait',
  handsetlandscape: 'handsetlandscape',
  weblandscape: 'weblandscape',
  webportrait: 'webportrait',
  tabletportrait: 'tabletportrait',
  tabletlandscape: 'tabletlandscape',
};

export const breakpointView = {
  breakpointView: '',
  handset: false,
  tablet: false,
  web: false,
};

export const event_status = {
  ongoing_status: 'ongoing',
  upcoming_status: 'upcoming',
  recent_status: 'recent',
  past_status: 'past',
  draft_status: 'draft',
};

export const applicationType = {
  womentechmakers: 'womentechmakers',
  speaker: 'speaker',
  volunteer: 'volunteer',
  sponsorship: 'sponsorship',
};

export const svgpaths = {
  featured_event: '/assets/svg/gdg-featured-event.svg',
  what_we_are_about_svg: '/assets/svg/what-we-are-about.svg',
  wtm_svg: '/assets/svg/wtm-logo-horiz-rgb.svg',
  team_svg: '/assets/svg/meet-the-team.svg',
  fb_svg: '/assets/svg/facebook.svg',
  twitter_svg: '/assets/svg/twitter.svg',
  instagram_svg: '/assets/svg/instagram.svg',
};

export const dialogdataplaceholder = {
  uid: 'notsignedin',
  displayName: '',
  email: '',
};