import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MaterialModule } from 'src/app/material/material.module';

import { TopBarComponent } from './top-bar.component';
import { SignedInAccountMenuComponent } from 'src/app/components/signedin-account-menu-card/signedin-account-menu-card.component';

@NgModule({
  imports: [CommonModule, RouterModule, MaterialModule],
  exports: [TopBarComponent],
  declarations: [TopBarComponent, SignedInAccountMenuComponent],
})
export class TopBarModule {}
