import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../../../material/material.module';
import { FooterModule } from 'src/app/components/footer/footer.module';

import { EventDetailsComponent } from './event-details.component';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MaterialModule, FooterModule],
  exports: [EventDetailsComponent],
  declarations: [EventDetailsComponent],
})
export class EventDetailsModule {}
