import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/material/material.module';

import { UpcomingEventsComponent } from './upcoming-events.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    // MatIcon,
    // MatCardModule,
    // MatButtonModule,
  ],
  exports: [UpcomingEventsComponent],
  declarations: [UpcomingEventsComponent],
})
export class UpcomingEventsModule {}
