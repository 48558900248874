import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'team',
  templateUrl: './team.component.html'
})

export class TeamComponent implements OnInit {
  van_photo = 'https://raw.githubusercontent.com/error900/gdg-baguio-team/master/account-photo/van.jpg';
  kim_photo = 'https://raw.githubusercontent.com/error900/gdg-baguio-team/master/account-photo/kim.jpg';
  macs_photo = 'https://raw.githubusercontent.com/error900/gdg-baguio-team/master/account-photo/macs.jpg';
  janine_photo = 'https://raw.githubusercontent.com/error900/gdg-baguio-team/master/account-photo/janine.jpg';
  karl_photo = 'https://raw.githubusercontent.com/error900/gdg-baguio-team/master/account-photo/karl.jpg';
  vj_photo = 'https://raw.githubusercontent.com/error900/gdg-baguio-team/master/account-photo/vj.jpg';
  yanyan_photo = 'https://raw.githubusercontent.com/error900/gdg-baguio-team/master/account-photo/yanyan.jpg';
  clint_photo = 'https://raw.githubusercontent.com/error900/gdg-baguio-team/master/account-photo/clint.jpg';
  clifford_photo = 'https://raw.githubusercontent.com/error900/gdg-baguio-team/master/account-photo/clifford.jpg';
  roselle_photo = 'https://raw.githubusercontent.com/error900/gdg-baguio-team/master/account-photo/roselle.jpg';
  reymart_photo = 'https://raw.githubusercontent.com/error900/gdg-baguio-team/master/account-photo/reymart.jpg';
  bryan_photo = 'https://raw.githubusercontent.com/error900/gdg-baguio-team/master/account-photo/bryan.jpg';
  kurt_photo = 'https://raw.githubusercontent.com/error900/gdg-baguio-team/master/account-photo/kurt.jpg';
  jude_photo = 'https://raw.githubusercontent.com/error900/gdg-baguio-team/master/account-photo/jude.jpg';
  
  constructor() { }
  
  ngOnInit() { }
}