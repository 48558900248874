import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { MaterialComponentService } from 'src/app/material/material-component.service';
import { FirestoreService } from 'src/app/core/firestore.service';
import { catchError, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { EventProperties, Event } from 'src/app/model/interface';

@Component({
  selector: 'event-details',
  templateUrl: 'event-details.component.html',
})
export class EventDetailsComponent implements OnInit {
  eventid: string;
  event: any;
  eventHosts: any;
  eventdetails: EventProperties;

  constructor(
    private route: ActivatedRoute,
    private materialComponentService: MaterialComponentService,
    private firestoreService: FirestoreService
  ) {}

  ngOnInit() {
    this.eventid = this.route.snapshot.params.eventId;
    this.getEvent(this.eventid);
  }

  getEvent(event_id: string) {
    this.firestoreService
      .getEventDetails(event_id)
      .get()
      .pipe(
        take(1),
        catchError((error) => {
          this.materialComponentService.errorSnackBar(error);
          return EMPTY;
        })
      )
      .subscribe((event) => {
        var eventp = {} as any;
        var eventButtons = {} as any;
        eventp.event = event.data();

        const date = new Date(eventp.event.local_date).toDateString();
        eventp.event.local_date = date;

        eventp.event.rsvp_limit < eventp.event.yes_rsvp_count ||
        (eventp.event.rsvp_limit == 0 && eventp.event.yes_rsvp_count == 0)
          ? (eventButtons['registration'] = true) &&
            (eventButtons['waitlist'] = false)
          : eventp.event.rsvp_limit == eventp.event.yes_rsvp_count &&
            !(eventp.event.rsvp_limit == 0 && eventp.event.yes_rsvp_count == 0)
          ? (eventButtons['waitlist'] = true) &&
            (eventButtons['registration'] = false)
          : console.log('none');

        if (eventp.event.is_online_event) {
          eventButtons['waitlist'] = false;
          eventButtons['registration'] = false;
        }

        eventp.properties = eventButtons;
        this.eventdetails = eventp;
      });
    this.getEventHosts(event_id);
  }

  getEventHosts(event_id: string) {
    this.firestoreService
      .getEventHosts(event_id)
      .get()
      .pipe(
        take(1),
        catchError((error) => {
          this.materialComponentService.errorSnackBar(error);
          return EMPTY;
        })
      )
      .subscribe((eventHosts) => {
        this.eventHosts = eventHosts.data();
        // console.log('this.eventHosts---', eventHosts.data());
      });
  }
}
