<div class="signedin-account-menu-button">
  <button mat-icon-button color="primary" [matMenuTriggerFor]="signedinAccountMenu">
    <img [alt]="user.displayName" [src]="user.photoURL" class="signedin-avatar" />
  </button>
  <mat-menu #signedinAccountMenu="matMenu" backdropClass="signedin-account-mat-menu" xPosition="before">
    <div class="signedin-account-menu-card">
      <img [alt]="user.displayName" [src]="user.photoURL" class="account-photo" />
      <h3 class="display-name">{{ user.displayName }}</h3>
      <p class="mat-caption email-address">{{ user.email }}</p>
      <mat-divider></mat-divider>
      <a mat-flat-button href="https://myaccount.google.com/?utm_source=OGB&utm_medium=act" target="_blank">Manage your Google account</a>
      <mat-divider></mat-divider>
      <button mat-stroked-button (click)="logout()">Sign out</button>
      <!-- <p class="menu-footer">
        GDG Baguio
      </p> -->
    </div>
  </mat-menu>
</div>