import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/material/material.module';

import { OngoingEventsComponent } from './ongoing-events.component';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MaterialModule],
  exports: [OngoingEventsComponent],
  declarations: [OngoingEventsComponent],
})
export class OngoingEventsModule {}
