import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/material/material.module';

import { DashboardComponent } from './dashboard.component';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MaterialModule],
  exports: [DashboardComponent],
  declarations: [DashboardComponent],
})
export class DashboardModule {}
