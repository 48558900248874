<ng-container *ngIf="!eventdetails">

</ng-container>
<ng-container *ngIf="eventdetails as eventInfo">
  <div class="site-article">
    <section class="article-row event-details-banner">
      <div class="row-group">
        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex class="content">
            <h1 class="mat-display-1">{{ eventInfo.event.name }}</h1>
            <div class="items">
              <ng-container *ngIf="eventInfo.event.is_online_event">
                <h3 style="margin: 0 0 0 12px;">{{ eventInfo.event.local_date }}</h3>
                <mat-chip-list aria-label="tag" class="tags">
                  <mat-chip>{{eventInfo.event.venue.name}}</mat-chip>
                </mat-chip-list>
              </ng-container>
              <ng-container *ngIf="!eventInfo.event.is_online_event">
                <h3>{{eventInfo.event.venue.name}}</h3>
                <h3>{{eventInfo.event.local_date}}</h3>
              </ng-container>
            </div>
            <!-- <div [innerHTML]="eventInfo.event.description"></div> -->
            <!-- *ngIf="eventInfo.event.status == 'past'" -->
            <div class="event-info-action-buttons">
              <a *ngIf="eventInfo.event.is_online_event && eventInfo.event.venue.link" mat-stroked-button href="{{eventInfo.event.venue.link}}"
                target="_blank">
                <mat-icon class="watch-icon-button" aria-hidden="false" aria-label="play icon">play_arrow</mat-icon>Watch Session
              </a>
              <a *ngIf="eventInfo.event.is_online_event && !eventInfo.event.venue.link" mat-stroked-button href="{{eventInfo.event.venue.link}}"
                disabled>
                <mat-icon class="watch-icon-button" aria-hidden="false" aria-label="play icon">play_arrow</mat-icon>Watch Session
              </a>
              <a *ngIf="eventInfo.properties.registration" mat-flat-button color="primary" href="{{eventInfo.event.link}}" target="_blank">Register
                now</a>
              <a *ngIf="eventInfo.properties.waitlist" mat-stroked-button color="primary" href="events/{{eventInfo.event.id}}">Join the
                waitlist</a>

              <a *ngIf="eventInfo.event.is_online_event && eventInfo.event.status != 'past'" mat-flat-button color="primary" href="{{eventInfo.event.link}}" target="_blank">Register
                now</a>
              <a *ngIf="eventInfo.event.status == 'past'" mat-stroked-button href="{{eventInfo.event.link}}" target="_blank">External event page</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="article-row event-details">
      <div class="row-group sub-group">
        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex>
            <h1>About this event</h1>
            <div [innerHTML]="eventInfo.event.description"></div>
          </div>
        </div>
      </div>
      <!-- <div class="row-group sub-group">
        <div fxLayout="row">
          <div fxFlex>
            <h1>Venue</h1>
            <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis debitis amet deleniti tempore hic blanditiis incidunt quam quisquam
              magni? Accusantium, laborum voluptates ea veniam illo velit? Assumenda quam veritatis nesciunt?</div>
          </div>
        </div>
      </div> -->
      <div class="row-group sub-group event-host-group">
        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex>
            <h1>Coorganizers</h1>
          </div>
        </div>
        <div *ngIf="eventHosts as eventHost" fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
          <div *ngFor="let host of eventHost.event_hosts" class="event-host" fxFlex>
            <div class="host">
              <!-- <img src={{host.photo.thumb_link}} class="host-photo" alt="Host photo"> -->
              <div [ngStyle]="{'background-image': 'url(' + host.photo.thumb_link + ')'}" class="host-photo"></div>
              <div class="host-info">
                <p>{{host.name}}</p>
                <p class="mat-small">{{host.role}}</p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row-group event-host-group">
        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex>
            <h1>Speakers</h1>
          </div>
        </div>
        <div *ngIf="eventHosts as eventHost" fxLayout="row" fxLayout.xs="column">
          <div *ngFor="let host of eventHost.event_hosts" class="event-host" fxFlex fxFlexAlign="center">
            <div [ngStyle]="{'background-image': 'url(' + host.photo.thumb_link + ')'}" class="host-photo">

            </div>
            <span class="mat-small">{{host.name}}</span>
          </div>
        </div>
      </div> -->
    </section>
    <footer></footer>
  </div>
</ng-container>