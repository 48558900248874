<ng-container *ngIf="islandingpage">
  <div *ngFor="let upcoming_event of upcoming_events" fxLayout="row" fxLayout.xs="column" fxLayoutGap="100px" class="upcoming-events">
    <div fxFlex="60" class="event-details landing">
      <a href="events/{{upcoming_event.event.id}}">
        <h1>{{upcoming_event.event.name}}</h1>
      </a>
      <ng-container *ngIf="upcoming_event.event.short_description; else description">
        <div class="description" [innerHTML]="upcoming_event.event.short_description"></div>
      </ng-container>
      <ng-template #description>
        <div class="description" [innerHTML]="upcoming_event.event.description"></div>
      </ng-template>
      <div fxLayout="row" fxLayout.xs="column" class="action-buttons">
        <a *ngIf="upcoming_event.event.is_online_event && upcoming_event.event.venue.link" mat-stroked-button
          href="{{upcoming_event.event.venue.link}}" target="_blank">
          <mat-icon class="watch-icon-button" aria-hidden="false" aria-label="play icon">play_arrow</mat-icon>Watch Session
        </a>
        <a *ngIf="upcoming_event.event.is_online_event && !upcoming_event.event.venue.link" mat-stroked-button
          href="{{upcoming_event.event.venue.link}}" disabled>
          <mat-icon class="watch-icon-button" aria-hidden="false" aria-label="play icon">play_arrow</mat-icon>Watch Session
        </a>
        <a *ngIf="upcoming_event.properties.registration" mat-flat-button color="primary" href="{{upcoming_event.event.link}}"
          target="_blank">Reigster
          now</a>
        <a *ngIf="upcoming_event.properties.waitlist" mat-stroked-button color="primary" href="events/{{upcoming_event.event.id}}">Join the
          waitlist</a>
        <a mat-flat-button href="events/{{upcoming_event.event.id}}">Read more</a>
      </div>
    </div>
    <div fxFlex="40" fxFlexAlign="center" class="event-banner">
      <ng-container *ngIf="upcoming_event.event.landing_page_photo; else defaultFeaturedPhoto">
        <img src={{upcoming_event.event.landing_page_photo}} alt="Featured event photo">
      </ng-container>
      <ng-template #defaultFeaturedPhoto>
        <img src={{featured_event_photo}} alt="Featured event photo">
      </ng-template>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!islandingpage">
  <div *ngFor="let upcoming_event of upcoming_events" fxLayout="row" fxLayout.xs="column" class="upcoming-events">
    <div fxFlex="35" fxHide.xs class="event-banner">
      <ng-container *ngIf="upcoming_event.event.featured_photo">
        <img src={{upcoming_event.event.featured_photo.highres_link}} alt="Event banner">
      </ng-container>
      <!-- <ng-container *ngIf="!upcoming_event.event.featured_photo">
        <img src={{meetup_card_svg}} alt="Event banner">
      </ng-container> -->
    </div>
    <div fxFlex="65" class="event-details">
      <a href="events/{{upcoming_event.event.id}}">
        <h1>{{upcoming_event.event.name}}</h1>
      </a>
      <ng-container *ngIf="upcoming_event.event.short_description; else description">
        <div class="description" [innerHTML]="upcoming_event.event.short_description"></div>
      </ng-container>
      <ng-template #description>
        <div class="description" [innerHTML]="upcoming_event.event.description"></div>
      </ng-template>
      <div fxLayout="row" fxLayout.xs="column" class="action-buttons">
        <a *ngIf="upcoming_event.event.is_online_event && upcoming_event.event.venue.link" mat-stroked-button
          href="{{upcoming_event.event.venue.link}}" target="_blank">
          <mat-icon class="watch-icon-button" aria-hidden="false" aria-label="play icon">play_arrow</mat-icon>Watch Session
        </a>
        <a *ngIf="upcoming_event.event.is_online_event && !upcoming_event.event.venue.link" mat-stroked-button
          href="{{upcoming_event.event.venue.link}}" disabled>
          <mat-icon class="watch-icon-button" aria-hidden="false" aria-label="play icon">play_arrow</mat-icon>Watch Session
        </a>
        <a *ngIf="upcoming_event.properties.registration" mat-flat-button color="primary" href="{{upcoming_event.event.link}}"
          target="_blank">Reigster
          now</a>
        <a *ngIf="upcoming_event.properties.waitlist" mat-stroked-button color="primary" href="events/{{upcoming_event.event.id}}">Join the
          waitlist</a>
        <a mat-flat-button href="events/{{upcoming_event.event.id}}">Read more</a>
      </div>
    </div>
  </div>
</ng-container>