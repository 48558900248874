import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { Location } from '@angular/common';

import { IsLoadingService } from 'src/app/core/is-loading.service';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { MaterialComponentService } from 'src/app/material/material-component.service';
import { BreakpointObserverService } from 'src/app/core/breakpointObserver.service';

import { MatSidenav } from '@angular/material/sidenav';
// import { MatProgressBar } from '@angular/material/progress-bar';
import { path, breakpointView } from 'src/app/model/constants';

import { catchError, filter, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'content',
  templateUrl: 'container.component.html',
})
export class ContainerComponent implements OnInit, AfterViewInit {
  @ViewChild('drawer') public sidenav: MatSidenav;
  // @ViewChild('progressbar') public progressbar: MatProgressBar;
  pages: string[] = ['community', 'events', 'team'];

  url: string;
  breakpointView = breakpointView;
  isLoading = true;

  constructor(
    private router: Router,
    private location: Location,
    // private isLoadingService: IsLoadingService,
    private readonly auth: AuthenticationService,
    private breakpointObserverService: BreakpointObserverService,
    private materialComponentService: MaterialComponentService
  ) {
    this.breakpointView = this.breakpointObserverService.breakpointView;
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        )
      )
      .subscribe((event) => {
        this.url = this.location.path();
        if (event instanceof NavigationStart) {
          this.progressBar();
        }
        if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0)
          this.progressBar();
        }
      });
  }

  progressBar() {
    document.addEventListener('readystatechange', (event) => {
      if (document.readyState == 'complete') {
        this.isLoading = false;
      }
    });
  }

  ngAfterViewInit() {
    this.materialComponentService.setSidenav(this.sidenav);
  }

  signInWithGoogle() {
    this.auth
      .googleSignIn()
      .pipe(
        take(1),
        catchError((error) => {
          this.materialComponentService.errorSnackBar(error);
          return EMPTY;
        })
      )
      .subscribe(
        (response) =>
          response && this.materialComponentService.signInSuccessSnackBar()
      );
  }

  logout() {
    this.auth
      .logout()
      .pipe(take(1))
      .subscribe((response) => {
        this.router.navigate([`/${path.HOME}`]);
        this.materialComponentService.signOutSnackBar();
      });
  }
}
