<mat-progress-bar mode="buffer" [style.visibility]="isLoading ? 'visible' : 'hidden'"></mat-progress-bar>
<top-bar #topbar [breakpointView]="breakpointView" [class.is-mobile]="breakpointView.handset" [url]="url" (signInEvent)="signInWithGoogle()"
  (logoutEvent)="logout()">
</top-bar>
<!-- [handset]="isHandset" [web]="isWeb" [tablet]="isTablet" -->
<mat-drawer-container hasBackdrop="true">
  <mat-drawer #drawer mode="over" fixedInViewport="true" class="drawer">
    <mat-selection-list #shoes [multiple]="false">
      <mat-list-option *ngFor="let page of pages" [value]="page">
        <mat-divider></mat-divider>
        <a href="/{{page}}">{{page}}</a>
      </mat-list-option>
    </mat-selection-list>
  </mat-drawer>
  <mat-drawer-content *ngIf="breakpointView.handset" [class.is-mobile]="breakpointView.handset">
    <router-outlet></router-outlet>
    <!-- <footer></footer> -->
  </mat-drawer-content>
</mat-drawer-container>

<ng-container *ngIf="breakpointView.web || breakpointView.tablet">
  <div [class.is-tablet]="breakpointView.tablet"></div>
  <router-outlet></router-outlet>
  <!-- <footer></footer> -->
</ng-container>