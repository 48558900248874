<ng-container *ngIf="!communityInfo">
  <div class="loading-page"></div>
</ng-container>
<ng-container *ngIf="communityInfo as info">
  <div class="site-article">
    <section class="article-row landing-header">
      <div class="row-group">
        <div fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
          <div fxFlex="60" class="content">
            <h1 class="mat-display-1 cssanimation fadeInBottom" [innerHTML]="info.header"></h1>
            <!-- <h1 class="mat-display-1">what we're about</h1>
            <p [innerHTML]="info.about"></p>
            <p>
              <a mat-flat-button href="https://developers.google.com/community/gdg" color="primary" class="gdg-link"
                target="_blank">developers.google.com/groups</a>
              <a mat-icon-button href="https://www.facebook.com/gdgbaguio/" class="social-media-link" target="_blank">
                <mat-icon><img src={{fb_svg}} alt="facebook"></mat-icon>
              </a>
              <a mat-icon-button href="https://twitter.com/gdgbaguio" class="social-media-link" target="_blank">
                <mat-icon><img src={{twitter_svg}} alt="twitter"></mat-icon>
              </a>
              <a mat-icon-button href="https://www.instagram.com/explore/tags/gdgbaguio/?hl=en" class="social-media-link" target="_blank">
                <mat-icon><img src={{instagram_svg}} alt="instagram"></mat-icon>
              </a>
            </p> -->
          </div>
        </div>
      </div>
    </section>
    <section class="article-row what-we-are-about">
      <div class="row-group">
        <div fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
          <div fxFlex class="content">
            <div [innerHTML]="info.about"></div>
            <p>
              <a mat-flat-button href="https://developers.google.com/community/gdg" color="primary" class="gdg-link"
                target="_blank">developers.google.com/groups</a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="article-row activities">
      <div class="row-group">
        <div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="60px">
          <div *ngFor="let activity of info.activities" fxFlex class="content">
            <div class="items" [class]="activity.title | lowercase">
              <div>
                <h2>{{activity.title}}</h2>
                <p>{{activity.description}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="article-row">
      <ng-container *ngIf="ongoing_events?.length">
        <div class="row-group">
          <h1>Ongoing events</h1>
          <ongoing-event [ongoing_events]="ongoing_events"></ongoing-event>
        </div>
      </ng-container>
      <ng-container *ngIf="upcoming_events?.length">
        <div class="row-group">
          <h1>Upcoming events</h1>
          <upcoming-event [upcoming_events]="upcoming_events">
          </upcoming-event>
        </div>
      </ng-container>
      <ng-container *ngIf="!ongoing_events?.length && !upcoming_events?.length">
        <div class="row-group">
          <h1>Upcoming events</h1>
          <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="90">
              <h3>There are no upcoming events at the moment. Please check again soon.</h3>
            </div>
            <div fxFlex="10">
              <a mat-flat-button href="/events">All events</a>
            </div>
          </div>
        </div>
      </ng-container>
    </section>
    <section id="speakersponsor" class="article-row">
      <div class="row-group">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="100px">
          <div fxFlex>
            <h1>Get the chance to meet our speakers</h1>
            <button mat-raised-button (click)="openApplicationFormDialog(applicationType.speaker)" class="speaker-form-button" color="primary">Request
              Speakers</button>
          </div>
          <div fxFlex>
            <h1>Sponsorship</h1>
            <button mat-raised-button (click)="openApplicationFormDialog(applicationType.sponsorship)" class="wtm-form-button">Become a
              sponsor</button>
          </div>
        </div>
      </div>
    </section>
    <section id="womentechmakers" class="article-row women-techmakers">
      <div class="row-group">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="100px" class="content">
          <div fxFlex="65" class="wtm-description">
            <img src={{wtm_svg}} alt="women techmakers" class="wtm-banner">
            <div [innerHTML]="info.wtm"></div>
            <button mat-raised-button (click)="openApplicationFormDialog(applicationType.womentechmakers)" class="wtm-form-button">Apply to
              join</button>
          </div>
          <div fxFlex="35">
            <img src="https://raw.githubusercontent.com/error900/gdg-baguio-team/master/account-photo/kim.jpg" class="wtm-ambassador-photo"
              alt="wtm ambassador">
            <h3 [innerHTML]="info.wtmAmbassador" class="wtm-ambassador-name"></h3>
            <p class="mat-caption wtm-ambassador-caption">Women Techmakers Ambassador, GDG Baguio</p>
          </div>
        </div>
      </div>
    </section>
    <section id="volunteer" class="article-row participation">
      <div class="row-group">
        <div fxLayout="row" fxLayout.xs="column" class="content">
          <div fxFlex>
            <h1 class="participation-headline">Be part of the growing community</h1>
          </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="100px" class="content map-content">
          <div fxFlex="45" fxFlexAlign="center">
            <div [innerHTML]="info.join"></div>
            <button mat-raised-button (click)="openApplicationFormDialog(applicationType.volunteer)" class="volunteer-form-button">Become a
              volunteer</button>
          </div>
          <div fxFlex="55" fxFlexAlign="center">
            <community-map class="community-map"></community-map>
          </div>
        </div>
      </div>
    </section>
    <footer></footer>
  </div>
</ng-container>