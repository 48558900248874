<svg version="1.0" id="GDG_community_map" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 2900.79 1985.13" xml:space="preserve">
  <g id="map-silhouette">
    <path class="st0" d="M1336.68,952.04c-2.01,3.82-3.08,7.69-0.93,11.88c0.84,1.64-0.39,2.99-0.97,4.44c-0.48,1.2,0.15,2.79,1.3,3.24
		c0.83,0.32,1.86,0.46,2.71,0.28c0.89-0.19,1.78-0.76,2.49-1.37c0.96-0.82,1.88-1.75,2.61-2.79c5.87-8.34,5.16-6.66,12.74-3.09
		c5.01,2.36,9.45,5.19,12.97,9.48c0.77,0.93,2.01,1.54,3.13,2.08c0.43,0.21,1.37,0.01,1.7-0.34c0.85-0.92,1.62-1.97,2.21-3.08
		c2.09-3.96,3.95-3.86,7.78-1.65c2.1,1.21,2.65,4.12,0.75,5.66c-2.35,1.89-3.69,4.22-4.9,6.91c-1.53,3.39-1.8,6.44-0.65,10.11
		c2.45,7.82,1.37,15.82-0.19,23.7c-0.24,1.19-0.86,2.53-1.74,3.34c-2.07,1.9-2.91,4.09-2.63,6.84c0.09,0.94-0.17,1.9-0.24,2.86
		c-0.19,2.72-1.37,4.25-4.84,5.1c-3.69,0.9-6.97-0.12-9.66-2.81c-0.9-0.9-1.76-1.88-2.45-2.93c-1.6-2.43-3.47-4.47-5.96-6.09
		c-4.36-2.84-5.87-7.35-6.34-12.3c-0.06-0.63,0.02-1.28,0.11-1.91c0.42-3.04-0.34-5.69-2.49-7.92c-0.91-0.95-0.2-1.77,0.39-2.45
		c0.61-0.72,1.43-1.27,2.12-1.94c1.57-1.51,3.22-1.93,5.14-0.61c0.52,0.36,1.13,0.73,1.73,0.78c0.91,0.08,2.23,0.25,2.7-0.26
		c2.33-2.46,5.29-3.1,8.41-3.77c2.2-0.48,3.72-3.65,3.55-6.23c-0.07-1.16-1.3-2.56-2.41-2.63c-2.54-0.17-5.09-0.18-7.61-0.46
		c-2.13-0.24-4.26-2.46-6.3-0.69c-1.33,1.16-2,3.16-2.78,4.87c-1.74,3.82-4.83,6.19-8.75,6.7c-2.53,0.33-4.97,0.24-7.13,1.83
		c-0.19,0.14-1.29-0.41-1.37-0.78c-0.47-1.98-0.9-3.31-3.65-2.89c-1.62,0.25-0.79-1.89-0.89-2.99c-0.08-0.82-0.45-1.24-1.25-0.93
		c-0.56,0.22-1.01,0.74-1.49,1.15c-3.09,2.67-5.74,2.94-8.33,0.32c-2.74-2.76-5.82-4.83-9.17-6.78c-2.66-1.55-4.78-4.17-6.84-6.58
		c-2.84-3.32-6.15-4.38-10.23-2.97c-1.51,0.52-2.94,1.25-4.43,1.82c-2.68,1.02-5.16-0.43-5.41-3.46
		c-0.42-5.05-1.45-10.16,0.64-15.12c0.37-0.87,0.61-1.83,0.73-2.77c0.3-2.31-0.08-4.48-1.82-6.19c-0.91-0.9-1.82-1.82-2.86-2.54
		c-3.88-2.68-5.21-7.17-7.71-10.82c-0.8-1.16-0.5-3.08-0.69-4.65c-0.46-3.81-2.45-6.61-5.9-8.14c-5.81-2.58-11.19-6.16-17.61-7.34
		c-1.49-0.28-2.9-1.3-4.2-2.2c-3.41-2.36-6.83-4.74-10.08-7.32c-1.98-1.57-3.69-3.5-5.44-5.33c-0.9-0.94-0.81-2.15,0.11-3.45
		c0.55-0.78,1.26-1.45,1.79-2.24c0.57-0.85,0.53-1.8-0.03-2.65c-1.76-2.7-3.88-5.07-6.53-6.94c-1.03-0.73-2.38-1.37-2.92-2.4
		c-2.31-4.39-6.14-5.03-10.51-4.83c-2.59,0.12-3.09-0.22-3.69-3.12c-0.42-2.03-1.19-3.77-2.92-4.7c-3.14-1.69-5.61-4.19-8.38-6.32
		c-0.67-0.52-1.89-1-2.56-0.74c-3.79,1.52-7.52,1.06-11.3,0.15c-0.27-0.07-0.85,0.1-0.88,0.24c-0.1,0.53-0.28,1.32,0,1.6
		c3.39,3.44,6.11,7.38,8.6,11.49c0.68,1.12,1.16,2.33,0.81,3.63c-0.51,1.91-0.37,3.76-0.11,5.69c0.11,0.82-0.22,2.01-0.79,2.56
		c-1.93,1.84-1.75,4-1.32,6.23c0.62,3.22,2.06,5.98,4.46,8.3c2.08,2.01,4.25,3.84,6.74,5.34c1.61,0.97,3.02,2.29,4.49,3.49
		c0.21,0.17,0.42,0.73,0.34,0.8c-0.42,0.4-0.9,0.84-1.43,1c-0.59,0.18-1.27,0.08-1.91,0.11l0.11-0.11c0.14,0.93,0.01,2.02,0.46,2.75
		c1.02,1.61,2.29,3.07,3.53,4.53c3.44,4.07,5.16,8.82,5.41,14.07c0.2,4.16,0.49,8.24,1.79,12.26c0.6,1.85-0.16,3.67-1.93,5.02
		c-2.29,1.74-4.46,3.62-7.18,4.72c-1.98,0.8-3.74,0.57-5.41-0.65c-1.03-0.75-2.02-1.57-3.04-2.33c-3.27-2.46-5.44-5.04-3.68-9.72
		c1.43-3.81-0.31-7.59-2.3-10.94c-1.47-2.46-3.31-4.7-5.01-7.03c-1.5-2.07-3.5-3.93-4.45-6.22c-2.2-5.29-6.51-8.58-10.52-12.2
		c-0.68-0.62-1.66-1.34-2.47-1.31c-3.67,0.15-6.61-2.28-10.11-2.65c-1.72-0.18-2.83-1.19-3.7-2.67c-2.44-4.13-4.88-8.26-7.5-12.27
		c-1.39-2.12-3.09-4.05-4.82-5.93c-0.59-0.64-1.73-1.28-2.49-1.15c-4.21,0.71-7.29-2.02-10.58-3.62c-2.8-1.36-5.19-3.6-8.23-4.77
		c-2.96-1.14-5.14-3.75-8.4-4.51c-1.92-0.45-2.98-2.28-4.16-3.81c-1.42-1.84-3.44-2.79-5.68-3.33c-1.96-0.48-3.81-0.46-5.37,1.01
		c-3.84,3.6-8.7,5.05-13.54,6.67c-4.22,1.41-8.41,2.99-12.42,4.9c-2.6,1.24-4.4,3.67-5.9,6.15c-0.47,0.77-0.73,1.83-0.68,2.73
		c0.19,3.47-0.28,6.99,1.45,10.32c0.76,1.46-1.25,4.01-2.96,4.26c-0.31,0.05-0.64,0.18-0.94,0.12c-4.85-0.87-8.75,0.89-12.14,4.25
		c-0.9,0.9-1.94,1.68-2.99,2.4c-1.07,0.74-2.23,1.1-3.58,0.53c-2.34-1-4.7-1.99-7.11-2.78c-4.29-1.41-8.61-2.36-13.01-0.18
		c-0.79,0.39-1.87,0.22-2.82,0.24c-2.18,0.05-3.61-1.57-3.25-3.61c0.39-2.2,0.9-4.38,1.11-6.59c0.22-2.32-0.69-4.44-2.11-6.22
		c-2.33-2.92-5.28-3.74-8.01-0.57c-2.28,2.66-4.65,5.25-7.07,7.8c-0.31,0.32-1.48,0.39-1.65,0.16c-0.49-0.7-1.13-1.79-0.89-2.42
		c0.56-1.46,1.37-2.96,2.48-4.02c1.91-1.83,2.2-3.55,1.07-5.95c-0.64-1.35-0.49-3.08-0.61-4.65c-0.12-1.59-0.07-3.19-0.21-4.77
		c-0.17-1.82-1.8-3.59-3.67-3.95c-3.45-0.67-6.92-1.26-10.38-1.88c-2.2-0.39-4.39-0.84-6.6-1.15c-2.33-0.33-4.31,1.97-3.7,4.23
		c0.33,1.22,0.78,2.4,1.09,3.62c0.21,0.84-0.72,1.86-1.69,1.88c-0.95,0.02-1.91-0.17-2.85-0.11c-1.27,0.08-2.26,1.42-2.1,2.71
		c0.19,1.56,0.47,3.11,0.56,4.68c0.01,0.17-1.04,0.62-1.5,0.53c-0.55-0.12-1.12-0.61-1.48-1.09c-1.17-1.56-1.87-3.33-2.1-5.28
		c-0.56-4.73,0.4-9.61-1.4-14.24c-0.17-0.44,0.27-1.24,0.63-1.72c0.78-1.06,1.59-2.24,1.17-3.49c-0.98-2.84-0.51-5.71-0.48-8.56
		c0.02-2.65-0.38-5.08-2.49-6.97c-0.45-0.41-0.94-1.04-0.97-1.6c-0.25-3.8-0.89-7.66,1.57-11.1c0.74-1.04,1.37-2.16,2.02-3.26
		c1.04-1.75,2.54-2.9,4.57-3.14c3.36-0.39,5.8-2.18,8.26-4.41c3.07-2.78,6.07-5.55,8.75-8.74c2.25-2.68,4.96-4.98,7.51-7.41
		c1.17-1.11,2.53-2.03,4.2-2.11c2.4-0.11,4.16-1.36,5.93-2.82c4.97-4.1,4.52-5.9,2.28-10.72c-1.07-2.31-2.05-4.71-2.64-7.17
		c-0.7-2.89-2.14-5.27-3.75-7.69c-1.46-2.19-2.98-4.14-5.62-4.93c-0.6-0.18-1.33-0.43-1.67-0.88c-2.02-2.68-4.89-2.8-7.82-2.74
		c-5.16,0.11-10.2-0.6-15.14-2.04c-0.89-0.26-1.89-0.66-2.49-1.32c-1.5-1.63-2.91-3.36-4.13-5.2c-0.48-0.71-0.41-1.81-0.51-2.74
		c-0.14-1.25-0.17-2.51-0.31-3.75c-0.02-0.17-0.38-0.43-0.58-0.43c-0.3,0-0.75,0.11-0.87,0.32c-1.11,1.91-2.24,3.82-3.21,5.81
		c-0.55,1.11-0.92,2.34-1.18,3.56c-0.32,1.56-0.3,3.2-0.68,4.74c-1.15,4.64-0.73,8.82,2.73,12.48c0.8,0.84,1.47,2.31,1.37,3.41
		c-0.39,4.29,1.33,8.16,2.01,12.23c0.31,1.87,0.63,3.89,0.25,5.69c-1.14,5.26-3.18,7.6-8.25,7.61c-0.64,0-1.36-0.22-1.91-0.01
		c-3.45,1.35-6.47,0.83-9.89-0.8c-4.33-2.06-6.59-4.77-6.24-9.6c0.16-2.23,0.16-4.48,0.1-6.71c-0.11-3.98-1.95-6.38-5.86-7.82
		c-0.3-0.11-0.62-0.17-0.93-0.22c-4.39-0.61-6.29-4.01-8.13-7.39c-0.43-0.79-0.52-1.85-0.51-2.79c0.02-1.26,0.63-2.59,0.38-3.76
		c-0.52-2.49,0.38-4.26,1.94-6.07c1.33-1.53,0.97-2.48-0.45-4.11c-1.54-1.76-3.52-2.75-5.59-3.57c-0.39-0.15-1.42,0.27-1.51,0.6
		c-0.33,1.21-0.36,2.5-0.54,3.76c-0.23,1.58-0.31,3.21-0.79,4.7c-1.45,4.48-4.26,5.28-8.09,2.66c-2.67-1.83-4.64-4.22-5.91-7.32
		c-1.97-4.82-3.13-9.72-2.9-14.93c0.08-1.92,0.18-3.83,0.27-5.75c0.18-3.85-0.07-7.62-1.14-11.39c-1.49-5.21-2.01-10.68-4.28-15.68
		c-0.26-0.57-0.46-1.23-0.44-1.84c0.14-6.97-4.25-11.81-7.98-16.94c-0.74-1.02-1.74-1.88-2.68-2.74c-3.96-3.63-3.3-7.34,1.55-10.92
		c0.76-0.56,1.61-1.01,2.33-1.61c0.86-0.72,1.31-2.59,0.91-3.52c-0.89-2.05-1.85-4.03-3.53-5.63c-0.89-0.85-1.7-1.94-2.14-3.08
		c-0.43-1.13-0.63-2.5-0.43-3.68c0.26-1.55,1.03-3.01,1.56-4.52c0.58-1.66,0.1-3.19-0.89-4.5c-0.77-1.01-1.82-1.79-2.7-2.71
		c-1.21-1.28-1.43-2.72-0.73-4.37c1.94-4.55,1.31-9.43,1.36-14.14c0.04-3.5-1.71-6.77-4.1-9.51c-0.95-1.09-2.94-1.42-4.35-0.7
		c-1.4,0.72-2.78,1.5-4.22,2.15c-0.95,0.42-2.84-0.55-3.14-1.51c-0.29-0.91-0.72-1.82-0.76-2.74c-0.25-5.76-0.49-11.53,1.47-17.09
		c1.01-2.87,0.93-5.53-0.65-8.26c-1.15-1.99-1.05-4.29-0.5-6.54c1.05-4.35,2.01-8.72,3.07-13.06c0.64-2.64,2.13-4.65,4.71-5.66
		c2.08-0.81,4.18-1.61,6.33-2.2c1.6-0.44,3.17-0.13,4.29,1.31c1.51,2.52,0.63,5.65,2.1,8.18c-1.14,6.45,5.83,8.09,7.61,12.81
		c0.65,0.68,1.2,1.57,1.99,1.98c1.11,0.57,2.43,0.75,3.63,1.16c3.97,1.35,6.41,4.03,7.07,8.23c0.05,0.32,0.12,0.63,0.17,0.94
		c0.32,2.22,0.46,4.58,2.47,6.02c3.23,2.31,6.94,3.69,10.83,2.79c4.02-0.93,7.87-2.52,12.13-1.74c0.42,0.08,1.29-0.58,1.43-1.05
		c1.14-3.86,4.66-6,6.56-9.35c3.25-5.7,2.87-12.04-1.57-16.72c-0.65-0.69-1.48-1.21-2.22-1.82c-1.31-1.08-1.9-2.54-2.08-4.17
		c-0.79-6.98-1.77-13.93-3.5-20.76c-0.38-1.5-0.1-3.17-0.18-4.76c-0.08-1.59-0.14-3.18-0.37-4.75c-0.24-1.6-0.24-8.31,0.2-9.8
		c0.72-2.44,1.39-4.9,2.24-7.3c0.55-1.56,0.76-3.04,0.35-4.66c-0.4-1.54-0.72-3.12-0.87-4.7c-0.28-2.96,0.23-5.7,2.45-7.96
		c4.07-4.14,6.77-9.28,9.92-14.08c1.8-2.74,2.37-5.86,1.95-9.18c-0.44-3.48-0.68-6.99-1.01-10.49c-0.15-1.59,0.01-3.3-0.52-4.75
		c-2.14-5.91-1.39-11.95-1.36-18c0.02-3.02,0.56-5.63,2.44-8.02c1.73-2.2,1.76-4.78,0.86-7.3c-1.29-3.61-2.75-7.16-4.12-10.74
		c-0.73-1.9-1.93-3.27-3.99-3.78c-0.61-0.15-1.22-0.4-1.77-0.71c-1.5-0.85-2.5-2.2-2.3-3.89c0.48-4.12,1.16-8.21,1.83-12.3
		c0.22-1.32,1.11-2.27,2.26-2.94c0.55-0.32,1.15-0.56,1.69-0.89c3.5-2.15,4.23-3.86,2.63-7.94c-0.87-2.22-1.7-4.21,0.08-6.2
		c2.07-2.32,2.81-5.03,2.46-8c-0.44-3.7,0.66-6.8,2.64-9.91c2.63-4.12,3.99-8.72,2.95-13.71c-0.69-3.33,0.24-6.31,1.75-9.21
		c3.53-6.8,5.59-14.22,9.26-20.99c3.01-5.55,3.32-11.61,0.96-17.63c-0.47-1.19-0.9-2.4-1.29-3.61c-0.55-1.69,0.11-3.14,1.06-4.46
		c0.56-0.77,1.25-1.46,1.92-2.13c1.65-1.64,3.56-2.86,5.97-2.41c4.62,0.85,8.86-0.42,13.08-1.99c2-0.75,3.02-2.23,3.39-4.25
		c0.23-1.26,0.3-2.55,0.67-3.77c1.02-3.32,6.53-4.55,8.77-1.94c1.86,2.18,3.53,4.53,5.37,6.72c1.12,1.33,3.84,1.64,5.35,0.8
		c0.28-0.16,0.61-0.25,0.83-0.47c4.12-3.88,9.05-5.9,14.65-6.36c3-2.03,6.1-1.1,9.15-0.22c1.18,0.34,2.31,0.99,3.36,1.66
		c1.87,1.2,3.63,2.56,5.47,3.8c7.68,5.18,15.74,9.69,23.98,13.93c4.52,2.33,8.9,5.02,13.04,7.97c3.11,2.21,7.18,2.9,9.57,6.21
		c0.28,0.38,1.26,0.73,1.59,0.53c3.09-1.79,5.44,0.34,8.04,1.33c6.57,2.49,13.14,4.96,19.73,7.4c5.76,2.14,11.33,1.32,16.69-1.36
		c2.6-1.3,4.97-2.9,6.37-5.61c0.44-0.85,1.12-1.57,1.55-2.42c1.34-2.64,4.88-3.24,5.8-6.2c0.85-2.74,1.46-5.56,2.3-8.31
		c0.37-1.21,0.91-2.42,1.63-3.44c0.51-0.72,1.38-1.37,2.21-1.62c1.2-0.35,2.52-0.39,3.79-0.4c3.43-0.03,5.03,0.95,5.28,4.13
		c0.34,4.16,2.07,7.28,5.29,9.76c3.64,2.81,4.7,6.7,4.37,11.08c-0.58,7.64-3.32,14.26-9.28,19.31c-2.19,1.86-4.47,3.61-6.63,5.48
		c-1.27,1.1-1.86,2.65-1.81,4.28c0.1,3.19,0.3,6.39,0.59,9.57c0.33,3.55-0.02,7.01-0.84,10.46c-0.89,3.72-1.73,7.46-2.53,11.21
		c-1.22,5.67-1.75,11.28,0.2,16.98c0.7,2.04,0.6,4.41,0.6,6.63c0,4.21,0.46,8.29,2.41,12.1c0.43,0.85,0.87,1.74,1.06,2.67
		c0.86,4.11,2.45,7.87,4.8,11.38c0.85,1.27,1.23,2.92,1.57,4.45c0.4,1.85-0.07,4.42,1.64,5.29c3.02,1.53,6.18,3.78,9.81,2.74
		c2.92-0.84,4.86-0.22,6.7,2.07c0.97,1.21,2.55,1.91,3.59,3.07c0.56,0.62,1.07,2.01,0.76,2.59c-2.63,4.83-0.44,10.06-1.4,15.02
		c-0.36,1.84,2.31,4.45,4.25,4.5c1.89,0.06,3.8-0.25,5.7-0.27c1.59-0.02,3.55,2.53,3.24,4.13c-0.18,0.93-0.36,1.89-0.75,2.74
		c-0.92,2.04-1.73,4.18-3,5.99c-2.69,3.85-3.69,8.13-3.71,12.68c-0.01,2.96-0.92,5.6-1.95,8.31c-2.03,5.39-4.12,10.73-7.25,15.61
		c-0.69,1.07-1.37,2.21-1.71,3.42c-1.62,5.6-5.01,10.31-7.58,15.44c-1.17,2.32-2.53,4.46-4.53,6.11c-2.55,2.1-4.26,4.68-5.2,7.86
		c-0.35,1.19-1.18,2.24-1.76,3.37c-0.63,1.22-0.64,2.53,0.08,3.64c1.35,2.08,1.47,4.1,0.34,6.31c-0.87,1.7-1.68,3.45-2.29,5.25
		c-0.3,0.88-0.04,1.92,1.29,1.96c0.79,0.02,1.46,0.36,1.3,0.97c-0.54,2.14-1.13,4.28-1.95,6.32c-0.32,0.79-1.28,1.34-1.97,1.98
		c-1.17,1.08-2.36,2.14-3.54,3.2c-0.28,0.79-0.83,1.33-1.6,1.65l-0.18,0.15c-0.62,1.42-2.78,0.86-3.22,2.5l-0.02,0.07
		c-1.5,0.42-2.42-0.28-3.05-1.56c-0.2-1.28,0.16-2.4,0.94-3.4c0.45-0.56,0.94-1.12,1.41-1.66c0.14-0.3,0.33-0.6,0.46-0.89
		c0.15-1.21-0.52-1.55-1.58-1.45c-0.59,0.3-1.09,0.68-1.47,1.22c-0.69,1.29-0.89,2.71-1.38,4.05c-0.66,1.6-2.12,2.3-3.45,3.16
		c-3.01,0.09-5.45,1.73-7.99,3.05c-2.58,1.34-5.19,2.46-8.14,2.69c-2.27,0.18-4.44,0.8-6.22,2.27c-3.07,2.54-6.63,3.8-10.5,4.33
		c-2.33,0.32-4.2,1.47-5.88,3.01c-1.65,1.51-3.42,2.93-4.83,4.65c-2.87,3.51-6.03,6.59-10.08,8.72c-2.09,1.1-3.34,2.93-3.97,5.18
		c-0.43,1.54-0.91,3.08-1.19,4.64c-0.73,4.08,1.17,7.46,3.09,10.79c0.29,0.5,0.95,0.85,1.5,1.14c1.14,0.58,2.35,1.01,3.48,1.6
		c1.55,0.81,2.14,2.58,1.39,4.03c-0.29,0.57-0.59,1.14-0.98,1.64c-1.24,1.57-1.83,3.26-1.65,5.33c0.07,0.79-0.44,1.92-1.06,2.44
		c-2.25,1.88-3.82,4.12-5.22,6.73c-1.16,2.16-3.27,3.88-5.14,5.59c-2.22,2.03-3.76,4.25-3.83,7.4c-0.1,4.71-2.16,8.61-5.31,12.01
		c-0.9,0.97-1.89,1.84-3.22,1.84c-1.96-0.01-2.87,1.01-3.28,2.75c-1.61,6.92,0.25,12.9,5.95,16.97c6.82,4.87,5.23,4.85,7.34,11.61
		c0.38,1.21,0.52,2.51,0.64,3.78c0.22,2.32,1.22,4.24,2.6,6.08c2.71,3.59,4.89,7.4,5.76,11.97c0.4,2.11,1.89,4.01,2.87,6.02
		c0.41,0.84,1.21,1.76,1.11,2.55c-0.74,5.84,4.15,8.22,7.11,11.73c1.83,2.17,4.71,3.35,6.08,6.01c0.2,0.39-0.11,1.51-0.38,1.59
		c-2.75,0.78-5.56,1.32-8.31,2.1c-2.4,0.68-3.68,2.49-3.85,4.93c-0.26,3.55,0.12,7.02,1.17,10.42c1.5,4.89,2.91,9.78,3.64,14.87
		c0.65,4.53,2.75,8.49,6.22,11.62c2.23,2.01,3.66,4.4,3.54,7.54c-0.06,1.64,0.4,3.18,1.43,4.48c1.84,2.31,1.76,4.68,0.34,7.13
		c-0.89,1.53-0.78,3.03,0.1,4.51c1.82,3.03,3.71,6.02,6.77,7.98c4.36,2.78,7.97,6.41,11.49,10.12c2.24,2.37,4.86,3.99,7.89,5.26
		c9.17,3.85,18.51,7.06,28.36,8.61c0.55,0.09,1.5-0.11,1.7-0.48c0.4-0.75,0.81-1.89,0.52-2.56c-0.63-1.43-1.75-2.63-2.57-3.99
		c-0.3-0.49-0.55-1.3-0.34-1.73c0.2-0.4,1.08-0.74,1.58-0.66c2.2,0.37,4.38,0.9,6.57,1.36c1.58,0.33,2.49-0.09,3.06-1.43
		c0.51-1.2,0.87-2.47-0.11-3.57c-2.77-3.07-5.32-6.32-9.29-8.15c-1.62-0.75-3.03-2.42-3.99-4c-1.9-3.12-2.05-4.17,0.57-6.72
		c0.91-0.88,1.8-2,2.93-2.41c2.7-0.97,3.98-2.9,4.7-5.47c0.02-0.07,0.64-0.09,0.9,0.04c0.26,0.13,0.57,0.44,0.61,0.71
		c0.36,2.49-0.09,4.96,0.48,7.54c0.77,3.48,3.08,5.43,5.37,7.57c0.25,0.24,1.23,0,1.65-0.31c1.37-1.02,1.98-2.54,1.83-4.2
		c-0.11-1.24-0.52-2.49-0.98-3.66c-0.54-1.39-0.28-2.57,0.83-3.38c2.52-1.84,3.81-4.1,3.53-7.34c-0.13-1.57,0.66-3.09,2.11-4.08
		c1.05-0.72,2.07-1.5,3.11-2.24c7.65-5.42,15.28-5.73,22.92,0.07c1,0.76,2.2,1.3,3.36,1.8c1.91,0.83,4.19-0.53,4.9-2.84
		c0.18-0.6,0.23-1.31,0.59-1.77c0.34-0.42,1.18-0.94,1.51-0.79c1.42,0.64,2.66,1.8,4.12,2.21c1.44,0.4,3.08,0.03,4.62,0.18
		c0.58,0.05,1.21,0.5,1.64,0.94c2.02,2.12,4.16,3.83,7.29,4.18c2.28,0.26,4.16,1.72,5.45,3.64c3.41,5.07,8.09,9.01,11.96,13.66
		c1.45,1.74,3.08,3.19,5.1,4.26c1.58,0.83,2.04,2.4,1.73,4.1c-0.17,0.93-0.64,1.81-0.9,2.73c-0.47,1.68-0.02,3.18,1.18,4.39
		c0.9,0.9,2.01,1.6,2.84,2.55c0.61,0.69,1.22,1.6,1.33,2.48c0.36,2.85,0.59,5.72,0.64,8.59c0.02,0.86-0.51,1.92-1.13,2.57
		c-2.37,2.48-3.11,5.51-3.36,8.76c-0.21,2.72,0.56,5.06,2.72,6.83c0.99,0.81,2.04,1.54,3.06,2.31c1.07,0.8,2.29,1.28,3.57,0.89
		c3.16-0.97,6.36-0.34,9.54-0.42c5.11-0.13,9.89-3.59,11.59-8.55c0.39-1.14,0.42-2.56,0.16-3.75c-0.41-1.86-1.32-3.6-1.79-5.44
		c-0.31-1.19-0.22-2.49-0.31-3.73c0.84-1.23,2.74-2.54-0.25-3.5c-1.42-0.7-2.94-1.26-4.25-2.13c-3.34-2.22-3.46-3.16-1.38-6.91
		c0.77-1.39,1.66-2.77,2.1-4.28c0.54-1.82,1.49-2.7,3.36-2.54c1.66,0.14,3.16-0.28,4.51-1.24c0.97-0.69,2-2.04,3.15-0.79
		c0.77,0.85,0.97,2.28,1.24,3.49c0.13,0.57-0.28,1.24-0.27,1.87c0.02,1.16,0.62,1.48,1.83,1.5c0.75,0.02,1.89,0.77,2.16,1.47
		c1.16,2.95,2.07,6.01,3.07,9.03c0.43,1.29,1.37,1.35,2.83,0.17c0.25-0.2,0.43-0.48,0.68-0.67c1.69-1.31,2.7-1.48,4.16-0.11
		c1.69,1.59,3.38,2.62,5.83,2.77c2.17,0.14,4.28,1.1,6.43,1.68c2.77,0.74,5.52,1.57,8.32,2.15c1.98,0.41,3.74-0.13,5.09-1.85
		c1.46-1.86,2.74-1.68,3.9-0.33c1.46,1.69,2.89,3.44,4.54,4.93c1.65,1.49,3.4,2.99,5.37,3.98c3.69,1.86,7.47,3.67,11.4,4.88
		c2.74,0.84,4.42,2.34,5.61,4.71c0.56,1.12,1.12,2.23,1.63,3.38c0.05,0.1-0.36,0.59-0.51,0.56c-2.47-0.34-4.92-0.73-7.38-1.11
		c-3.05-0.48-5.72-0.02-7.64,2.73c-0.54,0.77-1.33,1.39-2.1,1.95c-1.37,0.99-2.96,1.24-4.55,0.75c-2.74-0.84-5.42-1.9-8.17-2.7
		c-1.2-0.35-2.7-0.72-3.75-0.3c-3.53,1.4-6.78,0.8-10.13-0.52c-1.44-0.57-3.32,0.44-3.96,1.95c-1.24,2.94-2.45,5.89-3.48,8.91
		c-0.75,2.2-0.15,4.43,0.52,6.56c0.17,0.52,0.76,0.98,1.26,1.3c1.06,0.67,2.6,0.96,3.23,1.88c2.27,3.31,5.45,5.28,8.88,7.09
		c1.36,0.72,2.79,1.81,3.54,3.1c2.22,3.81,5.62,6.93,6.24,11.7c0.38,2.92,2.23,5.39,5,6.66c2.93,1.34,3.8,4.18,5.36,6.53
		c0.85,0.74,1.93,0.62,2.94,0.78c0.67,0.28,1.18,0.74,1.57,1.34c0.2,0.75-0.03,1.33-0.69,1.74l0,0
		C1343.31,952.29,1339.36,949.3,1336.68,952.04z" />
    <path class="st0" d="M1352.08,1572.17c1.66-0.36,2.8-1.47,3.88-2.66c3.16-3.5,7.2-5.27,11.79-5.8c3.16-0.37,6.36-0.48,9.54-0.69
		c3.19-0.21,6.39-0.25,9.56-0.64c2.65-0.32,4.82-1.8,6.22-4.08c1.66-2.69,3.43-5.3,2.63-8.89c-0.64-2.87,0.35-5.74,1.66-8.32
		c1.47-2.9,2.19-5.9,2.66-9.12c1.11-7.63,5.04-13.75,11.34-18.19c1.78-1.25,4.02-1.87,6.08-2.69c1.07-0.43,2.55,0.19,3.11,1.52
		c0.78,1.86,1.69,3.23,3.79,3.92c1.76,0.58,3.67,1.52,4.86,2.88c2.96,3.39,6.55,4.9,10.93,4.94c0.96,0.01,1.43,0.87,1.31,2.14
		c-0.06,0.63-0.34,1.23-0.42,1.86c-0.2,1.5,0.28,2.06,1.31,1.41c1.69-1.07,3.48-1.33,5.42-1.59c3.78-0.51,5.03-1.89,5.58-5.35
		c0.2-1.26,0.01-2.71,0.58-3.76c1.98-3.68,2.13-7.11,0.1-10.9c-1.26-2.36-0.77-5.07,1.13-7.16c1.61-1.78,2.14-3.75,2.21-6.11
		c0.21-7.35,0.67-14.7,0.06-22.05c-0.11-1.26,0.05-2.55,0.22-3.81c0.35-2.56,2.79-4.62,5.34-4.56c1.6,0.04,3.19,0.19,4.79,0.24
		c2.67,0.09,4.95,1.14,6.99,2.79c1.49,1.2,2.79,2.77,4.46,3.59c6.17,3.04,11.16,7.35,15.14,12.93c1.03,1.44,2.69,1.78,4.26,1.04
		c1.73-0.82,3.47-1.65,5.05-2.7c0.73-0.49,1.49-1.46,1.59-2.29c0.54-4.39,1.84-8.69,0.28-13.25c-1.17-3.42-0.17-6.93,1.59-10.12
		c0.58-1.06,2.58-1.35,3.28-0.42c1.34,1.79,2.67,3.58,3.96,5.4c1.97,2.78,4.58,4.41,8.02,4.59c3.83,0.19,7.65,0.36,11.39,1.41
		c1.92,0.54,3.75-0.22,5.57-0.94c5.68-2.26,8.69-6.24,8.47-12.51c-0.11-3.19,0-6.4,0-9.6c0-0.64,0.08-1.33-0.12-1.91
		c-1.98-5.71-2.12-11.93-4.89-17.42c-2.32-4.6-3.61-9.46-4.59-14.54c-1.26-6.57-3.39-12.97-5.14-19.44
		c-0.42-1.54-0.63-3.17-1.24-4.63c-1.92-4.62-0.9-8.76,1.73-12.72c1.23-1.86,2.44-3.74,3.69-5.58c0.54-0.79,1.78-1.07,2.49-0.61
		c0.53,0.35,1.1,0.67,1.56,1.11c3.31,3.16,8.44,3.03,11.63,6.4c1.28,3.94,2.49,7.9,3.87,11.8c0.78,2.2,2.4,3.86,4.61,4.62
		c3.61,1.23,6.66,3.8,10.65,4.15c1.15,0.1,2.2,1.17,3.31,1.76c1.12,0.59,2.21,1.47,3.39,1.65c6.92,0.99,12.24,5.06,17.69,8.9
		c0.77,0.54,1.67,0.96,2.27,1.65c0.25,0.3,0.08,1.42-0.27,1.69c-1.49,1.16-3.17,2.09-4.68,3.23c-0.4,0.3-0.65,1.13-0.56,1.64
		c0.1,0.55,0.59,1.13,1.07,1.49c1.36,1.02,3.01,0.81,4.59,0.91c2.03,0.13,2.48,0.49,2.8,1.9c0.28,1.25,0.53,2.5,0.75,3.76
		c0.82,4.74,3.74,8.06,7.5,10.69c0.93,0.65,2.48,0.88,3.62,0.67c2.83-0.52,5.54-1.55,7.78-3.5c0.96-0.84,1.88-1.74,2.87-2.54
		c1.67-1.35,3.11-1.48,4.29-0.47c0.95,0.82,1.21,1.92,0.37,3.49c-3.11,5.81-3.9,12.1-4.17,18.54c-0.09,2.08,0.43,3.7,2.31,4.86
		c1.06,0.66,2.29,1.44,2.81,2.48c1.92,3.81,4.84,6.74,7.84,9.62c2.38,2.29,4.04,4.92,4.51,8.25c0.13,0.94,0.45,1.86,0.73,2.78
		c1.07,3.57,0.58,6.92-1.18,10.19c-1.09,2.03-2.46,3.7-4.47,4.86c-1.38,0.8-2.74,1.64-4,2.61c-1.11,0.86-1.15,2.03-0.86,3.42
		c0.44,2.14,0.31,2.42-1.42,2.47c-4.84,0.12-8.31,3.06-11.89,5.64c-1.8,1.3-2.76,3.5-1.39,5.95c0.94,1.67,1.74,3.42,2.63,5.12
		c1.15,2.19,3.04,3.14,5.54,2.7c0.92-0.16,1.98-0.32,2.67-0.86c1.64-1.3,3.51-1.69,5.41-1.44c4.11,0.54,8.23,1.12,12.24,2.11
		c1.36,0.34,2.51,1.88,3.58,3.02c1.63,1.74,1.65,3.13-0.34,4.81c-1.8,1.53-2.08,3-1.1,4.99c0.6,1.22,0.53,2.52-0.22,3.64
		c-1.05,1.59-2.22,3.1-3.34,4.65c-0.77,1.07-0.79,2.47-0.03,3.54c0.56,0.77,1.16,1.52,1.78,2.24c2.62,3.08,2.63,3.18,0.46,6.46
		c-0.35,0.53-0.79,1.01-1.16,1.52c-2.04,2.76-2.01,4.04-0.88,5.01c1.41,1.21,3.01,0.81,4.51,0.23c2.08-0.81,4.1-1.75,6.15-2.61
		c0.71-0.3,2,0.65,1.99,1.49c-0.05,4.15-0.11,8.31-0.21,12.46c-0.02,0.61-0.17,1.3-0.48,1.82c-2.32,3.83-2.58,7.79-0.97,11.94
		c0.51,1.33-0.02,2.51-0.99,3.44c-0.92,0.89-1.96,1.65-2.88,2.54c-3.52,3.38-3.94,8.18-0.95,12.04c0.97,1.25,2.17,2.33,3.31,3.44
		c1.14,1.11,2.71,1.96,3.45,3.28c2.58,4.65,6.5,6.72,11.65,7.11c1.69,0.13,2.62,1.84,2.35,3.58c-0.2,1.26-0.56,2.5-0.74,3.76
		c-0.39,2.89-0.23,5.73,0.88,8.49c1.69,4.18,2.42,8.6,3.25,13c0.47,2.46-0.27,4.46-2.11,5.99c-1.66,1.38-2.55,2.77-1.25,4.87
		c1.09,1.76,0.77,3.74,0.55,5.62c-0.41,3.64-0.15,6.95,2.99,9.47c0.35,0.28,0.5,1.31,0.27,1.75c-1.44,2.82-2.71,5.78-5.29,7.85
		c-0.49,0.39-1.13,0.8-1.33,1.35c-1.96,5.16-5.43,9.32-8.6,13.72c-1.21,1.68-1.26,4.21-1.83,6.36c-0.62,2.36-2.46,3.93-4.84,3.83
		c-2.87-0.13-5.72-0.57-8.58-0.66c-1.27-0.04-2.42,0.67-3.24,1.76c-2.04,2.69-2.82,5.75-2.29,9.03c0.19,1.18,1.01,2.29,1.67,3.35
		c0.67,1.08,1.74,1.96,2.19,3.1c0.45,1.13,0.46,2.48,0.48,3.73c0.01,0.98-0.53,1.75-1.56,2.07c-1.04,0.33-1.98-0.03-2.63-0.69
		c-0.87-0.9-1.67-1.94-2.22-3.06c-1.13-2.29-1.99-4.71-3.12-7c-0.85-1.74-2.15-3.15-3.89-4.11c-1.32-0.73-3.29-0.31-4.16,1.02
		c-0.52,0.8-0.91,1.69-1.52,2.4c-0.95,1.1-1.06,2.25-0.48,3.5c1.9,4.07,4.03,7.95,7.67,10.84c1.31,1.04,1.63,2.73,1.26,4.39
		c-0.35,1.56-0.88,3.07-1.26,4.62c-1.39,5.61-2.07,11.3-1.95,17.1c0.12,5.75,0.1,11.46-1.46,17.13c-0.83,3.02-0.48,6.35-0.76,9.54
		c-0.11,1.25-0.36,2.51-0.77,3.7c-0.17,0.49-0.9,0.81-1.42,1.13c-0.06,0.04-0.51-0.34-0.6-0.59c-0.3-0.89-0.7-1.79-0.74-2.7
		c-0.11-2.7-0.87-5-3.17-6.66c-1.36-0.98-2.16-2.38-2.21-4.07c-0.08-2.68-1.26-4.95-2.58-7.14c-1.52-2.52-2.46-5.24-2.8-8.1
		c-0.68-5.72-1.36-11.49-0.46-17.22c0.65-4.16,0.94-8.18-0.15-12.29c-0.57-2.12,0.36-4.74-1.3-6.43c-1.29-1.31-3.35-1.82-4.92-2.9
		c-3.27-2.26-5.26-5.56-6.54-9.21c-0.38-1.08-0.05-2.57,0.34-3.74c1.19-3.55-0.14-6.22-2.61-8.61c-1.38-1.33-2.71-2.72-4.09-4.05
		c-1.89-1.82-3.37-3.89-3.72-6.54c-0.46-3.55-1.88-6.77-3.26-9.99c-1.54-3.58-2.65-7.19-2.23-11.15c0.1-0.94-0.03-1.91-0.11-2.86
		c-0.19-2.4-2.84-3.58-5.06-2.27c-0.55,0.32-1.12,0.62-1.65,0.97c-3.22,2.08-6.44,4.15-9.65,6.25c-1.07,0.7-2.14,1.41-3.12,2.23
		c-2.33,1.95-3.71,4.39-3.54,7.51c0.08,1.59,0.23,3.19,0.48,4.77c0.61,3.76-0.65,6.96-3.02,9.74c-2.29,2.69-4.84,5.1-8.45,6.03
		c-1.23,0.32-2.4,0.86-3.58,1.33c-2.59,1.03-4,3.14-4.83,5.63c-0.4,1.19-0.43,2.51-0.57,3.78c-0.25,2.31-1.19,4.3-2.65,6.06
		c-1.02,1.23-2.08,2.43-3.07,3.69c-4.31,5.44-6.2,11.8-7.14,18.56c-0.6,4.33,0.99,8.2,2.31,12.12c0.27,0.79,1.19,1.5,1.98,1.92
		c0.4,0.21,1.24-0.18,1.78-0.49c1.67-0.97,3.11-0.49,4.28,0.76c1.75,1.86,3.39,3.83,5.05,5.77c1.3,1.52,2.83,2.44,4.92,2.55
		c3.41,0.19,5.46,2.56,5.68,5.53c0.37,4.97,2.17,9.28,4.45,13.52c0.45,0.84,1.05,1.71,1.16,2.61c0.89,7.41,4.44,13.95,6.81,20.87
		c1.19,3.46,1.62,6.71,0.29,10.26c-1.23,3.27-0.79,6.91-2.03,10.29c-1.23,3.36-2.7,6.45-5.01,9.19
		c-8.28,9.8-15.56,20.29-21.92,31.43c-1.27,2.22-2.61,4.41-4.13,6.46c-2.28,3.08-5.45,4.34-9.26,3.8c-1.26-0.18-2.57-0.39-3.7-0.93
		c-0.77-0.37-1.68-1.27-1.79-2.03c-0.4-2.84-0.48-5.72-0.71-8.58c-0.19-2.39-1.21-4.58-3.35-5.47c-2.84-1.19-4.59-3.25-6.26-5.62
		c-0.71-1-1.97-1.58-2.87-2.48c-0.98-0.98-1.21-2.36-0.84-3.58c1.39-4.58,2.72-9.21,6.15-12.85c1.09-1.16,1.95-2.53,2.86-3.85
		c2.35-3.4,2.84-7.05,1.42-10.94c-0.33-0.9-0.69-1.79-1.05-2.68c-0.77-1.92-2.07-3.11-4.22-3.39c-1.89-0.24-3.75-0.66-5.61-1.06
		c-1.59-0.34-2.59,0.12-2.9,1.5c-1.7,7.51-4.84,14.53-7.17,21.83c-1.22,3.83-2.63,5.55-7.95,4.95c-1.87-0.21-3.91-0.25-5.67,0.27
		c-2.71,0.81-5.06,0.13-7.42-0.9c-2.34-1.03-4.58-2.29-6.92-3.33c-4.67-2.07-9.38-3.96-14.55-4.54c-1.84-0.21-3.68-1.06-5.37-1.9
		c-2.85-1.42-5.51-3.18-8.74-3.83c-1.15-0.23-2.39-1.11-3.12-2.06c-1.43-1.84-3.35-2.78-5.28-3.93
		c-11.01-6.57-22.41-12.32-34.33-17.04c-1.48-0.58-2.88-1.4-4.21-2.29c-2.15-1.44-4.12-3.18-4.93-5.71
		c-1.41-4.44-4.28-7.8-7.5-10.97c-0.91-0.9-1.77-1.85-2.62-2.81c-4.16-4.66-6.79-10.02-7.41-16.3c-0.28-2.8-1.46-5.69,1.01-8.14
		c0.93-4.11,0.79-8.24,0.06-12.35c-0.28-1.57-0.61-3.17-1.18-4.64c-0.97-2.5-0.96-4.97-0.41-7.53c0.28-1.31-0.01-2.68-1.23-3.34
		c-0.75-0.41-1.83-0.2-2.76-0.29c-0.63-0.06-1.27-0.1-1.88-0.27c-1.25-0.35-2.87-2.44-2.65-3.67c1.02-5.69,0.87-11.45,1.32-17.18
		c0.15-1.88,0.74-3.75,1.32-5.57c0.58-1.82,1.57-3.52,2.09-5.35c1.5-5.24,4.56-8.79,9.66-11.13c6.55-3.01,11.41-8.13,14.74-14.57
		c0.57-1.09,1.01-2.41,0.99-3.62c-0.06-3.58,0.53-6.98,1.89-10.29c0.41-0.99-0.8-2.51-2.07-2.71c-0.63-0.1-1.27-0.15-1.9-0.17
		c-1.7-0.04-2.99-0.8-4.17-2.01c-2.05-2.1-3.47-4.57-4.72-7.18c-1.25-2.59-2.46-5.2-3.77-7.77c-1.78-3.5-4.29-6.34-7.71-8.34
		c-1.38-0.81-2.85-1.53-4.05-2.56c-5.61-4.78-12.23-6.69-19.43-7.09c-0.96-0.05-1.91-0.17-2.86-0.31c-1.66-0.23-3.05-1.08-4.09-2.31
		c-1.85-2.2-3.47-4.59-5.36-6.75c-1.03-1.18-2.26-2.56-3.66-2.98c-7.01-2.08-14.03-4.69-21.54-3.04c-2.45,0.54-4.76,1.73-7.08,2.74
		c-1.2,0.53-1.9,3.05-1.15,4.27c0.98,1.62,2.12,3.15,3.26,4.67c2.95,3.92,2.05,8.91-2.13,11.47c-0.81,0.5-1.66,1.12-2.56,1.26
		c-2.82,0.44-4.58,1.87-5.49,4.62c-0.22,0.66-1.51,0.95-2.28,1.44c-1.15,0.73-1.48,1.5-1,2.25c1.53,2.42,2.94,4.92,4.63,7.21
		c1.12,1.51,2.66,2.71,4,4.07c3.2,3.22,3.49,6.38,0.72,10.27c-0.42,0.6-1.89,1.11-2.48,0.83c-4.89-2.35-9.77-4.7-13.26-9.17
		c-1.61-2.06-2.77-4.2-3.64-6.64c-0.8-2.23-3.67-2.75-5.63-1.16c-1.23,1-2.26,2.24-3.47,3.27c-1.01,0.87-3.33,0.75-4.53-0.04
		c-1.9-1.26-3.53-2.79-4.51-4.86c-1.08-2.26-0.63-4.78,1.44-5.83c0.85-0.43,1.77-0.77,2.57-1.28c2.86-1.8,3.32-4.18,0.77-6.12
		c-2.48-1.88-3.88-4.13-4.43-7.08c-0.17-0.94-0.47-1.85-0.77-2.76c-0.09-0.28-0.4-0.7-0.61-0.69c-0.57,0.02-1.14,0.17-1.68,0.37
		c-0.28,0.1-0.54,0.37-0.73,0.61c-1.16,1.52-2.23,3.12-3.47,4.58c-1.27,1.5-1.94,3.17-2.51,5.06c-1.28,4.23-1.57,8.73-3.89,12.71
		c-0.89,1.53-0.63,3.73-0.94,5.61c-0.2,1.18-1.73,1.82-3.01,1.45c-2.91-0.85-5.2-2.31-6.31-5.34c-0.3-0.81-1.15-1.46-1.84-2.09
		c-0.56-0.52-1.21-0.44-1.74,0.09c-0.66,0.67-1.49,1.27-1.91,2.07c-0.73,1.41-1.15,2.97-1.81,4.42c-1,2.21-4.53,2.98-6.61,1.5
		c-0.26-0.19-0.52-0.38-0.75-0.59c-2.72-2.45-2.91-4.59-0.29-7.19c1.28-1.27,2.07-2.52,1.18-4.14c-1.93-3.52-1.5-7.49-2.28-11.22
		c-0.24-1.15,0.35-2.48,0.53-3.73c0.23-1.58,0.45-3.15,0.61-4.74c0.11-1.09-1.13-2.58-2.23-2.72c-0.95-0.12-1.91-0.25-2.85-0.21
		c-2,0.08-3.8-0.29-5.28-1.74c-2.33-2.3-5.19-2.29-8.08-1.65c-1.54,0.34-3.12,0.88-4.46,1.7c-8.56,5.22-16.22,11.38-20.82,20.62
		c-0.33,0.67,0.28,1.82,1.26,2.2c0.59,0.23,1.27,0.24,1.86,0.47c1.43,0.56,2,1.58,1.7,2.92c-0.56,2.52-1.82,3.89-3.51,3.55
		c-1.25-0.25-2.55-0.88-3.48,0.39c-1.57,2.12-3.85,3.33-5.85,4.9c-1.99,1.56-3.16,3.39-1.49,6.71c0.43,0.85,0.93,1.71,1.13,2.63
		c0.2,0.9,0.31,1.97,0,2.79c-1.14,3.05-2.64,5.9-6.24,6.64c-2.51,0.52-3.83,2.3-4.65,4.42c-1.28,3.28-2.31,6.64-2.58,10.19
		c-0.19,2.55-0.25,5.14-0.75,7.63c-0.43,2.16-0.95,4.54-2.21,6.24c-3.3,4.45-5.34,9.43-7.25,14.53c-0.55,1.48-1.24,3.02-2.25,4.2
		c-3.31,3.87-4.79,4.99-9.88,3.19c-3.65-1.29-7.01-3.08-10.4-4.88c-6.96-3.69-9.77-9.77-10.14-17.26
		c-0.19-3.85,0.02-7.62,1.77-11.24c1.28-2.64,2.72-5.04,4.95-6.96c2.9-2.51,5.63-5.2,9.11-6.98c0.69-0.35,1.44-1.62,1.32-2.32
		c-0.37-2.17-1.7-4.25-0.96-6.5c1.29-3.96,0.88-8.26,2.57-12.13c0.51-1.16,0.91-2.39,1.16-3.63c0.49-2.37,1.89-3.94,3.83-5.25
		c1.3-0.88,2.48-1.98,3.58-3.12c0.7-0.72,0.83-1.81,0.07-2.56c-1.49-1.47-2.01-3.15-1.76-5.2c0.24-1.9,0.47-3.82,0.43-5.73
		c-0.04-1.57-0.04-3.41-0.83-4.64c-1.87-2.91-1.28-5.46,0.3-8.09c1.15-1.92,2.21-3.91,3.57-5.67c3.92-5.05,8.05-9.93,11.33-15.46
		c1.33-2.24,3.34-3.95,5.65-5.07c4.31-2.08,8.68-4.08,13.13-5.83c2.95-1.16,5.99-2.07,9.31-2.04c4.83,0.04,9.42-1.64,14.09-2.7
		c3.44-0.78,6.64-2.23,8.56-5.62c0.68-1.2,2.1-1.25,3.22-0.13c1.94,1.96,4.19,2.96,6.88,2.71c2.85-0.27,5.72-0.61,8.52-1.18
		c2.81-0.57,5.51-1.84,8.34-2.15c5.92-0.64,9.82-4.24,13.49-8.34c0.74-0.83,0.7-2.71-0.12-3.54c-1.12-1.12-2.38-2.11-3.48-3.26
		c-1.29-1.35-1.61-3.92-0.27-5.29c2.43-2.48,3.4-5.23,3.08-8.66c-0.11-1.2,0.33-2.54,0.83-3.68c1.04-2.33,2.23-4.6,3.46-6.84
		c1.61-2.92,3.85-5.04,7.32-5.62c3.46-0.58,6.94-1.17,10.34-2.05c2.06-0.54,3.61,0.04,5.41,0.91c5.19,2.5,11.28,0.47,14-4.45
		c0.46-0.83,0.93-1.71,1.13-2.63c0.79-3.58,2.77-5.61,6.6-5.63c0.54,0,1.2-0.5,1.58-0.96c0.36-0.43,0.76-1.24,0.58-1.65
		c-0.62-1.45-1.48-2.79-2.25-4.18c-0.87-1.58-0.83-2.84,0.03-3.4c1.21-0.8,2.5-0.66,3.66-0.16c2.05,0.89,3.91,2.24,6.01,2.96
		c2.1,0.73,3.23,1.89,3.26,4.09c0.03,2.57,1.39,4.08,3.68,5.03c1.47,0.61,2.85,1.43,4.28,2.16c1.96-1.92,2.58-4.42,3.73-6.63
		c0.19-0.37,1.16-0.72,1.5-0.55c1.99,0.99,3.84,2.21,6.16,2.45c3.31,0.33,6.05,2.01,8.51,4.11c0.94,0.8,2,1.74,2.4,2.84
		c1.34,3.67,3.53,6.76,5.8,9.88c3.86,5.31,5.6,11.43,6.02,17.94c0.08,1.28,0.1,2.56,0.1,3.84c0.01,4.8,0.05,9.56,1.56,14.23
		c0.94,2.93,0.11,5.7-2.03,7.99c-4.21,7.52-11.61,11.13-20.23,15.87C1337.78,1582.58,1342.39,1572.76,1352.08,1572.17z" />
    <path class="st0" d="M1173.61,1371.55c0-0.19,0.22-1.21-0.04-2.09c-1.35-4.74,0.36-8.72,2.79-12.62c1.01-1.62,1.83-3.38,2.53-5.16
		c1.03-2.63,2.86-3.85,5.6-4.6c4.13-1.12,8.07-1.46,12.22-0.16c2.75,0.86,5.66,0.88,8.53,0.19c3.48-0.83,6.68-2.09,9.09-4.93
		c1.24-1.45,2.66-2.77,4.08-4.05c1.2-1.08,2.57-1.9,4.32-1.62c1.75,0.28,3.09-0.46,4.24-1.68c1.34-1.44,2.42-3.06,2.33-5.09
		c-0.08-1.9-0.14-3.9-0.73-5.67c-1.69-5.03-1.58-10.05-0.54-15.14c0.19-0.94,0.35-1.89,0.5-2.83c0.56-3.57,0.07-7.01-1.43-10.29
		c-0.93-2.03-1.97-4.02-2.79-6.1c-0.58-1.47-1.01-3.02-1.28-4.58c-0.48-2.73,0.05-5.2,2.89-6.38c2.47-1.03,4.24-2.86,5.87-4.8
		c1.21-1.44,2.21-3.1,3.07-4.79c1.59-3.13,2.9-6.4,4.52-9.51c2.38-4.59,2.29-9.26,0.4-13.86c-0.77-1.89-1.3-3.6-0.48-5.47
		c1.04-2.37,2.47-4.51,4.77-5.83c0.8-0.46,1.81-0.63,2.75-0.72c5.25-0.49,9.83-2.58,14.09-5.58c1.3-0.92,2.69-1.73,4.11-2.46
		c3.87-1.98,7.79-2.69,11.91-0.57c1.97,1.02,4.15,1.64,6.11,2.67c1.82,0.96,3.63,0.99,5.56,0.67c1.7-0.28,3.15,0.18,3.97,1.85
		c0.35,0.71,0.84,0.97,1.51,0.73c3.18-1.17,6.19-0.53,9.34,0.42c3.12,0.94,4.99,2.72,5.69,5.79c0.43,1.91,1.14,3.63,2.44,5.12
		c2.45,2.83,1.86,5.89,0.53,8.96c-0.13,0.29-0.25,0.6-0.45,0.85c-4.5,5.72-6.06,12.58-7.59,19.46c-0.35,1.56-0.61,3.14-1.07,4.66
		c-0.76,2.47-2.05,4.68-3.76,6.62c-1.7,1.91-3.48,3.75-5.16,5.67c-5.4,6.18-9.08,13.09-8.94,21.58c0.04,2.59-0.42,5.05-1.56,7.43
		c-1.66,3.46-3.06,7-3.07,10.98c-0.01,2.27-1,4.36-2.24,6.25c-3.52,5.33-6.95,10.7-9.28,16.7c-0.34,0.87-0.94,1.7-1.62,2.35
		c-3.31,3.13-4.18,6.99-3.65,11.36c0.49,4.12,0.84,8.26,1.26,12.39c0.3,2.92,0.42,6.07-1.45,8.31c-2.44,2.93-2.06,4.93,0.52,7.33
		c1.39,1.29-0.26,2.02-1.07,2.9c-1.14,1.24-1.1,2.5,0.08,3.19c2.23,1.3,4.37,2.47,4.84,5.46c0.3,1.96,1.98,3.19,3.58,4.31
		c1.58,1.11,3.11,2.33,3.74,4.24c1.15,3.46,3.5,6.01,5.98,8.55c2.15,2.2,2.52,5.13,2.25,8.04c-0.33,3.55-1.6,6.85-3.43,9.89
		c-2.31,3.83-4.69,7.61-7.16,11.34c-0.68,1.03-1.72,1.88-2.74,2.61c-3.66,2.65-7.43,4.83-12.24,4.85c-2.15,0.01-4.31,1.05-6.44,1.72
		c-4.14,1.29-7.65,0.04-10.43-2.97c-1.71-1.85-3.21-4.03-4.24-6.32c-2.74-6.09-5.7-12.11-5.89-19.07c-0.12-4.2-3.08-6.9-6.38-9.03
		c-1.01-0.65-2.42-0.92-3.66-0.94c-7.31-0.11-13.7-2.65-19.46-7.01c-5.39-2.06-8.44-6.55-11.46-11.07
		c-0.71-1.06-1.16-2.43-2.11-3.17c-4.01-3.1-5.93-7.46-7.44-12.03c-0.67-2.03-2.01-3.16-3.73-4.08c-0.46-3.67-4.47-6.25-3.2-10.47
		C1173.58,1373.64,1173.51,1372.96,1173.61,1371.55z" />
    <path class="st0" d="M1459.43,1113.23c-0.63-0.07-1.28-0.27-1.89-0.19c-1.42,0.18-2.54-0.34-3.28-1.47
		c-0.87-1.32-1.78-2.68-2.3-4.15c-2.63-7.4-8.02-11.66-15.6-12.24c-7-0.54-11.92-3.97-16.24-8.92c-0.21-0.24-0.42-0.48-0.63-0.72
		c-8.37-9.49-8.61-9.43-11.84-21.42c-2.09-7.76-5.31-15.04-8.32-22.42c-0.84-2.07-1.77-4.11-2.46-6.23
		c-0.41-1.26,0.1-2.49,0.92-3.56c0.73-0.95,2.52-1.69,3.35-1.24c1.39,0.76,2.77,1.57,4.08,2.46c1.4,0.95,2.85,1.42,4.5,0.92
		c1.55-0.47,3.02-0.67,4.67-0.24c5.57,1.46,11.25,2.26,16.99,2.7c1.59,0.12,3.17,0.38,4.74,0.7c5.71,1.15,11.42,1.03,17.17,0.28
		c4.11-0.54,8.26-0.78,12.4-1.16c3.04-0.28,3.48-0.85,3.84-5.02c0.09-1.08,0.97-1.54,1.96-0.86c1.9,1.31,3.71,2.54,6.13,1.14
		c0.81-0.47,1.77-0.55,2.74-0.03c2.53,1.36,5.1,2.64,7.67,3.93c1.72,0.86,3.69,0.51,5.27-0.99c0.69-0.65,1.22-1.48,1.83-2.21
		c0.87-1.03,1.98-1.46,3.35-1.48c3.22-0.05,6.31-0.08,8.79,2.66c1.25,1.39,3.09,2.23,4.65,3.35c0.78,0.56,1.72,1.03,2.24,1.78
		c2.86,4.2,6.96,7.46,9.17,12.16c0.62,1.31,0.58,2.38-0.4,3.44c-0.43,0.47-0.8,1-1.17,1.52c-1.77,2.52-0.5,5.52,1.38,6.76
		c3.18,2.12,6.58,3.7,10.47,4.41c6.01,1.1,10.79,4.87,13.26,9.82c0.44,0.89,0.54,2.41-0.32,2.56c-3.56,0.63-3.7,4.89-6.79,6.02
		c-1.18,0.43-0.96,2.31,0.45,2.89c2.24,0.91,3.53,2.65,4.78,4.51c0.72,1.07,0.67,2.57-0.14,3.55c-0.41,0.49-0.9,0.92-1.28,1.43
		c-1.72,2.29-3.5,4.54-5.09,6.92c-1.86,2.79-2.55,5.81-1.31,9.11c0.44,1.18,0.93,2.48,0.88,3.7c-0.15,3.14,1.3,5.48,3.13,7.78
		c0.72,0.9,0.25,1.93-1.02,3.14c-0.52,0.49-2.31,0.85-0.76,1.89c2.72,1.82,3.33,4.62,3.7,7.44c0.14,1.09-0.53,2.52-1.26,3.44
		c-2.26,2.83-2.43,5.67-0.92,8.89c1.21,2.58,1.91,5.4,3.16,7.96c1.12,2.29,2.51,4.41,2.81,7.05c0.14,1.24,4.35,6.51,5.57,7.49
		c1.74,1.4,3.58,2.68,5.22,4.19c2.32,2.14,2.25,6.44-0.08,8.65c-2.08,1.98-4.34,3.77-6.37,5.8c-1.16,1.16-1.47,2.78-1.3,4.45
		c0.23,2.32,2.09,4.05,4.55,3.89c0.81-0.05,1.79-0.62,2.32-1.25c0.73-0.87,0.3-2.84,1.8-2.89c1.12-0.03,2.31,0.88,3.39,1.5
		c1.11,0.63,2.24,1.3,3.15,2.17c1.12,1.09,2.52,2.26,2.92,3.65c1.11,3.83,3.31,6.85,5.87,9.75c0.82,0.93,1.36,2.16,1.82,3.34
		c0.35,0.89,0.28,1.89-0.66,2.54c-0.8,0.56-1.61,0.92-2.65,0.53c-1.23-0.47-2.38-1.1-2.95-2.29c-0.82-1.72-1.5-3.52-2.1-5.33
		c-0.53-1.61-1.64-2.7-2.87-3.73c-1.56-1.29-3.18-2.14-5.19-0.86c-0.81,0.51-1.68,0.91-2.54,1.35c-1.31,0.67-2.39,0.33-3.4-0.69
		c-0.65-0.67-1.39-1.27-2.15-1.81c-0.64-0.46-2.19-0.18-2.6,0.53c-0.62,1.08-1.08,2.25-1.63,3.38c-0.47,0.96-1.43,1.37-2.32,0.91
		c-0.85-0.43-1.59-1.12-2.47-1.42c-0.83-0.29-2.25-0.67-2.62-0.27c-3.1,3.29-6.41,1.52-9.61,0.53c-2.73-0.84-5.35-2-8.02-3.04
		c-2.46-0.96-4.34,0.01-6,1.73c-0.88,0.91-1.66,1.92-2.57,2.81c-0.75,0.73-2.87,0.12-3.27-0.93c-0.45-1.18-0.93-2.37-1.18-3.59
		c-0.66-3.12-0.97-6.34-1.85-9.39c-1.02-3.52-3.13-6.28-7.08-6.99c-5.14-1.96-10.73,0.63-15.84-1.53
		c-2.66-3.39-2.14-6.89-0.06-10.25c1.2-1.94,2.49-3.62,0.01-5.46c-0.58-0.57-1.15-1.13-1.72-1.71c-2.3-2.81-6.11-3.22-8.77-5.51
		c-0.44-0.38-1.84,0.03-2.63,0.42c-2.9,1.4-4.84-0.04-6.57-2.08c-0.61-0.72-0.9-1.69-0.29-2.6c2.18-3.25,5.49-5.18,8.7-7.17
		c0.98-0.61,2.51-0.27,3.73-0.59c3.3-0.86,6.49-4.25,6.04-7.6c-0.6-4.45,1.51-7.26,4.21-10.05c0.65-0.68,1.53-1.17,2.05-1.92
		c0.24-0.35,0.07-1.25-0.23-1.68c-1.88-2.72-4.02-3.52-6.44-2.46c-1.45,0.64-2.85,1.39-4.27,2.12c-2.65,1.36-5.04,0.65-6.99-1.23
		c-1.58-1.52-2.78-3.44-4.17-5.16c-1.38-1.72-2.79-3.42-4.19-5.13c-3.84-1.04-3.1-4.25-3.32-7L1459.43,1113.23z" />
    <path class="st0" d="M1177.12,1261c-3.66,1.08-7.25,2.61-10.98,3.11c-4.13,0.55-7.93,2.25-12.01,2.76
		c-1.64,0.2-3.13,0.62-4.37,1.71c-2.42,2.14-4.97,3.86-8.26,4.57c-2.24,0.48-3.79,2.32-5.03,4.22c-1.75,2.67-3.35,5.44-5.09,8.12
		c-0.87,1.33-1.79,2.66-2.91,3.77c-1.88,1.87-4.21,2.81-6.93,2.7c-1.84-0.07-3.72-1.58-4.12-3.4c-0.64-2.91-0.31-5.7,0.94-8.4
		c1.21-2.6,2.43-5.2,3.59-7.83c0.92-2.07,1.27-4.26,1.26-6.53c-0.03-4.25-0.71-8.24-3.7-11.58c-1.86-2.07-2.18-4.52-1.26-7.16
		c0.73-2.11,1.43-4.23,2.05-6.37c1.16-4.01,2.75-7.82,5.04-11.34c1.22-1.87,2.28-3.85,3.42-5.77c1.67-2.82,2.46-5.89,2.45-9.14
		c-0.03-7.98,0.51-15.98-0.44-23.94c-0.27-2.25-0.47-4.54,0.51-6.6c1.56-3.29,1.8-6.76,1.86-10.28c0.07-3.84,0.64-7.55,1.73-11.29
		c1.69-5.83,2.13-11.94,2.44-18.01c0.07-1.27-0.02-2.56-0.18-3.82c-0.48-3.87-2.31-5.98-5.89-6.44c-1.58-0.2-3.18-0.39-4.71-0.82
		c-5.51-1.55-11.02-3.09-16.79-3.31c-0.74-0.03-1.37-1.57-1.04-2.31c0.13-0.29,0.15-0.67,0.36-0.88c2.39-2.47,3.12-5.46,2.95-8.79
		c-0.09-1.69,0.6-3.07,2.03-4.08c1.56-1.1,3.03-2.33,4.61-3.4c0.78-0.53,1.71-0.91,2.69-0.4c3.93,2.07,7.96,3.87,10.91,7.52
		c2.67,3.31,6.4,5.17,10.74,5.74c2.2,0.29,4.35,0.98,6.53,1.51c4.07,0.98,7.96,2.35,11.2,5.16c0.72,0.63,1.48,1.23,2.27,1.76
		c3.6,2.4,8.97,1.6,11.24,6.47c1.99,4.25,6.73,5.78,9.62,9.19c1.46,1.72,3.18,3.21,5.4,3.83c4,1.12,6.54,3.57,7.97,7.52
		c0.72,2,3.43,1.89,4.61,0.02c0.33-0.52,0.46-1.17,0.73-1.74c0.26-0.57,0.46-1.22,0.89-1.63c0.42-0.4,1.18-0.86,1.63-0.74
		c3.66,1.02,5.32-1.57,6.83-3.95c1.44-2.27,3.4-2.8,5.72-2.58c1.89,0.18,3.76,0.68,5.63,1.07c2.32,0.48,4.27,1.66,5.16,3.86
		c1.09,2.67,3.29,3.67,5.66,4.75c2.4,1.09,2.43,2.28,0.43,4.28c-0.45,0.45-1,0.8-1.45,1.25c-2.02,2.04-2.66,3.97-1.57,4.78
		c1.54,1.14,3.04,0.52,4.43-0.33c2.18-1.32,4.37-2.54,6.8-3.45c4.76-1.78,8.81-5.02,13.59-6.91c2.25-0.89,3.96-3.09,5.98-4.63
		c0.57-0.44,1.18-0.1,1.41,0.71c0.63,2.29,0.31,4.24-1.44,6.12c-2.17,2.34-2.01,2.49,0.2,4.43c0.24,0.21,0.53,0.36,0.8,0.53
		c1.15,0.72,2.06,1.64,1.52,3.09c-0.9,2.44-1.23,4.92-1.14,7.51c0.05,1.63-0.57,3.19-1.75,4.33c-1.45,1.4-2.24,3-2.44,5.02
		c-0.16,1.58-0.7,3.11-0.98,4.68c-0.46,2.58-0.1,4.95,1.15,7.39c1.63,3.17-0.51,7.02-3.9,6.7c-4.29-0.4-6.76,0.77-7.5,5.13
		c-0.28,1.63-1.27,2.98-2.61,3.89c-1.6,1.09-3.05,2.26-4.17,3.88c-0.76,1.09-1.86,1.67-3.27,1.2c-3.09,1.7-6.62,1.99-9.94,2.95
		c-2.22,0.64-4.13,1.63-5.89,3.04c-2.42,1.94-3.7,4.36-3.19,7.53c0.2,1.26,0.43,2.52,0.69,3.77c1.21,5.8-1.55,9.82-5.91,13.07
		c-1.09,0.81-2.41,0.66-3.66,0.21c-2.39-0.87-4.36,0.15-6.28,1.38c-1.66,2.43-3.56,4.67-5.62,6.78c-1.2,1.25-2.33,2.57-3.82,3.52
		c-0.88,0.21-1.76,0.31-2.66,0.3C1186.96,1260.57,1181.9,1258.8,1177.12,1261z" />
    <path class="st0" d="M827.76,1222.96c-0.05,4.15-0.06,8.3-0.18,12.45c-0.07,2.32,0.78,4.38,1.54,6.48c1,2.76,1.79,3.08,5.11,2.33
		c0.9-0.2,1.87-0.38,2.77-0.29c0.52,0.06,1.43,0.76,1.39,1.06c-0.75,5.05,1.27,10.13-0.22,15.19c-0.49,1.68,0.52,2.53,2.8,2.86
		c0.63,0.09,1.32-0.08,1.9,0.12c0.47,0.17,1.22,0.82,1.15,1.06c-0.76,2.68,0.62,4.95,1.14,7.4c0.78,3.75,2.89,6.28,6.12,8.08
		c0.26,0.15,0.44,0.45,0.66,0.67c0.7,0.67,0.63,3.01-0.23,3.49c-5.97,3.3-10.25,8.52-14.99,13.2c-1.73,1.7-3.66,2.47-6,2.46
		c-0.96,0-1.95,0.17-2.87-0.01c-4.66-0.95-8.75,0.62-12.91,2.44c-2.99,1.31-5.67,2.89-7.9,5.26c-1.53,1.63-3.17,3.16-4.74,4.76
		c-4.14,4.22-7.01,9.04-7.64,15.08c-0.23,2.21-0.94,4.37-1.53,6.53c-0.53,1.95-1.84,3.39-3.6,4.27c-1.7,0.85-3.5,1.56-5.33,2.11
		c-4.54,1.36-8.62,4.34-13.69,3.91c-0.59-0.05-1.27,0.24-1.81,0.55c-4.02,2.28-8.41,2.86-12.94,3c-1.55,0.05-3.16,0.54-4.6,1.15
		c-2.62,1.1-4.94,2.66-7.93,3.18c-4.51,0.77-6.87,5.29-5.48,9.52c0.69,2.12,1.7,4.14,2.61,6.18c0.95,2.15,0.88,4.36,0.49,6.59
		c-0.11,0.6-0.45,1.17-0.7,1.74c-0.45,1.04-2.4,1.48-3.27,0.62c-1.11-1.1-2.04-2.39-3.17-3.46c-0.2-0.19-1.46,0.21-1.49,0.44
		c-0.15,1.22-0.4,2.59-0.01,3.68c0.76,2.11,2.07,3.92,4.08,5.15c0.38,0.23,0.71,1.29,0.5,1.6c-2.74,4.21-3.69,9.51-8.22,12.67
		c-1.74,1.21-2.87,3.37-4.12,5.2c-3.04,4.49-5.33,9.48-9.27,13.36c-0.88,0.86-1.4,2.13-1.94,3.28c-2.67,5.63-6.41,10.28-11.98,13.29
		c-4.06,2.2-6.81,5.75-9.42,9.38c-1.24,1.73-2.76,2.63-4.78,2.68c-2.6,0.06-5.08,0.52-7.38,1.79c-2.01,1.11-4.18,1.52-6.46,1.49
		c-1.94-0.02-3.77,0.36-5.55,1.24c-2.37,1.18-4.03,2.68-5.09,5.33c-2.36,5.92-5.17,11.66-7.9,17.42c-0.82,1.72-1.98,3.28-3.02,4.89
		c-1.78,2.73-4.03,4.93-7.04,6.31c-8.27,3.81-15.17,9.45-21.05,16.33c-2.62,3.06-5.86,4.6-9.67,5.74
		c-4.64,1.39-9.12,2.67-14.06,2.02c-2.74-0.36-4.63,1.13-4.55,3.7c0.13,4.52-2.19,7.9-4.35,11.44c-0.66,1.08-1.17,2.27-1.92,3.29
		c-0.31,0.43-1.17,0.85-1.61,0.72c-4.33-1.28-8.07,0.82-12.02,1.88c-4.15,1.12-7.53,3.23-10.32,6.56c-1.39,1.65-3.49,2.7-5.21,4.09
		c-1.48,1.2-2.89,2.49-4.32,3.74c-1.04,0.25-1.87,0.74-2.07,1.91l-0.03,0.07c-0.83,0.19-1.64,0.12-2.42-0.25
		c-0.43-3.02,0.71-5.65,2.12-8.19c0.95-1.71,1.56-3.46,1.52-5.45c-0.09-4.66,2.31-8.54,4.14-12.58c1.7-3.77,3.49-7.51,6.64-10.32
		c1.71-1.53,3.01-3.19,3.77-5.4c1.09-3.2,3.2-5.45,6.63-6.3c2.63-0.65,4.45-2.5,5.91-4.62c2.18-3.15,4.25-6.38,6.22-9.66
		c1.83-3.05,4.01-5.74,6.85-7.9c1.27-0.96,2.4-2.1,3.65-3.1c2.79-2.24,5.09-4.86,6.31-8.27c1.27-3.54,4.09-4.69,7.47-4.9
		c1.25-0.08,2.53,0.26,3.79,0.24c1.36-0.03,2.39-0.85,3.16-1.9c0.55-0.77,0.82-1.74,1.37-2.51c1.49-2.1,1.06-5.14,3.14-6.85
		c1.71-1.41,3.66-2.57,5.61-3.66c2.41-1.34,3.12-3.97,4.79-5.86c0.61-0.69,1.45-1.55,2.26-1.64c3.45-0.37,6.98-1,10.27,0.76
		c1.29,0.69,2.64,0.62,3.42-0.48c2.66-3.74,6.17-6.39,10.09-8.63c1.77-1.01,2.8-2.51,2.92-4.62c0.11-2.09,1.28-3.64,3.07-4.54
		c2.7-1.35,4.7-3.28,5.79-6.09c0.84-2.17,2.2-3.95,4.13-5.14c4.48-2.76,6.85-7.53,10.6-10.98c0.88-0.81,1.64-2.16,1.74-3.33
		c0.32-3.79,2.84-5.28,5.88-6.64c1.4-0.62,2.49-1.94,3.71-2.95c2.21-1.83,4.33-3.79,6.62-5.49c3.34-2.48,6.47-4.97,8.52-8.85
		c1.45-2.74,4.35-4.67,6.04-7.33c1.51-2.38,1.99-5.36,3.83-7.65c0.8-0.99,1.42-2.13,2.19-3.14c1.38-1.8,0.53-4.57,2.56-6.04
		c1.54-1.11,3.31-1.93,4.8-3.1c0.97-0.76,1.77-1.85,2.41-2.92c1.13-1.92,2.07-3.95,3.15-5.9c0.08-0.14,0.6-0.12,0.88-0.04
		c0.27,0.08,0.65,0.31,0.69,0.52c0.16,0.9,0.25,1.81,0.3,2.72c0.1,1.87,0.64,2.73,1.75,2.9c1.44,0.23,2.46-0.49,3.29-1.49
		c1.25-1.51,2.16-3.13,1.44-5.24c-0.62-1.79-1.23-3.61-1.61-5.46c-0.42-2.09,0.62-3.87,2.62-4.42c3.03-0.83,5.76-2.46,9.19-2.33
		c3.76,0.15,4.88-1.29,5.07-5.29c0.08-1.73,0.29-3.11,1.93-3.96c1.14-0.58,2.27-1.17,3.39-1.78c0.56-0.3,1.26-0.55,1.59-1.03
		c0.52-0.75,0.88-1.65,1.12-2.53c0.27-1-0.45-1.9-1.46-1.95c-0.63-0.03-1.27,0.12-1.9,0.11c-1.25-0.03-2.15-1.5-1.64-2.62
		c1.14-2.5,2.86-4.21,5.81-4.17c0.96,0.02,1.91,0.11,2.86,0.19c1.81,0.15,2.82-1.28,4.15-2.08c1.2-0.71,1.79-2.86,3.82-1.62
		c0.28,0.91,0.43,1.88,0.86,2.71c0.59,1.14,2.36,1.44,3.29,0.7c0.74-0.59,1.67-1.06,2.16-1.82c2.48-3.79,5.61-6.84,9.57-9.04
		c1.36-0.75,1.4-1.83,1.02-3.21c-0.66-2.42-0.69-2.62,1.27-3.6c3.43-1.7,5.23-4.43,5.9-8.1c0.68-3.74,1.54-7.45,2.21-11.19
		c0.53-2.97-1.6-6.01-5.14-6.76c-2.29-0.48-3.26-1.57-3.43-3.73c-0.13-1.6-0.29-3.33-1.71-4.27c-3.45-2.28-2.18-4.74-0.52-7.3
		c-0.49-0.71-0.93-1.45-1.32-2.21c-0.21-0.62-0.42-1.23-0.63-1.85c6.67-3.28,3.46,4.57,6.31,5.59c0.51,0.34,1.22,0.55,1.5,1.02
		c3.26,5.38,8.89,8.23,13.26,12.43c0.4,0.38,1.15,0.39,1.74,0.57c2.41,0.83,3.47,0.31,2.36-2.37c0-1.27-0.1-2.55,0.03-3.81
		c0.25-2.55-0.53-4.51-4.09-5.48c-1.22-0.34-2.53-0.35-3.74-0.71c-1.95-0.59-3.43-1.85-3.6-3.97c-0.16-2.03-0.95-3.5-2.4-4.94
		c-2.03-2.02-1.61-4.65-0.85-7.13c0.16-0.53,0.79-0.95,1.28-1.33c1.12-0.87,1.51-1.88,1.02-3.28c-0.53-1.48-1.02-2.99-1.34-4.52
		c-0.11-0.52,0.19-1.33,0.59-1.69c0.29-0.26,1.21-0.19,1.58,0.08c1.02,0.75,1.82,1.8,2.85,2.53c0.75,0.53,1.69,0.88,2.59,1.05
		c0.5,0.09,1.47-0.24,1.6-0.61c0.76-2.06,1.45-4.16,1.89-6.31c0.16-0.8-0.29-1.83-0.72-2.61c-0.6-1.1-1.63-2-2.13-3.14
		c-0.35-0.79-0.26-1.86-0.09-2.75c0.1-0.56,0.67-1.07,1.09-1.54c1.83-2.01,2.53-4.37,2.24-7.04c-0.17-1.58-0.68-3.19-0.5-4.73
		c0.18-1.5,1.03-2.93,1.59-4.39c3.94-1.82,4.78-5.6,5.93-9.22c0.12-0.38,1.03-0.55,1.6-0.71c0.19-0.05,0.56,0.22,0.7,0.44
		c0.32,0.53,0.63,1.1,0.8,1.69c0.35,1.22,0.54,2.49,0.91,3.7c0.47,1.55,1.27,2.79,2.83,3.61c2.53,1.34,3.1,3.84,3.31,6.47
		c0.23,2.94-0.76,5.66-1.59,8.39c-0.55,1.81-1.23,3.66-2.24,5.24c-2.67,4.15-2.32,7.72,1.36,11.06c0.63,0.57,0.73,1.71,1.08,2.58
		C836.68,1223.02,829.18,1220.29,827.76,1222.96z" />
    <path class="st0" d="M1017.68,1067.07c-0.04-1.58-0.01-3.17-0.16-4.74c-0.05-0.51-0.55-1.39-0.88-1.4
		c-2.87-0.11-4.6-1.98-6.32-3.88c-1.93-2.13-3.92-4.2-5.73-6.43c-1.4-1.72-2.58-3.62-3.82-5.47c-0.71-1.06-1.16-2.37-2.05-3.22
		c-2.56-2.46-3.4-5.21-2.28-8.64c0.39-1.18,0.5-2.56,0.35-3.79c-0.19-1.55-0.5-3.21-1.25-4.55c-1.72-3.05-3.72-5.95-5.65-8.89
		c-0.9-1.38-2.14-2.51-3.77-2.76c-3.32-0.53-5.04-2.35-5.48-5.66c-0.21-1.57-0.64-3.19-1.37-4.58c-1.28-2.45-1.44-4.7-0.38-7.32
		c0.56-1.38,0.2-3.15,0.18-4.74c-0.01-0.63-0.18-1.26-0.3-1.89c-1.38-7.25-3.11-14.31-8.28-20.06c-1.66-1.85-2.62-4.37-3.77-6.64
		c-1.64-3.23-3.69-5.94-7.36-7.1c-2.43-0.77-3.85-3.54-6.58-3.72c-2.52-0.17-4.21-1.22-5-3.63c-4.22-4.71-6.31-10.29-6.7-16.56
		c-0.04-0.63-0.23-1.25-0.37-1.88c-0.39-1.84-2.92-3.01-4.55-2.09c-0.82,0.47-1.63,0.98-2.39,1.55c-1.02,0.76-1.95,1.64-2.98,2.39
		c-1.23,0.89-3.06,0.65-4.41-0.51c-3.61-3.1-4.28-8.34-1.4-12.01c2.76-3.51,6.26-5.34,10.88-4.32c1.87,0.41,3.75,0.88,5.65,1.01
		c3.5,0.24,7.03,0.15,10.53,0.39c3.17,0.22,6.06,1.82,9.35,1.83c4.15,0.02,8.22,0.96,12.06,2.58c1.89,0.8,3.63,0.83,5.56,0.29
		c6.44-1.81,12.81-3.84,19.37-5.22c1.15-0.24,2.27-1.72,3.41-0.38c0.52,0.61,0.42,1.75,0.65,2.63c0.16,0.6,0.34,1.23,0.67,1.75
		c0.33,0.53,0.87,0.92,1.3,1.39c1.91,2.11,3.64,4.44,5.78,6.28c2.41,2.07,5.68,0.08,8.36,1.15c1.95,0.77,3.59,0.64,5.46-0.31
		c1.62-0.83,3.23-2.64,5.16-1.75c1.33,0.62,2.14,2.27,3.32,3.32c2.62,2.35,5.23,4.71,8,6.87c3.06,2.38,5.84,4.95,7.75,8.38
		c1.62,2.9,3.98,4.85,7.2,5.96c1.95,0.67,2.79,2.52,2.83,4.61c0.03,1.27,0,2.54,0.01,3.82c0.01,0.77,1.08,1.95,1.76,1.99
		c2.54,0.15,5.12,0.1,7.62,0.53c1.11,0.19,2.18,1.22,3.03,2.1c1.01,1.06,0.95,3.48,0,4.45c-1.1,1.13-2.25,2.25-3.51,3.2
		c-2.52,1.9-3.85,4.46-3.48,7.47c0.94,7.68-0.23,15.32-0.06,22.98c0.09,3.94,0.72,7.49,3.28,10.73c2.63,3.33,3.04,7.64,4.05,11.63
		c0.34,1.35-4.7,8.43-7.41,10.93c-1.62,1.5-4.16,1.97-5.09,4.19c-0.86,2.04-1.41,4.22-2.1,6.33c-0.1,0.3-0.22,0.6-0.38,0.88
		c-1.05,1.84-0.76,3.69-0.03,5.53c0.35,0.89,0.81,1.73,1.16,2.63c0.42,1.08-0.04,1.8-1.21,2.01c-3.63,0.65-3.67,0.64-3.77,3.76
		c-0.03,0.95-0.04,1.92-0.23,2.84c-0.16,0.79-0.77,1.06-1.38,0.76c-2.14-1.05-4.01-0.94-6.27,0.24c-3.9,2.03-6.46,5.01-8.5,8.76
		c-1.08,1.97-1.94,2.16-4.57-0.21c-2.95-2.33-6.8,0.19-9.81-1.81c-0.64,0.09-1.18-0.1-1.63-0.57
		C1018.15,1069.89,1018.78,1067.95,1017.68,1067.07z" />
    <path class="st0" d="M1424.76,1168.97c2.28,2.19,4.77,4.2,5.72,7.43c0.27,0.91,0.74,1.75,1.11,2.63
		c3.09,7.31,11.05,7.98,16.08,6.24c1.49-0.51,2.93-1.25,4.28-2.06c2.74-1.65,5.14-3.73,7.15-6.23c1.76-2.19,2.87-5.46,5.88-5.9
		c4.06-0.59,8.26-0.36,12.07,1.66c1.12,0.59,2.16,1.33,3.24,2c4.79,4.79-0.97,12.21,4.22,16.91c0.75,0.59,1.54,1.15,2.25,1.78
		c1,0.89,1.72,2.08,1.46,3.37c-0.68,3.46-0.8,6.8-0.12,10.35c0.6,3.09,0.45,6.34,1.24,9.47c0.39,1.53,0.51,3.16,0.57,4.75
		c0.14,3.2-0.07,6.34-1.21,9.43c-1.22,3.29-2.18,6.68-3.13,10.06c-0.52,1.83-0.97,3.72-1.13,5.61c-0.43,4.9,0.84,7.07,5.19,9.15
		c1.72,0.83,3.51,1.52,5.27,2.28c1.51,0.65,2.71,1.6,3.34,3.21c1.32,3.33,3.45,6.18,5.99,8.59c2.43,2.3,2.94,4.69,1.94,7.75
		c-0.38,1.16-0.57,2.67-0.14,3.74c1.1,2.77,1.11,5.61,1.22,8.48c0.12,3.12,1.34,5.52,4.48,6.66c1.95,0.71,3.11,2.21,3.54,4.2
		c0.46,2.18,0.97,4.37,1.17,6.58c0.36,4.05-2.75,6.85-6.6,5.98c-2.17-0.49-4.27-1.29-6.41-1.91c-1.6-0.46-3.38,0.7-3.52,2.29
		c-0.16,1.89-0.22,3.78-0.35,5.67c-0.05,0.7-1.63,1.2-2.31,0.85c-2.12-1.08-3.47-2.95-4.26-5c-1.72-4.44-4.42-8.5-5.09-13.36
		c-0.21-1.55-1.79-2.81-3.42-2.74c-0.78,0.04-2.21,0.94-2.17,1.35c0.78,8.54,0.15,17.21,3.23,25.54c1.52,4.12,1.81,8.71,2.62,13.09
		c0.18,0.98-0.56,2.17-1.31,2.22c-0.61,0.04-1.24-0.03-1.84-0.14c-0.3-0.05-0.59-0.24-0.84-0.43c-0.78-0.55-1.58-1.09-2.32-1.7
		c-3.15-2.66-6.11-5.56-9.79-7.52c-1.78-2.73-4.5-3.37-7.52-3.39c-0.96-0.01-1.92-0.06-2.87-0.19c-4.21-0.58-6.76-3.98-5.93-8.25
		c0.67-3.44,1.77-6.79,2.52-10.22c0.8-3.66-0.21-6.93-2.65-9.73c-0.62-0.72-1.43-1.27-2.14-1.92c-1.27-1.16-1.64-2.68-1.7-4.32
		c-0.14-3.89,0.38-7.67,2.32-11.11c0.97-1.72,1.59-3.49,1.49-5.46c-0.14-2.97,0.66-5.74,1.96-8.31c2.14-4.22,2.15-8.66,1.57-13.11
		c-0.41-3.15-1.26-6.26-2.13-9.33c-0.33-1.17-1.12-2.33-1.99-3.2c-2.07-2.05-3.8-4.26-5.04-6.92c-1.22-2.63-3.08-4.8-5.36-6.66
		c-1.97-1.61-3.69-3.51-5.59-5.22c-1.93-1.74-6.13-1.29-7.85,0.83c-0.38,0.47-0.76,1.12-0.76,1.69c0,1.59,0.33,3.17,0.31,4.76
		c-0.03,2.36-1.05,4.31-2.91,5.79c-1.07,0.85-2.36,1.2-3.57,0.65c-2.02-0.93-4.12-1.87-5.84-3.25c-1.76-1.41-3.53-2.57-5.63-3.38
		c-0.61-0.24-1.1-1.54-1.13-2.37c-0.03-0.89,0.48-1.82,0.87-2.69c0.97-2.16,1.29-4.33-0.12-6.38c-1.82-2.65-1.95-5.43-0.52-8.15
		c1.6-3.04,1.52-6.15,0.92-9.32c-0.65-3.47-1.09-6.94-0.95-10.47c0.11-2.75-0.52-5.06-3.37-6.25c-0.54-0.23-1.02-0.81-1.34-1.34
		c-0.47-0.8-1.02-1.68-1.09-2.56c-0.1-1.23,0.76-2.86,0.23-3.68c-1.58-2.46-2-5.31-3.21-7.87c-1.08-2.3-2.14-4.66-2.82-7.09
		c-0.41-1.46-0.31-3.17-0.03-4.69c0.14-0.76,1.09-1.54,1.86-1.98c0.82-0.47,1.89-0.41,2.65,0.32c2.29,2.17,3.83,5.17,6.99,6.39
		C1416.24,1164.55,1419.7,1168.5,1424.76,1168.97z" />
    <path class="st0" d="M1282.12,1406.22c0.34-3.01,0.6-6.84,1.23-10.61c1.04-6.3,2.23-12.57,3.51-18.82
		c0.9-4.37,1.82-8.75,3.84-12.81c0.71-1.42,1.19-2.96,1.73-4.45c0.91-2.53,1.06-4.82-0.99-7.11c-1.37-1.52-1.31-3.64,0.49-5.08
		c1.33-1.06,2.28-2.21,2.36-3.93c0.12-2.75,1.47-4.83,3.44-6.6c1.43-1.28,2.86-2.55,4.24-3.88c2.33-2.24,4.39-4.76,5.14-7.95
		c0.69-2.91,2.22-5.27,3.93-7.59c1.92-2.6,3.42-5.42,4.01-8.61c0.49-2.63,1.91-4.65,3.5-6.7c3.12-4.04,6.18-8.14,9.11-12.32
		c2.04-2.91,3.48-6.06,2.93-9.84c-0.28-1.94,0.46-3.75,1.58-5.39c0.89-1.31,1.95-2.62,2.41-4.1c1.57-5.01,4.32-9.19,8-12.88
		c2.06-2.07,3.24-4.72,4.21-7.45c1.5-4.21,2.97-8.43,4.49-12.63c0.98-2.7,1.81-5.48,3.08-8.03c1.24-2.48,1.46-4.83,0.43-7.35
		c-0.79-1.92-0.54-3.72,0.59-5.45c0.87-1.34,1.55-2.82,2.54-4.06c1.56-1.97,1.78-4.02,0.91-6.27c-0.62-1.6-0.41-3.15,0.33-4.62
		c2.01-4.03,4.53-7.72,7.98-10.66c0.68-0.58,1.7-0.79,2.6-1.05c1.07-0.3,2.83,1.03,2.73,2.01c-0.09,0.94-0.12,1.91-0.36,2.81
		c-0.57,2.16-1.46,4.25-1.85,6.43c-0.68,3.84-1.83,7.38-4.35,10.45c-1.01,1.23-0.51,2.9,1.39,3.85c2.26,1.14,3.44,2.71,3.55,5.18
		c0.1,2.23,0.24,4.46,0.28,6.69c0.02,0.95-0.05,1.93-0.25,2.86c-0.69,3.18,1.86,6.13,0.5,9.42c-1.21,2.93-1.83,6.11-3.06,9.03
		c-1.85,4.38-1.69,8.69-0.31,13.09c0.67,2.13,1.29,4.28,1.92,6.42c0.46,1.56,0.53,3.06,0,4.67c-0.49,1.48-0.83,3.1-0.81,4.65
		c0.07,5.48-0.44,10.81-2.8,15.88c-0.64,1.38-0.59,3.09-0.82,4.66c-0.23,1.57-1.37,1.9-2.68,2.2c-0.54,0.12-1.33,0.64-1.4,1.08
		c-0.45,2.88-2.47,4.26-4.77,5.55c-1.93,1.08-4.19,2.1-5.44,3.78c-2.29,3.08-5.13,4.55-8.76,5.18c-1.16,0.2-2.28,1.13-3.22,1.95
		c-1.67,1.47-3.05,3.3-4.81,4.64c-2.65,2.02-4.56,4.41-5.28,7.71c-0.52,2.38-2.24,3.8-4.15,5.03c-2.69,1.74-3.66,4.25-3.59,7.35
		c0.05,2.23,0.07,4.48-0.13,6.7c-0.36,4.16-1.1,8.2-4.19,11.43c-0.85,0.88-1.34,2.14-1.86,3.3c-1.82,4.08-3.64,8.17-5.34,12.3
		c-2.19,5.3-4.61,10.52-5.61,16.25c-0.67,3.87-2.26,7.18-5.62,9.66c-1.72,1.28-2.7,3.52-4.17,5.19c-2.31,2.63-4.69,5.22-7.19,7.67
		c-0.57,0.56-1.84,0.86-2.59,0.63c-0.73-0.22-1.57-1.21-1.72-1.99C1282.55,1411.52,1282.44,1409.27,1282.12,1406.22z" />
    <path class="st0" d="M1430.93,1339.51c1.22,0.49,2.4,1.22,2.73,2.48c0.3,1.18,0.26,2.52,0.05,3.73c-0.16,0.89-0.89,1.67-1.27,2.54
		c-1.18,2.74-1.8,5.42,0.38,8.06c0.9,1.09,0.46,2.27-0.27,3.48c-1.36,2.25-1.29,2.91-0.01,4c1.45,1.24,3.07,2.31,4.4,3.67
		c0.58,0.59,1.01,1.85,0.79,2.59c-0.56,1.8-1.41,3.55-2.42,5.15c-0.44,0.7-1.54,1.27-2.39,1.36c-2.55,0.27-5.12,0.29-7.11-1.82
		c-0.43-0.46-1.04-1.14-1.49-1.09c-0.88,0.1-1.8,0.53-2.53,1.05c-1.29,0.92-2.34,2.22-3.7,2.99c-1.82,1.03-2.78,2.45-3.28,4.42
		c-0.85,3.33-3.28,5.43-6.18,6.93c-4.6,2.38-9.56,3.55-14.71,3.79c-5.42,0.25-10.86,0.14-16.26,0.55
		c-7.79,0.59-15.2-1.25-22.65-2.97c-1.53-0.35-3.03-0.99-4.45-1.7c-1.49-0.75-2.4-2.05-2.49-3.8c-0.03-0.63-0.06-1.27-0.03-1.91
		c0.08-1.88,0.78-4.04-0.95-5.36c-1.17-0.9-2.9-1.07-4.37-1.58c-1.6-0.55-2.54-2-2.31-3.69c0.17-1.25,0.29-2.56,0.73-3.73
		c2.06-5.53,6.44-10.35,11.97-14.34c3.16-2.28,6.35-4.19,10.18-4.93c0.93-0.18,1.87-0.45,2.74-0.81c2.43-1,4.44-2.43,5.42-5.05
		c0.66-1.78,1.54-3.47,2.32-5.2c4.11-2.62,5.94-7.5,9.98-10.14c1.29-0.84,2.87-1.5,4.38-1.7c4.73-0.61,9.48-1.04,14.24-1.37
		c1.78-0.12,2.82,1.26,3.58,2.73c0.6,1.16,1.58,1,2.38,0.49c1.43-0.91,2.47-1.44,4.03,0.13c2.68,2.7,5.72,5.04,8.6,7.53
		C1424.04,1337.83,1428.61,1335.49,1430.93,1339.51z" />
    <path class="st0" d="M1290.72,1038.5c3.21-0.98,6.25-0.93,8.83,1.56c2.55,2.47,5.38,4.3,9.01,4.79c1.63,0.22,2.78,1.48,3.67,2.8
		c1.76,2.62,3.43,5.3,5.13,7.96l-0.16-0.15c3.49,0.78,6.16,3.23,9.32,4.71c1.56,0.73,2.66,2.69,3.7,4.27
		c2.28,3.46,4.38,7.05,6.63,10.54c0.06,0.1,1.06-0.19,1.38-0.53c0.62-0.67,0.94-1.94,1.63-2.15c0.72-0.22,1.88,0.32,2.58,0.88
		c2.02,1.6,3.84,3.32,6.81,2.66c0.62-0.14,1.73,0.82,2.25,1.54c1.3,1.82,2.38,3.79,3.57,5.69c0.85,1.36,1.43,3.06,2.62,3.98
		c5.27,4.09,6.55,10.87,10.77,15.64c0.24,0.27-0.04,1.24-0.33,1.72c-1.72,2.82-1.75,2.81-1.18,3.85c0.76,1.4,1.52,2.8,2.25,4.23
		c1.48,2.91,2.46,5.99,1.87,9.25c-0.65,3.64,0.78,6.93,1.22,10.38c0.05,0.4-0.59,1.13-1.04,1.28c-0.48,0.15-1.34-0.03-1.66-0.4
		c-0.84-0.94-1.52-2.04-2.2-3.12c-1.91-3.04-4.42-5.42-7.48-7.3c-3.25-2-6.75-3.77-9.55-6.3c-4.06-3.67-8.72-4.99-13.93-5.22
		c-0.63-0.03-1.4,0-1.86-0.33c-1.27-0.92-2.54-1.9-3.57-3.07c-0.97-1.09-0.34-2.39,0.06-3.61c0.18-0.54,0.38-1.41,0.11-1.7
		c-1.28-1.4-2.55-2.95-4.15-3.89c-3.31-1.95-4.73-6.03-8.55-7.36c-0.48-0.17-0.89-0.95-1.05-1.52c-1.26-4.59-5.09-7.28-7.94-10.67
		c-0.97-1.16-2.55-1.88-3.96-2.6c-4.39-2.27-8.98-1.34-11.91,2.58c-1.52,2.03-2.72,4.32-3.94,6.56c-1.08,1.99-2.31,3.64-4.23,5.04
		c-2.27,1.66-4.1,3.98-5.95,6.17c-4.15,4.91-8.9,9.17-13.84,13.23c-0.39,0.32-1.16,0.29-1.74,0.25c-0.69-0.05-1.53-1.5-1.32-2.29
		c0.48-1.84,0.53-4.11,1.66-5.41c3.12-3.56,4.14-7.92,5.01-12.2c0.55-2.73,1.43-4.8,3.61-6.38c0.26-0.19,0.45-0.47,0.71-0.64
		c1.99-1.28,3.32-2.9,3.22-5.46c-0.02-0.55,0.65-1.09,0.88-1.67c1.05-2.64,0.83-2.96-2.34-3.42c-0.63-0.09-1.28-0.06-1.91-0.16
		c-2.09-0.33-3.32-2.45-2.11-4.24c3.34-4.95,3.8-10.78,5.32-16.27c0.52-1.88,0.34-3.93-1.11-5.41c-1.95-1.99-2.51-4.36-2.54-7
		c-0.03-2.87-0.13-5.74-0.18-8.62c-0.01-0.74,1.33-1.78,2.01-1.61c1.85,0.46,3.71,0.91,5.56,1.37c3.44,0.84,5.77,2.61,5.76,6.55
		c0,0.94,0.39,1.87,0.6,2.81C1289.71,1043.65,1288.95,1037.87,1290.72,1038.5z" />
    <path class="st0" d="M1412.73,873.23c-0.73,1.63-0.47,2.83,0.01,4.06c0.61,1.55,0.47,3.06-0.22,4.61
		c-1.45,3.31-1.48,6.82-1.04,10.32c0.19,1.51,1.52,3.09,0.08,4.43c-0.86,0.8-2.2,1.11-3.36,1.53c-1.31,0.48-2.06,1.34-2.41,2.68
		c-0.56,2.15-1.16,4.3-1.84,6.42c-0.44,1.39-1.32,1.49-2.88,0.42c-0.26-0.18-0.47-0.44-0.73-0.62c-3.76-2.46-7.69-1.82-9.93,1.67
		c-0.86,1.34-1.52,2.81-2.37,4.15c-0.86,1.36-2.93,1.64-4.18,0.59c-1.46-1.23-2.83-2.59-4.33-3.76c-2-1.56-3.92-3.48-6.21-4.38
		c-3.55-1.38-4.34-4.45-5.49-7.34c-0.26-0.65,0.22-1.91,0.77-2.52c1.06-1.17,2.45-2.03,3.64-3.08c4.57-4.05,6.91-9.11,6.63-15.25
		c-0.15-3.19-0.43-6.34,0.51-9.5c0.33-1.13,0.15-2.57-0.24-3.72c-1.63-4.75,0.17-9.45,0.06-14.17c-0.01-0.46,0.62-1.11,1.11-1.36
		c1.68-0.86,3.46-1.53,5.16-2.36c2.64-1.31,4.15-1.14,5.92,0.71c2.49,2.59,4.31,5.52,5.02,9.09c0.57,2.84,1.33,5.62,3.51,7.72
		c0.65,0.63,1.52,1.58,2.22,1.52c4-0.34,6.51,2.2,9.22,4.4C1412.67,870.55,1413.46,871.84,1412.73,873.23z" />
    <path class="st0" d="M1127.63,1777.64c-2.39,0-3.98,0.07-5.56-0.04c-0.58-0.04-1.46-0.37-1.64-0.81c-1.4-3.42-4.25-5.52-6.9-7.79
		c-2.08-1.78-3.23-3.89-3.15-6.63c0.09-2.96-1.3-4.78-4.13-5.62c-0.9-0.27-1.82-0.6-2.6-1.11c-0.48-0.31-0.83-0.97-1.02-1.55
		c-1.16-3.41,0.65-6.77,3.93-7.33c0.94-0.16,1.89-0.31,2.84-0.34c5.93-0.2,11.1-2.38,15.8-5.89c1.01-0.75,2.08-1.48,3.23-1.99
		c2.12-0.93,4.53-1.38,6.38,0.12c3.27,2.65,7.81,2.79,10.86,5.84c0.82,0.82,2.27,1.12,3.5,1.41c4.71,1.12,8.65,3.88,12.91,5.97
		c0.28,0.14,0.48,0.41,0.73,0.61c0.64,0.48,1.1,2.04,0.68,2.56c-0.37,0.47-0.79,1.1-1.29,1.23c-1.53,0.4-3.11,0.63-4.67,0.88
		c-3.51,0.55-5.68,2.7-6.89,5.89c-0.56,1.48-0.98,3.04-1.22,4.6c-0.62,4.04-2.66,6.19-6.96,6.3c-1.83,0.05-3.7,0.88-5.45,1.61
		C1133.74,1776.94,1130.42,1777.9,1127.63,1777.64z" />
    <path class="st0" d="M869.95,1078.56c0.38-3.45,0.25-7.03,2.42-10.05c-0.13-1.55-0.47-3.13-0.29-4.65
		c0.08-0.73,1.02-1.67,1.77-1.92c0.77-0.25,1.79,0.19,2.68,0.4c0.28,0.07,0.55,0.34,0.73,0.59c0.37,0.52,0.75,1.04,1,1.62
		c0.81,1.89,2.1,3.32,4.16,3.55c4.37,0.49,7.56,2.81,10.77,5.65c3.99,3.55,7.19,8.01,11.99,10.64c0.83,0.46,1.62,0.99,2.46,1.44
		c1.55,0.85,4.28-0.43,4.59-2.2c0.22-1.24,0.28-2.52,0.41-3.78c0.1-0.94,0.17-1.89,0.34-2.82c0.12-0.67,0.69-0.89,1.46-0.62
		c0.28,0.1,0.64,0.31,0.73,0.56c0.44,1.17,1.02,2.34,1.13,3.55c0.19,2.15,1.12,3.47,3.15,4.29c2.69,1.09,5.04,2.74,6.77,5.15
		c0.75,1.05,1.58,2.24,1.12,3.5c-0.78,2.12-0.87,4.14-0.25,6.3c0.1,0.35-0.55,1.18-1.03,1.38c-0.85,0.35-1.83,0.49-2.76,0.54
		c-1.89,0.11-3.9-0.27-5.66,0.24c-2.39,0.7-4.39,0.16-6.44-0.81c-0.86-0.41-1.74-0.8-2.56-1.29c-1.39-0.84-2.96-1.87-4.41-1
		c-0.88,0.53-1.1,2.16-1.63,3.3c-0.13,0.29-0.3,0.56-0.47,0.83c-0.66,1.1-2.36,1.73-3.2,0.99c-2.03-1.79-4.2-2.58-6.98-2.34
		c-3.5,0.29-6.19-1.55-7.92-4.46c-2.26-3.79-5.31-7.12-6.12-11.78c-0.42-2.43-1.86-4.71-2.97-6.99c-0.44-0.9-1.38-1.29-2.42-0.94
		c-0.9,0.3-1.77,0.67-2.66,1.01L869.95,1078.56z" />
    <path class="st0" d="M1102.62,927.99c-0.32-2.85,0.45-6.12,2.38-9.29c1.47-2.41,2.32-5.21,3.44-7.83c0.3-0.7,1.79-1.1,2.48-0.51
		c3.97,3.42,8.34,4.13,13.25,2.34c0.74-0.27,1.93,0.02,2.62,0.49c1.04,0.7,2.01,1.66,2.66,2.72c1.43,2.36,3.37,3.44,6.12,3.94
		c2.69,0.49,3.62,2.32,2.59,4.98c-0.85,2.21-0.09,3.66,1.1,5.2c1.41,1.82,1.07,3.66-0.2,5.26c-1.58,2-3.44,3.77-5.12,5.7
		c-1.7,1.94-3.19,3.96-3.26,6.75c-0.07,2.5-1.85,3.96-3.71,5.27c-1.37,0.96-3.13,0.94-4.46-0.1c-1.76-1.38-3.5-2.79-5.22-4.22
		c-1.48-1.23-2.93-2.48-4.89-2.91c-2.35-0.52-3.81-2.15-5.17-4C1102.4,935.25,1102.92,937.83,1102.62,927.99z" />
    <path class="st0" d="M1014.49,1815.46c1.9,0.19,3.9,0.07,5.67,0.65c3.68,1.18,7.12,2.86,8.8,6.78c0.37,0.86,0.94,1.67,1.55,2.38
		c0.62,0.73,1.48,1.28,2.5,0.92c1.2-0.42,2.31-1.09,3.52-1.47c0.87-0.28,2-0.63,2.74-0.31c3.63,1.57,7.49,1.37,11.28,1.83
		c1.51,0.18,2.97,1.05,4.37,1.76c1.3,0.66,1.85,3.27,0.88,4.22c-2.45,2.38-4.14,5.7-7.97,6.44c-2.33,0.45-3.66,2.45-5.4,3.82
		c-2.33,1.84-4.89,1.16-6.18-1.67c-0.53-1.15-0.75-2.44-1.24-3.61c-0.73-1.74-2.73-2.88-4.69-2.58c-1.56,0.23-3.17,0.5-4.62,1.1
		c-2.95,1.21-5.8,2.65-8.69,4c-1.29,0.6-2.45,0.37-3.55-0.47c-0.76-0.58-1.47-1.26-2.31-1.68c-1.78-0.88-3.68-0.97-5.57-0.31
		c-0.6,0.21-1.17,0.51-1.71,0.85c-6.88,4.27-6.63,2.15-11.21-2.06c-2.97-2.73-2.41-7.83,0.99-10.02c2.67-1.72,5.53-3.17,8.38-4.59
		c1.72-0.85,3.62-1.44,4.78-3.05C1008.75,1815.66,1011.48,1815.32,1014.49,1815.46z" />
    <path class="st0" d="M1565.07,1299.23c-0.39,3.91-1.23,8.66-3.51,12.88c-0.93,1.73-1.02,2.85,0.07,4.17
		c1.36,1.65,0.88,3.64,1.25,5.47c0.37,1.83,1.22,3.56,1.78,5.36c0.18,0.59,0.06,1.28,0.17,1.9c0.45,2.51,2.83,4.45,2.41,7.16
		c-0.33,2.18-1.34,4.26-1.63,6.45c-0.28,2.18-0.13,4.44,0.02,6.65c0.06,0.88,0.55,1.78,1,2.59c0.62,1.1,1.47,2.08,2.09,3.18
		c0.75,1.35-0.16,3.54-1.53,3.94c-1.64,0.48-3.57-0.2-4.1-1.53c-0.36-0.88-0.45-1.88-0.86-2.73c-0.53-1.13-1.02-2.81-1.92-3.12
		c-3.18-1.11-3.46-4.46-5.49-6.45c-2.33-2.28-2.04-3.34,0.02-6.71c0.16-0.27,0.55-0.46,0.6-0.73c0.11-0.57,0.26-1.28,0.02-1.72
		c-0.27-0.48-0.96-1.02-1.47-1.02c-1.26,0.01-2.52,0.26-3.77,0.5c-1.48,0.28-2.9,2.05-4.39,0.72c-1.13-1.01-1.73-2.6-2.57-3.94
		c-1.33-2.14-1.21-4.21,0.26-6.24c0.37-0.51,0.74-1.07,0.95-1.66c1.73-4.8,3.52-9.57,1.75-14.83c-0.53-1.58-0.09-3.21,1.51-4.15
		c1.18-0.7,1.98-1.68,2.15-2.99c0.3-2.31,1.41-3.19,3.79-3.18c2.08,0.01,2.82-0.96,3.16-2.86c0.28-1.57,0.45-3.16,0.79-4.72
		c0.13-0.58,0.61-1.09,0.92-1.64c0.56-0.97,2.52-1.16,3.31-0.31C1564.35,1292.35,1565.19,1294.6,1565.07,1299.23z" />
    <path class="st0" d="M1140.61,1020.37c1.63,0,3.12,2.06,2.21,3.23c-1.2,1.55-2.2,3.02-1.95,5.13c0.54,4.52-0.13,8.85-1.71,13.16
		c-0.98,2.67-1.54,5.58-1.76,8.42c-0.53,6.8-2.7,13.11-5.18,19.34c-0.95,2.4-1.99,4.64-1.21,7.36c0.21,0.72-0.3,2.01-0.91,2.54
		c-2.1,1.84-2.48,4.36-3.18,6.8c-0.07,0.24-0.44,0.63-0.55,0.59c-0.58-0.19-1.34-0.35-1.65-0.79c-1.45-2.06-2.79-4.2-4.14-6.33
		c-0.6-0.94-0.13-2.47,1.03-3.3c0.77-0.55,1.68-0.93,2.43-1.5c1.09-0.82,1.22-2.64,0.21-3.33c-2.65-1.8-5.16-3.75-7.16-6.27
		c-0.57-0.71-1.11-1.65-1.19-2.52c-0.36-3.99,0.44-8.09,3.59-10.44c4.82-3.58,5.6-8.26,5.37-13.52c-0.1-2.22-0.66-4.41-0.92-6.63
		c-0.25-2.13,1.16-4.55,3.13-5.49c1.15-0.55,2.3-1.2,3.52-1.47c3.23-0.72,5.92-2.27,8.26-4.57
		C1139.22,1020.42,1140.01,1020.5,1140.61,1020.37z" />
    <path class="st0" d="M916.11,1918.42c-0.81,4.04-1.55,8.12-2.53,12.15c-0.18,0.73-1.2,1.45-2,1.79c-0.9,0.38-2.66-0.91-2.89-1.99
		c-0.26-1.24-0.36-2.51-0.54-3.76c-0.22-1.53-0.23-1.68-2.49-1.66c-1.35,0.01-2.45-0.45-3.65-0.84c-1.66-0.54-1.75-0.42-2.84,1.52
		c-0.15,0.27-0.18,0.62-0.31,0.9c-0.73,1.61-2.04,2.83-3.73,2.12c-3.53-1.5-5.9-0.32-8.41,2.24c-1.57,1.6-3.11,3.06-4.11,5.12
		c-0.35,0.72-1.59,1.59-2.26,1.47c-2.4-0.43-4.28,0.21-6.2,1.62c-2.15,1.57-4.93,1.73-7.38,1.52c-2.7-0.24-5.04,0.23-7.4,1.3
		c-0.79,0.36-2.43-0.19-2.5-0.77c-0.11-0.92-0.28-1.97,0.04-2.78c0.84-2.11,2.16-3.94,4.28-4.98c3.14-1.54,6.37-2.92,9.48-4.52
		c3.68-1.89,7.52-3.53,10.14-7.04c0.7-0.93,2.03-1.75,3.18-1.91c3.62-0.5,6.38-2.91,9.69-4c1.61-0.53,2.69-1.39,3.76-2.67
		c2.51-2.97,5.79-4.96,9.31-6.59c3.26-1.51,5.2-0.71,5.45,2.53c0.18,2.36,0.78,4.28,2.89,5.67
		C916.25,1915.63,916.14,1917.1,916.11,1918.42z" />
    <path class="st0" d="M1125.44,735.1c0.28,0.91,0.49,1.84,0.84,2.71c0.53,1.3,0.41,2.53-0.47,3.56c-0.82,0.96-1.87,1.72-2.74,2.66
		c-1.74,1.88-1.84,4.95-0.21,6.98c0.59,0.74,1.36,1.33,2.05,1.99c1.2,1.14,1.89,2.55,2.14,4.17c0.24,1.58,0.21,3.23,0.69,4.72
		c1.29,4.07-0.19,7.54-2.18,10.87c-1.37,2.3-3.64,3.48-6.2,4.12c-2.05,0.51-4.63-1.16-5.05-3.33c-0.38-1.93-0.49-4.02,0.73-5.54
		c3.88-4.8,1.87-9.16-1.22-12.95c-1.9-2.33-3.3-4.39-3.53-7.48c-0.22-2.94-2.01-5.32-4.51-7c-1.49-1-2-2.46-1.86-4.11
		c0.2-2.21,0.53-4.41,0.89-6.6c0.18-1.08,1.85-2.17,2.94-1.76c2.2,0.82,4.23,1.12,6.2-0.64c1.25-1.12,2.88-1.24,4.51-0.95
		c1.26,0.23,2.51,0.52,3.77,0.62c2.55,0.19,3.94,1.83,5.09,3.83C1128.5,733.17,1126.27,733.82,1125.44,735.1z" />
    <path class="st0" d="M1207.83,1253.35c0.44,0.46,0.83,0.98,1.32,1.38c4.25,3.44,5.09,7.96,3.88,12.95
		c-1.36,5.6-3.35,11.02-6.19,16.03c-1.23,2.18-3.02,4.1-4.81,5.89c-1.37,1.37-3.16,2.03-5.27,1.36c-0.81-0.26-1.85-0.25-2.65,0.04
		c-1.69,0.6-2.88,2.84-5.06,1.75c-1.93-0.97-1.75-3.28-2.69-4.88c-0.63-1.08-1.68-1.91-2.34-2.97c-0.24-0.39-0.08-1.37,0.26-1.69
		c0.92-0.86,2.14-1.4,3.03-2.29c0.64-0.64,1.43-1.73,1.29-2.46c-1.24-6.41,2.27-10.97,5.8-15.55
		C1198.67,1259.43,1199.27,1250.8,1207.83,1253.35z" />
    <path class="st0" d="M1415.59,1160.57c-2.27-3.86-4.16-7.88-5.63-12.12c-0.72-2.09-1.75-4.08-2.63-6.12
		c-0.39-0.91,0.06-1.84,1.05-2.19c3.32-1.18,6.64-2.27,10.27-1.87c1.26,0.14,2.56-0.02,3.83,0.08c3.79,0.3,6.63,2.06,8.28,5.57
		c0.41,0.87,0.79,1.75,1.15,2.64c1.31,3.27,2.74,6.46,5.38,8.95c0.97,0.91,1.08,2.26,1.01,3.56c-0.19,3.52-1.99,5.55-5.26,5.56
		c-2.54,0.01-5.07-0.37-7.61-0.44c-0.88-0.02-1.77,0.37-2.66,0.57C1417.97,1167.44,1416.6,1164.31,1415.59,1160.57z" />
    <path class="st0" d="M1189.56,1040.63c3.5-0.13,6.98-0.03,10.32,1.24c1.23,0.47,2.26,1.15,2.93,2.27c0.49,0.81,0.84,1.7,1.29,2.54
		c3.7,6.97,3.74,7.01,1.49,14.28c-0.9,2.92-3.02,4.78-5.86,5.76c-0.55,0.19-1.43,0.25-1.82-0.06c-2.94-2.32-6.71-3.61-8.65-7.18
		c-1.77-3.24-4.54-5.15-8.23-5.84c-2.71-0.5-4.43-2.33-5.27-4.94c-0.82-2.54,0.35-5.48,2.68-6.41c1.75-0.7,3.58-1.33,5.43-1.6
		C1185.73,1040.42,1187.66,1040.63,1189.56,1040.63z" />
    <path class="st0" d="M1618.92,1357.83c-0.8,2.61,0.12,4.39,2.12,5.84c0.75,0.55,1.31,1.37,1.9,2.11c1.67,2.08,1.32,4.11-0.96,5.59
		c-1.06,0.69-2.12,1.48-3.29,1.89c-1.16,0.41-2.47,0.61-3.69,0.51c-1.23-0.09-2.47-1.05-3.64-0.94c-5.04,0.48-7.5-2.45-9.35-6.47
		c-0.68-1.47-1.36-2.72-0.04-4.29c0.4-0.47,0.05-1.84-0.31-2.62c-0.9-1.94-0.58-3.7,0.45-5.41c1.15-1.9,2.26-3.84,3.54-5.65
		c1.88-2.66,2.59-5.74,3.52-8.76c0.23-0.75,1.71-1.6,2.29-1.33c0.55,0.25,1.3,0.48,1.53,0.94
		C1616.05,1345.13,1619.58,1350.89,1618.92,1357.83z" />
    <path class="st0" d="M882.64,1141.07c-0.52-0.46-1.14-0.78-1.42-1.28c-0.79-1.44-1.22-3-0.03-4.45c1-1.22,2.18-2.31,3.15-3.55
		c1.53-1.96,1.92-3.92,1-4.22c-1.51-0.5-2.19,0.81-3.23,1.36c-1.51,0.82-3.34,0.47-4.28-0.75c-0.39-0.5-0.8-1.01-1.07-1.58
		c-0.41-0.85-1.03-1.78-1-2.65c0.13-3.45-1.47-6-3.6-8.49c-0.99-1.16-1.47-2.79-2.09-4.25c-0.48-1.14-0.06-2.69,0.86-3.46
		c1.2-1.01,2.4-2.03,3.66-2.96c0.79-0.58,1.71-0.8,2.59-0.09c1.24,1,2.52,1.96,3.68,3.04c1.49,1.38,3.24,1.87,5.19,2.12
		c0.81,0.11,2.06,0.73,2.22,1.35c0.71,2.71,2.59,4.33,4.73,5.77c0.67,0.45,0.85,0.86,0.58,1.6c-0.44,1.19-0.82,2.41-1.15,3.63
		c-0.27,1,0.14,1.79,0.91,2.47c0.47,0.41,1.1,0.89,1.2,1.42c0.47,2.51-1.75,4.76-0.81,7.36c-0.87,1.32-1.61,2.76-2.65,3.93
		c-1.27,1.43-2.83,2.55-4.85,2.85C885.02,1140.42,883.81,1140.79,882.64,1141.07z" />
    <path class="st0" d="M1274.99,999.92c-1.45-0.64-2.84-1.48-4.35-1.86c-2.25-0.56-4.03-1.76-5.75-3.22
		c-4.37-3.71-8.57-7.62-12.05-12.16c-1.85-2.42-3.98-4.01-6.84-4.81c-1.53-0.43-3.06-0.9-4.48-1.58c-0.82-0.39-1.65-1.12-2.08-1.9
		c-1.44-2.61-1.5-5.47-0.93-8.33c0.11-0.57,0.52-1.2,0.98-1.57c0.73-0.6,1.63-0.97,2.43-1.5c1.05-0.69,1.99-1.89,3.1-2.06
		c1.6-0.24,2.91,1.01,4.1,2.08c0.71,0.64,1.42,1.28,2.02,2.02c0.8,0.99,1.72,1.97,2.17,3.12c1.9,4.79,6.4,7.91,8.09,12.83
		c0.51,1.49,1.19,2.97,2.07,4.27c2.04,2.99,4.62,5.3,8.53,5.28c2.53-0.01,4.24,1.03,5.14,3.41
		C1277.56,996.34,1279.75,999.37,1274.99,999.92z" />
    <path class="st0" d="M886.52,1275.22c0.02,1.44-1.99,4.53-3.08,4.74c-1.56,0.3-3.15,0.46-4.73,0.67c-0.99,0.13-1.87,0.48-1.92,1.61
		c-0.09,2.13-1.01,3.67-3.02,4.43c-2.98,1.12-5.95,2.25-8.98,3.22c-1.76,0.56-4.06-0.91-4.45-2.71c-0.27-1.24-0.42-2.52-0.53-3.78
		c-0.11-1.34-0.73-2.39-1.54-3.41c-0.7-0.88-0.46-1.94,0.3-2.52c3.37-2.6,5.31-6.32,7.79-9.62c0.64-0.85,2.53-1.38,3.51-0.97
		c5,2.08,10,4.14,14.94,6.34C885.58,1273.56,886.03,1274.62,886.52,1275.22z" />
    <path class="st0" d="M1315.36,1452.97c-0.25-0.03-2-0.1-3.26-0.91c-3.46-2.24-6.75-4.73-10.01-7.25c-1.44-1.11-0.65-4.21,1.07-4.74
		c0.3-0.09,0.6-0.34,0.89-0.32c6.12,0.45,10.23-2.59,13.27-7.55c0.32-0.53,0.78-0.98,1.21-1.43c0.65-0.68,2.79-0.73,3.59-0.15
		c2.67,1.91,3.71,5.04,5.61,7.53c0.27,0.35,0.07,1.24-0.17,1.76c-1.08,2.33-0.65,4.27,1.19,6.03c0.42,0.4,0.7,1.1,0.75,1.68
		c0.05,0.55-0.19,1.21-0.51,1.68c-0.35,0.49-0.95,1.14-1.43,1.14C1323.7,1450.41,1320.66,1453.67,1315.36,1452.97z" />
    <path class="st0" d="M537.57,1579.75c0.06,0.31,0.16,0.63,0.16,0.94c0.05,5.68,0.03,11.37,0.15,17.05c0.08,3.75-1.22,6.8-4,9.29
		c-0.94,0.84-1.8,1.78-2.71,2.66c-0.56,0.54-2.2,0.31-2.52-0.35c-0.41-0.85-0.78-1.71-1.19-2.56c-5.42-11.3-5.42-11.3-4.57-23.76
		c0.09-1.37,0.31-1.82,2.65-1.63c1.96,0.16,2.79-1.21,4.11-1.9C532.36,1577.67,535,1577.75,537.57,1579.75z" />
    <path class="st0" d="M1336.54,1057.39c-7.48-9.16-13.59-19.28-20.27-29c-0.72-1.04-1.3-2.2-1.79-3.37
		c-0.22-0.52-0.29-1.36-0.01-1.79c1.17-1.85,1.91-4.04,4.02-5.39c3.06,2.19,6.27,4.26,9.22,6.68c1.68,1.38,3.49,2.83,4.16,5.12
		c2.13,7.33,5.3,14.35,6.69,21.92C1338.88,1053.85,1341.37,1056.88,1336.54,1057.39z" />
    <path class="st0" d="M1460.15,1448.15c-0.27,1.5-0.64,3.68-1.07,5.85c-0.21,1.02-2.11,2.11-3.03,1.76
		c-4.43-1.7-8.96-3.23-12.78-6.18c-4.14-3.19-4.1-9.05-0.01-12.31c2.97-2.37,7.81-2.25,10.49,0.31c1.6,1.53,3.19,3.07,4.7,4.69
		C1459.8,1443.7,1460.43,1445.46,1460.15,1448.15z" />
    <path class="st0" d="M1577.66,1693.52c-0.34,4.42-0.54,6.94-0.72,9.47c-0.25,3.48-0.16,6.92,0.93,10.31
		c0.52,1.61-0.44,3.05-1.27,4.42c-0.5,0.81-1.88,0.96-2.35,0.23c-0.51-0.79-1.18-1.53-1.47-2.39c-1.21-3.59-2.57-7.17-3.36-10.86
		c-0.59-2.76-2.03-4.48-4.3-5.87c-1.23-0.76-1.79-2.36-1.28-3.24c1.92-3.31,2.98-6.9,3.71-10.62c0.16-0.8,1.73-1.24,2.37-0.7
		c1.68,1.43,3.36,2.85,4.99,4.34C1576.91,1690.43,1577.67,1692.77,1577.66,1693.52z" />
    <path class="st0" d="M1069.3,191.95c0.2,0.69,0.74,2.19,1.04,3.74c0.09,0.48-0.34,1.24-0.76,1.59c-0.98,0.8-2.09,1.44-3.16,2.13
		c-1.18,0.76-2.4,0.68-3.61,0.07c-2.83-1.44-5.66-2.88-8.47-4.35c-0.55-0.29-1.18-0.63-1.51-1.12c-1.39-2.12-2.73-4.27-3.96-6.49
		c-0.22-0.4-0.01-1.33,0.34-1.71c0.84-0.94,1.78-1.84,2.85-2.48c0.75-0.46,1.91-0.88,2.63-0.62c3.69,1.33,7.44,1.56,11.29,1.19
		c1.01-0.1,2.36,1.28,2.55,2.49C1068.79,187.94,1068.97,189.52,1069.3,191.95z" />
    <path class="st0" d="M1118.89,827.64c0.6,0.18,1.32,0.2,1.77,0.56c1.48,1.18,2.86,2.49,4.27,3.75
		c5.66,5.07,11.17,10.31,17.56,14.51c1.29,0.85,2.45,2.03,3.35,3.29c0.94,1.32,1.15,2.97,0.11,4.42c-0.31,0.43-1.05,0.83-1.54,0.77
		c-0.9-0.11-1.89-0.38-2.62-0.9c-6.65-4.83-13.24-9.74-19.86-14.62c-2.64-1.94-4.33-4.57-5.56-7.55c-0.59-1.43,0.16-3.3,1.64-3.95
		C1118.29,827.8,1118.59,827.73,1118.89,827.64z" />
    <path class="st0" d="M1500.01,1324.8c0.65,0,1.35-0.19,1.86,0.04c1.15,0.53,2.15,1.43,3.32,1.88c4.93,1.9,7.85,5.2,8.19,10.67
		c0.16,2.68,2.08,4.61,3.6,6.65c1.59,2.14,2.23,4.58,2.28,7.19c0.01,0.59-0.35,1.2-0.55,1.79c-0.23,0.7-2,1.29-2.44,0.8
		c-0.84-0.93-1.72-1.84-2.44-2.85c-1.84-2.61-3.63-5.26-5.39-7.92c-1.07-1.62-2.23-3.13-3.98-4.06c-4.5-2.39-5.58-6.71-6.27-11.21
		c-0.09-0.57,0.14-1.29,0.46-1.8C1498.95,1325.47,1499.56,1325.16,1500.01,1324.8z" />
    <path class="st0" d="M918.95,894.78c-0.27,0.11-0.53,0.28-0.8,0.32c-0.29,0.04-0.7,0.07-0.89-0.09c-2.69-2.19-5.19-4.68-8.08-6.56
		c-2.63-1.71-5.68-2.76-8.54-4.13c-3.64-1.75-4.62-4.96-4.71-8.65c-0.03-1.4,1.95-2.93,3.38-2.6c0.92,0.21,1.9,0.35,2.71,0.77
		c2.81,1.45,5.68,2.69,8.63,3.85c4.67,1.84,7.86,5.34,7.23,10.98c-0.19,1.72,0.89,2.95,1.42,4.4
		C919.48,893.53,919.09,894.2,918.95,894.78z" />
    <path class="st0" d="M1219.63,1663.12c-0.03-2.09,0.31-3.9,1.34-5.6c1.09-1.79,2.97-2.43,4.77-1.39c0.82,0.47,1.52,1.17,2.37,1.55
		c0.84,0.38,1.69,0.49,2.53-0.39c3.04-3.21,4.02-3.32,6.91-0.9c0.71,0.6,1.28,1.38,1.87,2.11c0.19,0.24,0.44,0.64,0.36,0.85
		c-0.65,1.75-0.88,3.72-2.33,5.08c-0.45,0.42-1.07,0.67-1.63,0.97c-3.59,1.98-7.18,3.97-10.77,5.94c-0.84,0.46-2.76-0.26-3.34-1.18
		C1220.34,1667.96,1219.51,1665.6,1219.63,1663.12z" />
    <path class="st0" d="M1123.38,238.13c-0.12,0.3-0.18,0.64-0.37,0.88c-1.99,2.47-3.76,5.19-6.08,7.31
		c-2.82,2.58-5.16,5.57-7.86,8.23c-0.33,0.32-1.28,0.3-1.78,0.08c-0.48-0.22-0.99-0.87-1.06-1.39c-0.13-0.91-0.21-2.02,0.19-2.78
		c1.3-2.49,3.34-4.74,1.88-7.87c0.88-3.05,1.54-6.21,3.76-8.65c0.6-0.66,1.5-1.21,2.36-1.46c4.54-1.31,7.09,0.09,8.83,4.71
		C1123.36,237.47,1123.34,237.81,1123.38,238.13z" />
    <path class="st0" d="M871.79,1164.75c0.11,3.97-1.39,7.38-3.74,10.49c-0.75,0.99-2.53,0.89-3.17-0.28
		c-0.3-0.54-0.37-1.21-0.63-1.79c-0.4-0.88-0.97-1.78-2.08-1.2c-2.28,1.2-4.19,0.8-6.03-0.88c-1.18-1.08-2.42-2.09-2.77-3.71
		c-0.11-0.51,0.24-1.16,0.5-1.68c0.07-0.14,0.65-0.18,0.9-0.06c0.84,0.4,1.62,0.91,2.44,1.36c1.72,0.95,1.8,0.99,2.83-0.84
		c0.64-1.13,1.06-2.28,2.72-1.5c1.22,0.57,2.59,0.69,3.44-0.41c0.48-0.62,0.3-1.75,0.44-2.64c0.16-0.99,0.66-1.73,1.73-1.83
		c1.07-0.1,1.75,0.59,2.28,1.39c0.34,0.52,0.62,1.11,0.8,1.7C871.65,1163.46,871.68,1164.11,871.79,1164.75z" />
    <path class="st0" d="M1338.01,1205.49c-4.14,0.37-5.85-0.82-6.83-3.07c-0.76-1.74-1.49-3.5-2.03-5.31
		c-1.01-3.43,0.22-6.18,2.84-8.43c0.85-0.73,2.84-0.46,3.36,0.47c0.15,0.27,0.45,0.47,0.55,0.75c1.62,4.45,3.14,8.94,5.52,13.07
		c0.37,0.64-0.48,2.04-1.27,2.18C1338.91,1205.37,1337.67,1205.55,1338.01,1205.49z" />
    <path class="st0" d="M1602.9,1385.13c-0.07,0.53-0.09,0.85-0.16,1.15c-0.78,3.02-1.55,6.04-2.36,9.05
		c-0.07,0.27-0.37,0.56-0.63,0.67c-0.54,0.22-1.24,0.6-1.69,0.43c-2.87-1.12-4.19-3.63-5.19-6.3c-0.19-0.5,0.07-1.29,0.37-1.81
		c1.8-3.19,2.4-6.41,0.89-9.92c-0.33-0.77,0.02-1.85,0.12-2.78c0.1-0.98,1.88-2.66,2.59-2.34c0.83,0.37,1.71,0.93,2.2,1.66
		c0.5,0.73,0.72,1.76,0.78,2.67c0.12,1.97,0.76,3.64,2.02,5.17C1602.38,1383.47,1602.6,1384.45,1602.9,1385.13z" />
    <path class="st0" d="M1352.08,1572.17c-4.37,2.58-8.37,5.64-12.31,8.84c-3.68,2.99-7.73,5.51-11.65,8.19
		c-0.77,0.53-1.9,0.86-2.5,0.06c-0.49-0.65-0.59-1.77-0.52-2.66c0.24-2.74,1.46-4.83,4.33-5.58c1.23-0.32,2.45-0.67,3.69-0.93
		c1.93-0.41,3.48-1.46,4.92-2.74c3.07-2.73,5.9-5.77,9.63-7.68C1348.56,1571.54,1350.31,1571.87,1352.08,1572.17z" />
    <path class="st0" d="M1465.3,1154.3c-0.23,0.58-0.32,1.29-0.72,1.7c-0.36,0.37-1.33,0.75-1.57,0.55c-2.63-2.09-6.04-3.55-6.17-7.73
		c-0.05-1.68-1.37-2.84-2.68-3.73c-2.29-1.56-3.12-3.95-3.7-6.4c-0.16-0.65,0.58-1.68,1.17-2.29c0.3-0.31,1.23-0.29,1.75-0.08
		c3.38,1.35,5.84,3.85,7.43,7c1.14,2.26,2.16,4.5,3.9,6.42C1465.81,1150.96,1465.53,1152.72,1465.3,1154.3z" />
    <path class="st0" d="M1044.19,252.92c0.38,0.05-0.93,0.05-2.12-0.33c-0.84-0.27-1.54-1.02-2.25-1.63
		c-0.54-0.46-0.41-2.32,0.13-2.56c5.31-2.34,10.78-3.93,16.67-3.26c1.63,0.19,3.01,1.13,3.9,2.53c0.25,0.39,0.16,1.33-0.15,1.68
		c-0.62,0.69-1.42,1.34-2.28,1.65C1054.14,252.43,1050.05,253.06,1044.19,252.92z" />
    <path class="st0" d="M564.65,1547.42c1.64-0.27,3.07,0.13,4.32,1.25c1.28,1.14,1.59,2.62,1.59,4.22c0,0.63-0.05,1.25-0.07,1.88
		c-0.02,0.63-0.09,1.26-0.03,1.88c0.51,5.53-1.18,5.78-5.6,6.62c-1.64,0.31-3.25-0.65-3.6-2.1c-0.82-3.39-0.99-6.8-0.48-10.25
		C561.55,1549.18,562.94,1548.13,564.65,1547.42z" />
    <path class="st0" d="M958.43,1785.12c0.32,3.41-0.78,6.34-1.92,9.29c-1.41,3.67-4.78,5.62-10.11,2.9
		c-0.72-0.37-1.06-1.95-0.43-2.41c2.92-2.12,4.45-5.19,5.99-8.3c0.69-1.39,1.55-2.7,2.38-4.02c0.42-0.67,1.96-0.97,2.55-0.47
		c0.45,0.39,0.93,0.81,1.18,1.33C958.33,1784,958.34,1784.67,958.43,1785.12z" />
    <path class="st0" d="M1410.98,1265.78c-0.55,0.73-1.12,2-2.09,2.65c-2.08,1.4-4.35,2.53-6.59,3.69c-1.23,0.64-2.92,0.3-3.45-0.55
		c-0.53-0.86-0.5-1.69-0.11-2.63c1.19-2.92,2.19-5.91,3.46-8.79c1.08-2.45,4.79-2.88,6.3-0.71c1.06,1.52,1.73,3.32,2.53,5.01
		C1411.15,1264.7,1411.01,1265.07,1410.98,1265.78z" />
    <path class="st0" d="M1353.7,942.33c5.26,1.23,10.56,2.13,15.98,2.17c0.89,0.01,1.84,0.4,2.65,0.84c0.49,0.27,1.15,1.05,1.06,1.43
		c-0.7,2.78-4.69,5.56-7.23,5.02c-2.78-0.59-5.55-1.3-8.28-2.1c-2.9-0.85-4.05-2.8-4.11-6.55c0-0.32,0.04-0.63,0.06-0.95
		L1353.7,942.33z" />
    <path class="st0" d="M1111.18,4.83c-0.53,4.6-1.76,8.76-5.43,11.75c-0.71,0.58-1.6,0.96-2.44,1.37c-1.34,0.65-3.33-0.75-3.23-2.32
		c0.08-1.23,0.1-2.57,0.6-3.66c1.57-3.42,3.35-6.75,5.07-10.11c0.6-1.17,2.36-2.23,2.96-1.73c0.7,0.57,1.35,1.3,1.76,2.09
		C1110.88,3.03,1110.97,4.01,1111.18,4.83z" />
    <path class="st0" d="M820.98,1959.16c0.32,0.03,0.74-0.06,0.93,0.11c0.7,0.61,1.57,1.2,1.92,2c0.75,1.75,1.01,3.71,1.82,5.42
		c2.88,6.06,2.05,11.75-1.42,17.23c-0.3,0.47-0.83,0.82-1.32,1.13c-0.21,0.13-0.68,0.1-0.87-0.05c-0.21-0.17-0.32-0.56-0.35-0.86
		c-0.12-1.26-0.35-2.54-0.25-3.79c0.48-6.39,0.29-12.71-1.51-18.92C819.66,1960.49,820.31,1959.27,820.98,1959.16z" />
    <path class="st0" d="M1347.68,1391.33c-0.38,0.68-0.65,1.72-1.32,2.25c-2.21,1.75-4.46,3.51-6.89,4.91
		c-2.67,1.54-5.63,0.47-7.01-2.62c-0.22-0.5-0.34-1.4-0.06-1.7c3.07-3.32,6.81-5.54,11.33-6.24c1.34-0.21,2.46,0.53,3.3,1.5
		C1347.38,1389.86,1347.42,1390.56,1347.68,1391.33z" />
    <path class="st0" d="M875.74,571.86c-0.01-3.27-0.02-6.55-0.03-9.82c0.47,0.4,1.05,0.72,1.38,1.21c0.86,1.26,0.16,3.54,1.97,4
		c1.01,0.26,2.32-0.72,3.51-1.07c1.4-0.42,2.7,0.05,3.23,1.25c0.51,1.15,1.13,2.34,1.23,3.55c0.22,2.85,0.16,5.72,0.22,8.58
		C880.51,581.33,878.67,575.77,875.74,571.86z" />
    <path class="st0" d="M1419.27,1268.11c-1.38-0.45-2.59-0.78-3.76-1.24c-2.32-0.9-3.18-2.99-2.27-5.41
		c1.31-3.49,4.41-4.77,7.59-3.11c1.7,0.88,3.22,1.94,4.28,3.62c0.8,1.26,0.62,3.67-0.61,4.21
		C1422.79,1266.95,1420.98,1267.49,1419.27,1268.11z" />
    <path class="st0" d="M1581.72,1256.92c-0.21,0.54-0.26,1.27-0.65,1.59c-1.6,1.29-3.7,1.52-5.34,0.72c-3.36-1.63-6.55-3.64-9.68-5.7
		c-1.8-1.19-1.72-4.95-0.11-6.84c0.95-1.11,3.5-1.2,4.2-0.12c0.5,0.78,1.09,1.56,1.37,2.42c1.18,3.66,3.31,5.99,7.47,5.86
		c0.59-0.02,1.28,0.23,1.76,0.58C1581.19,1255.78,1581.4,1256.42,1581.72,1256.92z" />
    <path class="st0" d="M1036.74,226.61c-0.25,0.73-0.43,1.33-0.67,1.9c-0.3,0.72-1.83,1.17-2.45,0.76c-4.39-2.9-5.85-5.88-4.92-10.32
		c0.39-1.86,0.95-3.68,1.58-5.47c0.18-0.5,0.83-1.02,1.36-1.15c0.4-0.1,1.26,0.32,1.38,0.68c1.2,3.62,2.33,7.26,3.4,10.92
		C1036.67,224.82,1036.65,225.8,1036.74,226.61z" />
    <path class="st0" d="M705.88,1710.24c-0.82-0.18-2.36-0.47-3.88-0.87c-0.59-0.16-1.15-0.51-1.66-0.87
		c-1.09-0.79-1.54-1.94-1.49-3.25c0.04-1.19,1.09-2.51,2.19-2.77c2.14-0.51,4.28-0.98,6.43-1.44c0.29-0.06,0.63,0.03,0.94,0.06
		c2.41,0.21,4.09,2.77,3.19,4.7c-0.26,0.57-0.63,1.11-0.79,1.71C710.13,1710.12,708.46,1710.38,705.88,1710.24z" />
    <path class="st0" d="M1128.6,46.52c-0.1,0.61,0.02,1.42-0.33,1.78c-1.09,1.13-2.31,2.16-3.57,3.11c-2.19,1.65-3.47,3.66-3.01,6.52
		c0.05,0.3-0.07,0.63-0.15,0.94c-0.34,1.3-1.09,2.39-2.42,2.63c-0.81,0.15-1.91-0.06-2.57-0.53c-1.19-0.86-1.61-3.07-0.84-4.31
		c2.16-3.49,4.34-6.99,6.76-10.31c0.66-0.9,2.14-1.26,3.3-1.73C1126.75,44.21,1128.43,45.42,1128.6,46.52z" />
    <path class="st0" d="M1141.41,752.14c1.7,0.12,3.31,0.07,4.83,0.41c0.79,0.18,1.77,0.92,2.05,1.64c0.91,2.39,2.89,3.1,4.97,3.97
		c1.89,0.8,2.77,2.47,2.55,4.61c-0.1,1.02-1.09,1.91-1.85,1.61c-0.58-0.23-1.23-0.32-1.76-0.63c-0.82-0.47-1.65-0.98-2.34-1.61
		c-2.62-2.38-5.63-4.04-8.98-5.15c-0.56-0.19-1.24-0.66-1.44-1.16c-0.32-0.82-0.45-1.8-0.32-2.67c0.06-0.39,0.93-0.7,1.47-0.96
		C1140.86,752.09,1141.22,752.16,1141.41,752.14z" />
    <path class="st0" d="M1162.2,1037.61c-0.11,0.62-0.18,1.25-0.34,1.85c-0.34,1.33-1.27,2.16-2.51,2.59
		c-0.92,0.32-2.6-0.67-3.14-1.62c-1.27-2.27-1.79-4.71-1.81-7.28c-0.02-2.02,1.08-3.56,2.17-5.12c0.45-0.64,2-0.7,2.46-0.12
		C1161.27,1030.76,1162.33,1033.99,1162.2,1037.61z" />
    <path class="st0" d="M1484.51,1191.65c-0.87-0.32-1.91-0.44-2.59-1c-2.07-1.68-3.46-3.78-2.34-6.59c1.21-3.05,1.34-6.14,0.72-9.32
		c1.62-0.86,1.93-2.41,2.12-4.02c0.55-0.01,1.1,0.03,1.64,0.11c4.61,5.13-4.14,10.41,0.17,15.54c0.82,1.03,1.91,1.56,3.2,1.59
		c2.79,0.07,5.58,0.06,8.37,0.13c1.57,0.04,3.07,0.38,4.23,1.57c-4.08,0.13-8.16,0.26-12.24,0.38
		C1486.45,1190.09,1485.39,1190.68,1484.51,1191.65z" />
    <path class="st0" d="M1017.68,1067.07c1.49,0.65,2.03,1.88,2.08,3.4c-1.21,0.73-1.87,1.93-1.45,3.18c0.69,2.07,1.81,4,2.59,6.05
		c0.15,0.4-0.29,1.27-0.7,1.56c-0.42,0.29-1.19,0.27-1.73,0.12c-0.58-0.17-1.2-0.55-1.58-1.02c-1.96-2.47-3.89-4.97-5.75-7.52
		c-0.8-1.11-0.73-2.4-0.04-3.57c0.68-1.15,1.67-1.88,3.1-1.75C1015.4,1067.64,1016.67,1068.39,1017.68,1067.07z" />
    <path class="st0" d="M1430.93,1339.51c-2.61-0.73-5.11-1.8-7.89-1.95c-0.72-0.04-1.4-1.02-2.09-1.58c0.63-0.02,1.25-0.06,1.88-0.06
		c1.71,0,3.13-0.48,3.36-2.44c0.24-2.06,1.26-3.52,3.12-4.35c1.7-0.76,3.43-1.46,5.2-2.03c0.32-0.1,1.23,0.53,1.31,0.93
		c0.5,2.62,0.34,5.1-2.66,6.18C1430.41,1335.21,1430.3,1337.17,1430.93,1339.51z" />
    <path class="st0" d="M1121.08,170.46c-0.55-0.02-0.88,0.03-1.16-0.06c-3.1-1.03-5.37-3.03-6.81-5.96
		c-0.33-0.67-0.13-1.35,0.59-1.57c2.37-0.76,4.74-1.53,7.16-2.09c2.79-0.65,4.23,0.85,3.56,3.62c-0.37,1.52-0.71,3.08-1.38,4.48
		C1122.69,169.61,1121.66,170.02,1121.08,170.46z" />
    <path class="st0" d="M1388.67,955.42c-0.67,0.7-1.25,1.36-1.9,1.93c-0.19,0.17-0.63,0.16-0.92,0.1c-3.68-0.76-7.37-1.5-11.03-2.36
		c-0.45-0.11-0.95-0.85-1.03-1.37c-0.08-0.48,0.2-1.27,0.59-1.54c3.72-2.55,7.58-2.83,11.53-0.5c0.53,0.32,1.15,0.64,1.48,1.12
		C1387.9,953.54,1388.19,954.42,1388.67,955.42z" />
    <path class="st0" d="M1099.84,1818.54c0.35-0.12,0.7-0.38,0.91-0.3c2.64,1.1,5.16,2.58,7.9,3.29c2.41,0.62,5.03,0.39,7.56,0.57
		c0.22,0.02,0.61,0.36,0.59,0.52c-0.05,0.59-0.19,1.19-0.41,1.74c-0.1,0.26-0.4,0.52-0.68,0.63c-1.77,0.73-3.52,1.54-5.36,2.06
		c-0.85,0.24-1.99,0.16-2.77-0.24c-3.16-1.6-6.03-3.64-7.93-6.73C1099.45,1819.74,1099.78,1819.04,1099.84,1818.54z" />
    <path class="st0" d="M827.76,1222.96c0.54-1.23,1.43-2.06,2.75-2.35c2.29-0.5,2.81-2.28,3.09-4.24c1.77-0.67,1.6-2.58,2.47-3.81
		c0.29-0.41,1.14-0.73,1.63-0.63c3.42,0.73,5.44,2.97,6.41,6.26c0.32,1.1-0.88,2.45-2.12,2.6c-1.38,0.17-2.41-0.46-3.51-1.08
		c-0.8-0.46-1.97-0.97-2.48-0.02c-1.25,2.32-3.07,2.85-5.5,2.6C829.63,1222.21,828.68,1222.73,827.76,1222.96z" />
    <path class="st0" d="M1017.53,1237.11c-0.62,2.04-1.23,4.48-2.12,6.8c-0.49,1.29-1.87,1.56-3.17,1.56c-1.9,0-3.32-0.85-3.83-2.2
		c-0.61-1.65-0.28-3.27,0.91-4.35c1.62-1.47,3.52-2.64,5.38-3.82c0.44-0.28,1.33-0.31,1.76-0.04
		C1016.91,1235.33,1017.04,1236.1,1017.53,1237.11z" />
    <path class="st0" d="M1207.83,1253.35c-1.94-0.1-3.84,0.09-5.05,1.82c-1.07,1.52-1.95,3.17-2.89,4.78c-1.23,2.09-3.1,3-5.47,2.97
		c-0.04-0.79-0.08-1.58-0.12-2.37c-0.56-0.32-0.76-0.66-0.59-1.02c0.17-0.37,0.34-0.55,0.52-0.55l0.11-0.07
		c1.33-0.86,0.89-2.76,2.16-3.65c0.69-0.99,1.3-2.05,2.08-2.97c1.4-1.65,3-3.04,5.39-2.89
		C1205.25,1250.72,1206.54,1252.03,1207.83,1253.35z" />
    <path class="st0" d="M564.65,1547.42c-0.9,1.61-2.16,2.81-3.89,3.5c-2.2,1.16-4.39,2.34-6.62,3.45c-0.54,0.27-1.28,0.51-1.79,0.35
		c-0.83-0.27-1.66-0.77-2.25-1.4c-0.32-0.35-0.33-1.23-0.14-1.75c0.92-2.46,2.89-3.99,5.08-5.22c0.45-0.25,1.41-0.3,1.72,0
		c2.12,2.03,4.5,1.66,6.96,1C564.01,1547.26,564.34,1547.39,564.65,1547.42z" />
    <path class="st0" d="M1024.97,1852.68c-1.08-0.63-1.96-1-2.64-1.58c-0.69-0.59-0.65-2.61-0.12-3.54c0.83-1.45,2.07-2.48,3.71-2.47
		c3.08,0.01,5.26,1.68,6.58,4.39c0.19,0.39-0.16,1.52-0.46,1.6C1029.67,1851.72,1027.24,1852.19,1024.97,1852.68z" />
    <path class="st0" d="M991.72,1887.18c-2.34,0.11-4.3-0.63-5.84-2.47c-1.29-1.55-1.32-3.78,0.04-5.13c1.46-1.45,3.67-1.8,5.15-0.84
		c1.64,1.06,2.82,2.46,3.43,4.34C995.08,1884.88,993.59,1887.09,991.72,1887.18z" />
    <path class="st0" d="M1528.47,1902.78c-0.75-0.17-1.96-0.4-3.13-0.73c-0.95-0.27-1.65-2.25-1.23-3.16
		c0.98-2.14,2.64-3.38,4.92-3.82c3.06-0.59,5.43,1.71,5.07,4.92c-0.14,1.27-1.13,2.31-2.36,2.45
		C1530.81,1902.56,1529.88,1902.64,1528.47,1902.78z" />
    <path class="st0" d="M1175.11,768.47c-0.23,0.57-0.32,1.29-0.72,1.7c-2.4,2.43-5.34,3.18-8.64,2.46c-0.57-0.12-1.08-0.59-1.57-0.96
		c-0.55-0.42-0.53-2.19,0-2.59c2.55-1.93,5.3-3.31,8.61-3.22C1173.74,765.89,1175.19,767.5,1175.11,768.47z" />
    <path class="st0" d="M916.08,1118.88c-0.26-0.43-0.38-0.9-0.34-1.4c-0.14-1-0.89-1.65-1.41-2.43c-0.95-1.99-1.96-3.96-2.79-5.99
		c-0.16-0.4,0.21-1.35,0.6-1.58c1.35-0.78,2.77-1.52,4.25-1.97c2-0.62,4.2,1.32,3.49,3.14c-1.3,3.32-1.55,6.78-1.87,10.25
		C917.37,1119.55,916.72,1119.55,916.08,1118.88z" />
    <path class="st0" d="M1048.56,1094.65c0-1.96-0.08-2.93,0.03-3.88c0.07-0.55,0.34-1.33,0.74-1.5c0.46-0.2,1.29-0.01,1.73,0.32
		c2.38,1.81,3.81,4.29,4.47,7.17c0.3,1.31-0.19,2.48-1.2,3.43c-1.22,1.16-3.26,1.1-4.18-0.23c-0.54-0.78-1.12-1.61-1.32-2.5
		C1048.53,1096.23,1048.58,1094.92,1048.56,1094.65z" />
    <path class="st0" d="M1345.71,942.11c0.09-0.54,0.11-1.08,0.07-1.63c-1.17-4.02,0.27-5.44,4.28-4.27c1.97,0.71,4.14,1.26,3.62,4.14
		c-0.55,0.74-1.24,1.37-1.65,2.22l0.11-0.17c-2.01,1.76-1.59,3.66-0.35,5.62c0.26,0.6,0.24,1.2-0.05,1.78
		c-1.18,1.3-2.47,1.15-3.8,0.25l-0.03-0.01c-0.64-0.68-1.33-1.3-1.88-2.06l0.07,0.1C1344.58,946.18,1345.06,944.15,1345.71,942.11z" />
    <path class="st0" d="M537.57,1579.75c-2.64-0.09-5.28-0.18-7.91-0.26c-0.15-1.21-0.18-2.44-0.47-3.62
		c-0.76-3.13-0.01-4.61,2.94-5.5c0.98-0.3,2.9,0.52,3.17,1.43C536.08,1574.44,536.81,1577.1,537.57,1579.75z" />
    <path class="st0" d="M1415.59,1160.57c0.98,0.8,2.11,1.48,2.91,2.43c1.16,1.38,2.65,1.63,4.25,1.75c0.67,1.41,1.34,2.82,2.01,4.22
		c-1.61,0.37-2.6,1.55-3.68,2.67c-0.72,0.75-1.71,0.57-2.34-0.2c-2.34-2.85-4.69-5.68-7.03-8.52
		C1413.48,1162.91,1414.86,1162.28,1415.59,1160.57z" />
    <path class="st0" d="M1134.82,590.85c-0.3,1.84-0.43,3.73-0.93,5.52c-0.61,2.21-2.03,3.86-4.32,4.58c-1.14,0.54-2.17,0.38-3.1-0.48
		c0.34-1.18,0.57-2.4,1.03-3.54c1.34-3.38,3.69-5.57,7.45-5.96L1134.82,590.85z" />
    <path class="st0" d="M875.74,571.86c3.02,0.75,4.47,3.43,6.51,5.37c1.44,1.37,2.94,2.41,5.01,2.32c-0.61,1.16-1.22,2.32-1.84,3.48
		c-2.53-1.23-4.51-3.36-7.49-3.94c-2.18-0.43-2.64-1.73-2.53-4.4C875.44,573.74,875.62,572.8,875.74,571.86z" />
    <path class="st0" d="M1059.54,1117.17c2.69-0.16,4.82,0.2,6.63,1.59c0.39,0.3,0.74,1.03,0.66,1.48c-0.09,0.52-0.58,1.22-1.06,1.39
		c-1.13,0.41-2.43,0.94-3.51,0.71c-1.76-0.37-3.41-1.3-5.08-2.04c-0.25-0.11-0.58-0.54-0.53-0.71c0.16-0.56,0.36-1.29,0.79-1.57
		C1058.17,1117.54,1059.1,1117.34,1059.54,1117.17z" />
    <path class="st0" d="M1105.88,65.45c0.06-0.31,0.14-0.61,0.18-0.92c0.15-1.1,1.74-2.03,2.86-1.68c2.93,0.92,4.05,4.09,2.42,6.84
		c-0.64,1.07-2.35,1.95-3.09,1.42c-0.72-0.5-1.34-1.22-1.81-1.96c-0.32-0.49-0.35-1.19-0.44-1.81
		C1105.91,66.71,1105.91,66.08,1105.88,65.45z" />
    <path class="st0" d="M1274.99,999.92c1.34-0.77,1.92-1.95,1.96-3.46c0.02-0.84,0.11-1.68,0.17-2.52c1.02,0.83,1.75,1.88,1.75,3.18
		c0,3.08,1.54,5.31,3.59,7.39c0.86,0.87,1.45,2.02,2.16,3.04c-0.22,1.23-0.9,2.01-2.14,2.26
		C1279.25,1007.08,1277.36,1003.31,1274.99,999.92z" />
    <path class="st0" d="M1081.32,1752.73c-0.18,1.38-0.35,2.6-0.5,3.83c-0.09,0.8-0.51,1.15-1.3,1.02c-0.28-0.05-0.63-0.05-0.81-0.23
		c-1.65-1.56-2.21-3.69-2.79-5.75c-0.34-1.2,1.81-3.94,3.18-4.11c0.46-0.06,1.34,0.43,1.44,0.82
		C1080.94,1749.78,1081.1,1751.32,1081.32,1752.73z" />
    <path class="st0" d="M1138.27,582.91c-0.51,0.72-1.03,1.44-1.54,2.16c-0.51,0.8-0.71,1.92-1.96,2.01c-1.3-0.03-2.61-0.07-3.91-0.1
		c0.78-1.16,1.56-2.32,2.34-3.47c1.39-1.74-0.09-4.38,1.86-5.94l-0.08,0.08c3.49-1.39,7.09-2.93,5.47,3.55l0.06-0.06
		C1139.99,582.01,1139.29,582.67,1138.27,582.91z" />
    <path class="st0" d="M984.43,1867.31c-0.96-0.47-1.57-0.65-2.03-1.03c-0.54-0.43-0.18-1.93,0.45-2.49c1.81-1.6,3.84-2.13,6.09-1.16
		c0.48,0.21,0.97,0.87,1.05,1.39c0.08,0.47-0.25,1.35-0.63,1.51C987.66,1866.26,985.88,1866.81,984.43,1867.31z" />
    <path class="st0" d="M864.03,1956.37c1.56,0.37,3.03,1.37,3.92,3.15c0.48,0.96,0.13,2.96-0.8,3.33c-0.78,0.3-1.86,0.43-2.59,0.13
		c-1.82-0.74-2.86-2.26-3.13-4.24C861.27,1957.66,862.73,1956.09,864.03,1956.37z" />
    <path class="st0" d="M1389.5,1092.67c0,0.28,0.13,0.71-0.02,0.87c-1.81,1.91-3.83,3.33-6.65,2.3c-0.72-0.26-0.98-0.85-0.74-1.54
		c0.92-2.68,4.61-3.95,6.94-2.38C1389.25,1092.07,1389.34,1092.41,1389.5,1092.67z" />
    <path class="st0" d="M1092.71,987.72c0.01,1.15-1.06,2.45-2.15,2.59c-1.97,0.25-3.53-1.27-3.32-3.27c0.11-1.09,1.38-2.2,2.53-2.2
		C1091.2,984.82,1092.71,986.3,1092.71,987.72z" />
    <path class="st0" d="M801.19,1237.78c-1.23-0.46-2.02-1.32-2.28-2.61c-0.45-2.22-2.08-2.82-4.03-2.98
		c-0.31-0.53-0.85-1.04-0.87-1.58c-0.02-0.46,0.56-1.36,0.86-1.35c3.55,0.08,5.93,2.28,6.25,5.66
		C801.2,1235.86,801.17,1236.83,801.19,1237.78z" />
    <path class="st0" d="M1194.21,1258.98c-0.03,0.53-0.01,1.05,0.08,1.57c-5.72,0.15-11.45,0.3-17.17,0.45
		c1.03-1.32,2.42-1.89,4.04-1.9C1185.51,1259.05,1189.86,1259.02,1194.21,1258.98z" />
    <path class="st0" d="M841.63,1201.4c-0.67,2.03-2.14,3.17-4.01,3.78c-0.48,0.16-1.29-0.08-1.67-0.44
		c-0.38-0.36-0.68-1.17-0.55-1.66c0.61-2.32,2.52-3.68,4.8-3.6C841.13,1199.51,841.98,1200.72,841.63,1201.4z" />
    <path class="st0" d="M1125.44,735.1c0.63-1.38,1.25-2.75,1.88-4.13c1.6,0.09,3.13,0.48,4.07,1.85c0.48,0.7,0.7,1.65,0.79,2.52
		c0.07,0.67-1.3,1.14-2.3,0.88C1128.39,735.85,1126.91,735.47,1125.44,735.1z" />
    <path class="st0" d="M1350.06,936.21c-2.78,0.06-4.07,1.62-4.28,4.27c-1.82-1.96-3.64-3.92-5.46-5.89c0.81-1.56,2.78-0.81,3.82-1.9
		C1346.2,933.71,1348.45,934.42,1350.06,936.21z" />
    <path class="st0" d="M781.34,1291.87c-1.27,0.54-2.54,1.08-3.81,1.62c-0.22-1.56-0.64-3.13-0.57-4.68
		c0.04-0.75,0.92-1.55,1.58-2.14c0.65-0.58,2.11-0.12,2.46,0.57C781.75,1288.75,781.65,1290.3,781.34,1291.87z" />
    <path class="st0" d="M1290.72,1038.5c-0.52,2.99-2.16,5.26-4.54,7.05c-0.72,0.54-1.42,1.17-2.46,0.76
		c-0.64-0.25-0.64-1.58,0.08-2.51c0.97-1.26,1.98-2.49,2.97-3.74C1288.09,1039.54,1289.4,1039.02,1290.72,1038.5z" />
    <path class="st0" d="M1036.28,1389.44c0.07,0.28,0.28,0.63,0.18,0.81c-0.7,1.32-1.39,2.65-2.22,3.89c-0.56,0.84-2.1,0.84-2.32,0.09
		c-0.16-0.53-0.35-1.23-0.13-1.66c0.82-1.67,2.01-3.05,3.86-3.66C1035.78,1388.87,1036.07,1389.26,1036.28,1389.44z" />
    <path class="st0" d="M1344.14,932.69c-0.7,1.78-2.25,1.86-3.82,1.9c-1.26-0.89-2.61-1.71-2.99-3.37c-0.06-0.25,0.05-0.61,0.21-0.82
		c0.16-0.22,0.5-0.45,0.74-0.44C1340.58,930.11,1342.25,931.64,1344.14,932.69z" />
    <path class="st0" d="M1381.79,1044.98c0.3,0.08,0.66,0.09,0.89,0.26c1.65,1.17,2.51,2.82,2.55,4.81c0.01,0.45-0.57,0.97-0.99,1.35
		c-0.17,0.16-0.66,0.12-0.92,0c-2.29-1.09-3.13-2.99-2.8-5.4C1380.59,1045.61,1381.35,1045.32,1381.79,1044.98z" />
    <path class="st0" d="M1282.49,1009.81c0.71-0.75,1.43-1.51,2.14-2.26c0.67,0.64,1.43,1.21,1.98,1.94c1.68,2.25,2.19,4.81,1.09,4.92
		c-1.77,0.17-2.73-1.11-3.69-2.28C1283.44,1011.41,1282.99,1010.59,1282.49,1009.81z" />
    <path class="st0" d="M820.04,1249.43c1.62,1.24,2.37,2.89,2.05,4.9c-0.08,0.5-0.6,1.19-1.03,1.29c-0.49,0.11-1.33-0.19-1.62-0.59
		c-0.71-0.99-1.19-2.15-1.76-3.24C818.47,1251.01,819.26,1250.22,820.04,1249.43z" />
    <path class="st0" d="M1278.58,863.87c1.29-0.92,2.59-1.83,3.87-2.76c0.69-0.5,1.32-0.49,1.52,0.24c0.15,0.53,0.1,1.29-0.19,1.73
		c-1.25,1.87-2.76,3.49-4.95,4.3C1278.75,866.2,1278.67,865.03,1278.58,863.87z" />
    <path class="st0" d="M552.01,1566.64c-0.27,0.49-0.42,1.24-0.82,1.41c-1.21,0.53-2.45,0.26-3.57-0.44
		c-0.68-0.42-1.25-1.92-0.8-2.39c0.39-0.41,1.04-0.81,1.58-0.81C550.61,1564.38,551.57,1565.04,552.01,1566.64z" />
    <path class="st0" d="M996.83,1859.4c-0.06,0.3-0.08,0.61-0.2,0.89c-0.35,0.84-0.79,1.56-1.89,1.55c-1.08-0.01-1.7-0.67-1.8-1.57
		c-0.1-0.83,0.08-1.77,0.41-2.55c0.16-0.4,0.92-0.75,1.41-0.75c0.5,0.01,1.11,0.38,1.44,0.79
		C996.55,1858.19,996.63,1858.84,996.83,1859.4z" />
    <path class="st0" d="M1336.54,1057.39c1.12-0.73,1.54-1.82,1.65-3.09c0.08-0.92,0.23-1.82,0.36-2.74c1.76,1.8,1.56,4,1.24,6.22
		c-0.14,0.96-1.14,1.51-2.17,0.99C1337.15,1058.54,1336.9,1057.87,1336.54,1057.39z" />
    <path class="st0" d="M866.56,1968.1c0.6,0.48,1.3,0.97,1.92,1.54c0.18,0.17,0.3,0.72,0.18,0.83c-0.4,0.38-0.88,0.81-1.39,0.92
		c-0.9,0.2-1.86,0.04-2.34-0.85c-0.23-0.42-0.24-1.19,0.01-1.57C865.21,1968.56,865.89,1968.43,866.56,1968.1z" />
    <path class="st0" d="M1021.38,1071.03c2.23-0.04,4.46-0.13,6.69-0.11c1.39,0.01,2.34,0.86,3.12,1.93c-2.14-0.19-4.3-0.29-6.42-0.62
		C1023.61,1072.03,1022.51,1071.44,1021.38,1071.03z" />
    <path class="st0" d="M1014.22,909.39c0.59,0.16,1.23,0.22,1.75,0.51c0.86,0.48,1.03,1.64,0.48,2.48c-0.53,0.8-1.69,0.95-2.49,0.31
		c-0.25-0.2-0.56-0.38-0.69-0.64c-0.27-0.54-0.73-1.25-0.56-1.67C1012.88,909.91,1013.69,909.7,1014.22,909.39z" />
    <path class="st0" d="M1336.68,952.04c0.62-1.16,1.53-1.95,2.89-1.99c2.17-0.07,4.34-0.07,6.51-0.1c-0.65,1.15-1.62,1.77-2.96,1.83
		C1340.98,951.86,1338.83,951.95,1336.68,952.04z" />
    <path class="st0" d="M916.08,1118.88c0.65,0,1.29,0.01,1.94,0.01c0.42,0.75,0.96,1.47,1.19,2.27c0.08,0.27-0.61,0.82-1.04,1.12
		c-0.16,0.11-0.62-0.05-0.85-0.22C916.24,1121.28,915.98,1120.14,916.08,1118.88z" />
    <path class="st0" d="M1134.78,587.08c0.65-0.67,1.3-1.34,1.96-2.01c0.75,0.81,1.5,1.63,2.26,2.44c-0.84,1.8-2.05,3.14-4.17,3.34
		c0,0,0.13,0.12,0.13,0.12C1134.89,589.68,1134.83,588.38,1134.78,587.08z" />
    <path class="st0" d="M1126.47,600.47c1.03,0.16,2.06,0.32,3.09,0.48c-1.15,1-2.26,2.06-3.5,2.93c-0.26,0.18-1.03-0.26-1.5-0.53
		c-0.11-0.06-0.09-0.67,0.07-0.86C1125.21,601.78,1125.86,601.14,1126.47,600.47z" />
    <path class="st0" d="M1345.71,942.11c0.13,1.99,0.26,3.99,0.39,5.98c-1.71,0.16-3.16-0.19-3.85-2
		C1344.18,945.43,1344.24,943.16,1345.71,942.11z" />
    <path class="st0" d="M1351.79,948.02c-2.46-2.99-2.44-3.22,0.35-5.62C1352.02,944.27,1351.91,946.15,1351.79,948.02z" />
    <path class="st0" d="M1139.01,587.44c0.36-1.76,1.64-2.33,3.22-2.5C1141.7,586.47,1140.55,587.21,1139.01,587.44z" />
    <path class="st0" d="M1347.94,950.06c1.27-0.08,2.53-0.17,3.8-0.25c-0.6,0.52-1.17,1.42-1.8,1.46
		C1349.3,951.3,1348.61,950.5,1347.94,950.06z" />
    <path class="st0" d="M1346.03,947.99c1.01,0.34,1.64,1.02,1.88,2.06c0,0-0.92,0.05-0.92,0.05l-0.91-0.15
		C1346.06,949.29,1346.05,948.64,1346.03,947.99z" />
    <path class="st0" d="M1142.41,584.79c0.53-0.55,1.07-1.1,1.6-1.65C1143.97,584.17,1143.42,584.7,1142.41,584.79z" />
    <path class="st0" d="M553.44,1543.55c0.18-1.18,0.88-1.82,2.07-1.91C555.14,1542.62,554.47,1543.28,553.44,1543.55z" />
    <path class="st0" d="M550.99,1543.36c0.81,0.08,1.61,0.16,2.42,0.25c-0.3,0.78-0.8,1.43-1.69,1.04
		C551.36,1544.49,551.23,1543.81,550.99,1543.36z" />
    <path class="st0" d="M914.34,1115.06c0.93,0.54,1.42,1.33,1.41,2.42C914.85,1116.92,914.35,1116.13,914.34,1115.06z" />
    <path class="st0" d="M795.5,1234.04c0.96,0.43,1.42,1.15,1.32,2.21C796.39,1235.52,795.94,1234.78,795.5,1234.04z" />
    <path class="st0" d="M1352.04,942.56c0.12-1.06,0.69-1.78,1.65-2.22c0,0.66,0.01,1.32,0.01,1.98c0,0,0.13-0.14,0.13-0.14
		C1353.24,942.38,1352.65,942.51,1352.04,942.56z" />
    <path class="st0" d="M869.83,1078.45c-0.08,0.28-0.12,0.77-0.23,0.79c-0.54,0.08-0.43-0.21,0.36-0.67
		C869.95,1078.56,869.83,1078.45,869.83,1078.45z" />
    <path class="st0" d="M1140.46,581.2c0.01-0.32,0.06-0.63,0.03-0.94c-0.19-2.14-0.9-2.98-2.69-2.98c-0.94,0-1.87,0.24-2.81,0.38
		c0.92-1.94,2.62-2.33,4.5-2.08c1.2,0.16,2.28,0.67,2.69,2.01c0.38,1.27,0.08,2.32-0.96,3.15
		C1140.98,580.91,1140.71,581.05,1140.46,581.2z" />
    <path class="st0" d="M1135.06,577.57c-0.31,2.08-0.1,4.32-1.86,5.94C1132.88,581.23,1132.51,578.95,1135.06,577.57z" />
    <path class="st0" d="M1138.27,582.91c0.75-0.59,1.5-1.18,2.25-1.77C1140.16,582.23,1139.43,582.85,1138.27,582.91z" />
    <path class="st0" d="M1196.42,913.41c-0.24-0.08-0.49-0.17-0.73-0.25c0.21,0.12,0.42,0.24,0.62,0.36
		C1196.31,913.52,1196.42,913.41,1196.42,913.41z" />
    <path class="st0" d="M1484.22,1186.38c-2.46-2.73-1.99-5.91-1.13-8.99c0.61-2.17,1.07-4.3,0.96-6.55c1.22,0.3,2.56,0.37,3.62,0.94
		c1.9,1.03,2.26,3.41,0.93,4.85c-0.43,0.47-0.87,0.97-1.41,1.27c-2.2,1.26-2.93,3.21-2.88,5.61
		C1484.34,1184.47,1484.26,1185.42,1484.22,1186.38z" />
    <path class="st0" d="M1459.43,1113.23c-0.04-0.77,0.22-1.06,0.78-0.85c0.03,0.01-0.02,0.5-0.14,0.56
		c-0.22,0.12-0.52,0.09-0.79,0.12C1459.28,1113.07,1459.43,1113.23,1459.43,1113.23z" />
    <path class="st0" d="M1196.48,1255.26c-0.08,1.59-0.43,3.03-2.16,3.65C1194.46,1257.35,1194.86,1255.95,1196.48,1255.26z" />
    <path class="st0" d="M1317.21,1055.46c-0.24,0.29-0.48,0.59-0.72,0.88c0.04-0.18,0.03-0.45,0.14-0.52
		c0.21-0.13,0.48-0.14,0.73-0.21C1317.37,1055.61,1317.21,1055.46,1317.21,1055.46z" />
  </g>
  <g id="pin_davao">
    <path class="st9" d="M1576.04,1649.6c0-17.04-15.4-30.49-33.05-27.18c-11.1,2.08-19.94,11.15-21.89,22.27
		c-1.22,6.95,0.22,13.49,3.39,18.89h-0.02l0.08,0.1c1.06,1.79,2.39,3.07,3.74,4.95l16.07,21.29c2,2.65,5.98,2.65,7.98,0l15.81-20.94
		c0,0,2.95-3.52,4.02-5.33l0.06-0.08h-0.01C1574.63,1659.47,1576.04,1654.7,1576.04,1649.6z" />
    <circle class="st5" cx="1548.36" cy="1649.47" r="14" />
    <g id="davao">
      <g>
        <path class="st1" d="M1944.66,1639.16c-7.29-4.23-14.59-8.42-21.85-12.7c-4.27-2.52-6.41-6.27-5.91-11.3
				c0.76-7.68,9.59-12.39,16.3-8.59c13.44,7.6,26.8,15.31,40.1,23.15c3.7,2.18,5.47,5.52,5.23,9.81c-0.27,4.74-2.47,8.22-6.98,10.06
				c-0.41,0.17-0.88,0.21-1.21,0.57c-3.99,0.69-7.53-0.47-10.88-2.54c-2.35-1.45-4.75-2.83-7.16-4.2
				C1949.76,1641.98,1947.27,1640.46,1944.66,1639.16z" />
        <path class="st2" d="M1872.49,1639.14c5.4,3.17,10.8,6.35,16.2,9.52c2.13,1.25,4.31,2.42,6.38,3.76c3.27,2.12,5.12,5.15,5.19,9.1
				c0.15,8.64-8.31,14.07-16.14,10.35c-2.01-0.95-3.9-2.14-5.83-3.26c-11.29-6.51-22.58-13.04-33.87-19.55
				c-3.09-1.78-5.13-4.28-5.71-7.9c-0.75-4.71,1.1-8.94,5.12-11.48c0.92-0.58,1.88-1.08,2.88-1.49c0.52,0.11,1.01-0.13,1.52-0.14
				c2.94-0.07,5.64,0.83,8.19,2.16c5.04,2.63,9.92,5.55,14.86,8.37C1871.67,1638.79,1872.03,1639.09,1872.49,1639.14z" />
        <path class="st3" d="M1944.66,1639.16c2.61,1.3,5.1,2.82,7.64,4.26c2.41,1.36,4.8,2.75,7.16,4.2c3.35,2.07,6.9,3.23,10.88,2.54
				c-0.19,0.41-0.57,0.61-0.93,0.82c-11.84,6.83-23.66,13.67-35.52,20.47c-6.96,3.99-15.16,0.39-16.88-7.35
				c-1.04-4.68,1.16-9.36,5.76-12.11c3.51-2.1,7.06-4.14,10.6-6.2c3.41-1.98,6.83-3.94,10.25-5.91
				C1943.98,1639.66,1944.31,1639.4,1944.66,1639.16z" />
        <path class="st4" d="M1872.49,1639.14c-0.47-0.05-0.82-0.35-1.21-0.57c-4.94-2.82-9.82-5.73-14.86-8.37
				c-2.55-1.33-5.25-2.23-8.19-2.16c-0.51,0.01-1,0.25-1.52,0.14c3.7-2.43,7.57-4.57,11.4-6.78c8.41-4.87,16.82-9.74,25.26-14.56
				c6.67-3.8,14.72-0.37,16.6,7.01c1.15,4.5-0.55,9.05-4.6,11.83c-3.32,2.28-6.91,4.15-10.39,6.19
				C1880.83,1634.32,1876.66,1636.73,1872.49,1639.14z" />
      </g>
      <text transform="matrix(1 0 0 1 2010.7566 1674.167)" class="st5 st6 st7">GDG Davao</text>
    </g>
  </g>
  <g id="pin_zamboanga">
    <path class="st9" d="M1169.78,1657.04c0-17.04-15.4-30.49-33.05-27.18c-11.1,2.08-19.94,11.15-21.89,22.27
		c-1.22,6.95,0.22,13.49,3.39,18.89h-0.02l0.08,0.1c1.06,1.79,2.39,3.07,3.74,4.95l16.07,21.29c2,2.65,5.98,2.65,7.98,0l15.81-20.94
		c0,0,2.95-3.52,4.02-5.33l0.06-0.08h-0.01C1168.37,1666.92,1169.78,1662.15,1169.78,1657.04z" />
    <circle class="st5" cx="1142.1" cy="1656.91" r="14" />
    <g id="zamboanga">
      <g>
        <path class="st1" d="M1988.27,1442.53c-7.29-4.23-14.59-8.42-21.85-12.7c-4.27-2.52-6.41-6.27-5.91-11.3
				c0.76-7.68,9.59-12.39,16.3-8.59c13.44,7.6,26.8,15.31,40.1,23.15c3.7,2.18,5.47,5.52,5.23,9.81c-0.27,4.74-2.47,8.22-6.98,10.06
				c-0.41,0.17-0.88,0.21-1.21,0.57c-3.99,0.69-7.53-0.47-10.88-2.54c-2.35-1.45-4.75-2.83-7.16-4.2
				C1993.37,1445.35,1990.88,1443.83,1988.27,1442.53z" />
        <path class="st2" d="M1916.11,1442.51c5.4,3.17,10.8,6.35,16.2,9.52c2.13,1.25,4.31,2.42,6.38,3.76c3.27,2.12,5.12,5.15,5.19,9.1
				c0.15,8.64-8.31,14.07-16.14,10.35c-2.01-0.95-3.9-2.14-5.83-3.26c-11.29-6.51-22.58-13.04-33.87-19.55
				c-3.09-1.78-5.13-4.28-5.71-7.9c-0.75-4.71,1.1-8.94,5.13-11.48c0.92-0.58,1.88-1.08,2.88-1.49c0.52,0.11,1.01-0.13,1.52-0.14
				c2.94-0.07,5.64,0.83,8.19,2.16c5.04,2.63,9.92,5.55,14.86,8.37C1915.28,1442.16,1915.64,1442.46,1916.11,1442.51z" />
        <path class="st3" d="M1988.27,1442.53c2.61,1.3,5.1,2.82,7.64,4.26c2.41,1.36,4.8,2.75,7.16,4.2c3.35,2.07,6.89,3.23,10.88,2.54
				c-0.19,0.41-0.57,0.61-0.93,0.82c-11.84,6.83-23.66,13.67-35.52,20.47c-6.96,3.99-15.16,0.39-16.88-7.35
				c-1.04-4.68,1.16-9.36,5.76-12.11c3.51-2.1,7.06-4.14,10.6-6.2c3.41-1.98,6.83-3.94,10.25-5.91
				C1987.59,1443.03,1987.92,1442.77,1988.27,1442.53z" />
        <path class="st4" d="M1916.11,1442.51c-0.47-0.05-0.82-0.35-1.21-0.57c-4.94-2.82-9.82-5.73-14.86-8.37
				c-2.55-1.33-5.25-2.23-8.19-2.16c-0.51,0.01-1,0.25-1.52,0.14c3.7-2.43,7.57-4.57,11.4-6.78c8.41-4.87,16.82-9.74,25.26-14.56
				c6.67-3.8,14.72-0.37,16.6,7.01c1.15,4.5-0.55,9.05-4.6,11.83c-3.32,2.28-6.91,4.15-10.39,6.19
				C1924.44,1437.69,1920.27,1440.1,1916.11,1442.51z" />
      </g>
      <text transform="matrix(1 0 0 1 2054.3687 1477.5353)" class="st5 st6 st7">GDG Zamboanga</text>
    </g>
  </g>
  <g id="pin_cdo">
    <path class="st9" d="M1473.88,1494.59c0-17.04-15.4-30.49-33.05-27.18c-11.1,2.08-19.94,11.15-21.89,22.27
		c-1.22,6.95,0.22,13.49,3.39,18.89h-0.02l0.08,0.1c1.06,1.79,2.39,3.07,3.74,4.95l16.07,21.29c2,2.65,5.98,2.65,7.98,0l15.81-20.94
		c0,0,2.95-3.52,4.02-5.33l0.06-0.08h-0.01C1472.47,1504.46,1473.88,1499.69,1473.88,1494.59z" />
    <circle class="st5" cx="1446.2" cy="1494.46" r="14" />
    <g id="cdo">
      <g>
        <path class="st1" d="M1872.66,1248.9c-7.29-4.23-14.59-8.42-21.85-12.7c-4.27-2.52-6.41-6.27-5.91-11.3
				c0.76-7.68,9.59-12.39,16.3-8.59c13.44,7.6,26.8,15.31,40.1,23.15c3.7,2.18,5.47,5.52,5.23,9.81c-0.27,4.74-2.47,8.22-6.98,10.06
				c-0.41,0.17-0.88,0.21-1.21,0.57c-3.99,0.69-7.53-0.47-10.88-2.54c-2.35-1.45-4.75-2.83-7.16-4.2
				C1877.76,1251.72,1875.27,1250.19,1872.66,1248.9z" />
        <path class="st2" d="M1800.5,1248.88c5.4,3.17,10.8,6.35,16.2,9.52c2.13,1.25,4.31,2.42,6.38,3.76c3.27,2.12,5.12,5.15,5.19,9.1
				c0.15,8.64-8.31,14.07-16.14,10.35c-2.01-0.95-3.9-2.14-5.83-3.26c-11.29-6.51-22.58-13.04-33.87-19.55
				c-3.09-1.78-5.13-4.28-5.71-7.9c-0.75-4.71,1.1-8.94,5.12-11.48c0.92-0.58,1.88-1.08,2.88-1.49c0.52,0.11,1.01-0.13,1.52-0.14
				c2.94-0.07,5.64,0.83,8.19,2.16c5.04,2.63,9.92,5.55,14.86,8.37C1799.67,1248.52,1800.03,1248.83,1800.5,1248.88z" />
        <path class="st3" d="M1872.66,1248.9c2.61,1.3,5.1,2.82,7.64,4.26c2.41,1.36,4.8,2.75,7.16,4.2c3.35,2.07,6.9,3.23,10.88,2.54
				c-0.19,0.41-0.57,0.61-0.93,0.82c-11.84,6.83-23.66,13.67-35.52,20.47c-6.96,3.99-15.16,0.39-16.88-7.35
				c-1.04-4.68,1.16-9.36,5.76-12.11c3.51-2.1,7.06-4.14,10.6-6.2c3.41-1.98,6.83-3.94,10.25-5.91
				C1871.98,1249.4,1872.32,1249.13,1872.66,1248.9z" />
        <path class="st4" d="M1800.5,1248.88c-0.47-0.05-0.82-0.35-1.21-0.57c-4.94-2.82-9.82-5.73-14.86-8.37
				c-2.55-1.33-5.25-2.23-8.19-2.16c-0.51,0.01-1,0.25-1.52,0.14c3.7-2.43,7.57-4.57,11.4-6.78c8.41-4.87,16.82-9.74,25.26-14.56
				c6.67-3.8,14.72-0.37,16.6,7.01c1.15,4.5-0.55,9.05-4.6,11.83c-3.32,2.28-6.91,4.15-10.39,6.19
				C1808.83,1244.06,1804.66,1246.46,1800.5,1248.88z" />
      </g>
      <text transform="matrix(1 0 0 1 1938.7605 1283.9019)" class="st5 st6 st7">GDG Cagayan de Oro</text>
    </g>
  </g>
  <g id="pin_cebu">
    <path class="st9" d="M1383.65,1257.92c0-17.04-15.4-30.49-33.05-27.18c-11.1,2.08-19.94,11.15-21.89,22.27
		c-1.22,6.95,0.22,13.49,3.39,18.89h-0.02l0.08,0.1c1.06,1.79,2.39,3.07,3.74,4.95l16.07,21.29c2,2.65,5.98,2.65,7.98,0l15.81-20.94
		c0,0,2.95-3.52,4.02-5.33l0.06-0.08h-0.01C1382.24,1267.8,1383.65,1263.03,1383.65,1257.92z" />
    <circle class="st5" cx="1355.97" cy="1257.79" r="14" />
    <g id="cebu">
      <g>
        <path class="st1" d="M1810.31,991.97c-7.29-4.23-14.59-8.42-21.85-12.7c-4.27-2.52-6.41-6.27-5.91-11.3
				c0.76-7.68,9.59-12.39,16.3-8.59c13.44,7.6,26.8,15.31,40.1,23.15c3.7,2.18,5.47,5.52,5.23,9.81c-0.27,4.74-2.47,8.22-6.98,10.06
				c-0.41,0.17-0.88,0.21-1.21,0.57c-3.99,0.69-7.53-0.47-10.88-2.54c-2.35-1.45-4.75-2.83-7.16-4.2
				C1815.41,994.78,1812.92,993.26,1810.31,991.97z" />
        <path class="st2" d="M1738.14,991.95c5.4,3.17,10.8,6.35,16.2,9.52c2.13,1.25,4.31,2.42,6.38,3.76c3.27,2.12,5.12,5.15,5.19,9.1
				c0.15,8.64-8.31,14.07-16.14,10.35c-2.01-0.95-3.9-2.14-5.83-3.26c-11.29-6.51-22.58-13.04-33.87-19.55
				c-3.09-1.78-5.13-4.28-5.71-7.9c-0.75-4.71,1.1-8.94,5.13-11.48c0.92-0.58,1.88-1.08,2.88-1.49c0.52,0.11,1.01-0.13,1.52-0.14
				c2.94-0.07,5.64,0.83,8.19,2.16c5.04,2.63,9.92,5.55,14.86,8.37C1737.32,991.59,1737.67,991.89,1738.14,991.95z" />
        <path class="st3" d="M1810.31,991.97c2.61,1.3,5.1,2.82,7.64,4.26c2.41,1.36,4.8,2.75,7.16,4.2c3.35,2.07,6.89,3.23,10.88,2.54
				c-0.19,0.41-0.57,0.61-0.93,0.82c-11.84,6.83-23.66,13.67-35.52,20.47c-6.96,3.99-15.16,0.39-16.88-7.35
				c-1.04-4.68,1.16-9.36,5.76-12.11c3.51-2.1,7.06-4.14,10.6-6.2c3.41-1.98,6.83-3.94,10.25-5.91
				C1809.63,992.47,1809.96,992.2,1810.31,991.97z" />
        <path class="st4" d="M1738.14,991.95c-0.47-0.05-0.82-0.35-1.21-0.57c-4.94-2.82-9.82-5.73-14.86-8.37
				c-2.55-1.33-5.25-2.23-8.19-2.16c-0.51,0.01-1,0.25-1.52,0.14c3.7-2.43,7.57-4.57,11.4-6.78c8.41-4.87,16.82-9.74,25.26-14.56
				c6.67-3.8,14.72-0.37,16.6,7.01c1.15,4.5-0.55,9.05-4.6,11.83c-3.32,2.28-6.91,4.15-10.39,6.19
				C1746.48,987.13,1742.31,989.53,1738.14,991.95z" />
      </g>
      <text transform="matrix(1 0 0 1 1876.4039 1026.9708)" class="st5 st6 st7">GDG Cebu</text>
    </g>
  </g>
  <g id="pin_bacolod">
    <path class="st9" d="M1284.27,1215.95c0-17.04-15.4-30.49-33.05-27.18c-11.1,2.08-19.94,11.15-21.89,22.27
		c-1.22,6.95,0.22,13.49,3.39,18.89h-0.02l0.08,0.1c1.06,1.79,2.39,3.07,3.74,4.95l16.07,21.29c2,2.65,5.98,2.65,7.98,0l15.81-20.94
		c0,0,2.95-3.52,4.02-5.33l0.06-0.08h-0.01C1282.87,1225.83,1284.27,1221.06,1284.27,1215.95z" />
    <circle class="st5" cx="1256.59" cy="1215.82" r="14" />
    <g id="bacolod">
      <g>
        <path class="st1" d="M1714.1,819.94c-7.29-4.23-14.59-8.42-21.85-12.7c-4.27-2.52-6.41-6.27-5.91-11.3
				c0.76-7.68,9.59-12.39,16.3-8.59c13.44,7.6,26.8,15.31,40.1,23.15c3.7,2.18,5.47,5.52,5.23,9.81c-0.27,4.74-2.47,8.22-6.98,10.06
				c-0.41,0.17-0.88,0.21-1.21,0.57c-3.99,0.69-7.53-0.47-10.88-2.54c-2.35-1.45-4.75-2.83-7.16-4.2
				C1719.2,822.76,1716.71,821.24,1714.1,819.94z" />
        <path class="st2" d="M1641.94,819.93c5.4,3.17,10.8,6.35,16.2,9.52c2.13,1.25,4.31,2.42,6.38,3.76c3.27,2.12,5.12,5.15,5.19,9.1
				c0.15,8.64-8.31,14.07-16.14,10.35c-2.01-0.95-3.9-2.14-5.83-3.26c-11.29-6.51-22.58-13.04-33.87-19.55
				c-3.09-1.78-5.13-4.28-5.71-7.9c-0.75-4.71,1.1-8.94,5.13-11.48c0.92-0.58,1.88-1.08,2.88-1.49c0.52,0.11,1.01-0.13,1.52-0.14
				c2.94-0.07,5.64,0.83,8.19,2.16c5.04,2.63,9.92,5.55,14.86,8.37C1641.11,819.57,1641.47,819.87,1641.94,819.93z" />
        <path class="st3" d="M1714.1,819.94c2.61,1.3,5.1,2.82,7.64,4.26c2.41,1.36,4.8,2.75,7.16,4.2c3.35,2.07,6.89,3.23,10.88,2.54
				c-0.19,0.41-0.57,0.61-0.93,0.82c-11.84,6.83-23.66,13.67-35.52,20.47c-6.96,3.99-15.16,0.39-16.88-7.35
				c-1.04-4.68,1.16-9.36,5.76-12.11c3.51-2.1,7.06-4.14,10.6-6.2c3.41-1.98,6.83-3.94,10.25-5.91
				C1713.42,820.44,1713.75,820.18,1714.1,819.94z" />
        <path class="st4" d="M1641.94,819.93c-0.47-0.05-0.82-0.35-1.21-0.57c-4.94-2.82-9.82-5.73-14.86-8.37
				c-2.55-1.33-5.25-2.23-8.19-2.16c-0.51,0.01-1,0.25-1.52,0.14c3.7-2.43,7.57-4.57,11.4-6.78c8.41-4.87,16.82-9.74,25.26-14.56
				c6.67-3.8,14.72-0.37,16.6,7.01c1.15,4.5-0.55,9.05-4.6,11.83c-3.32,2.28-6.91,4.15-10.39,6.19
				C1650.27,815.1,1646.1,817.51,1641.94,819.93z" />
      </g>
      <text transform="matrix(1 0 0 1 1780.1974 854.9492)" class="st5 st6 st7">GDG Bacolod</text>
    </g>
  </g>
  <g id="pin_halsema">
    <path class="st9" d="M1037.45,461.04c0-17.04-15.4-30.49-33.05-27.18c-11.1,2.08-19.94,11.15-21.89,22.27
		c-1.22,6.95,0.22,13.49,3.39,18.89h-0.02l0.08,0.1c1.06,1.79,2.39,3.07,3.74,4.95l16.07,21.29c2,2.65,5.98,2.65,7.98,0l15.81-20.94
		c0,0,2.95-3.52,4.02-5.33l0.06-0.08h-0.01C1036.04,470.92,1037.45,466.15,1037.45,461.04z" />
    <circle class="st5" cx="1009.76" cy="460.91" r="14" />
    <g id="halsema">
      <g>
        <path class="st1" d="M1480.02,402.02c-7.29-4.23-14.59-8.42-21.85-12.7c-4.27-2.52-6.41-6.27-5.91-11.3
				c0.76-7.68,9.59-12.39,16.3-8.59c13.44,7.6,26.8,15.31,40.1,23.15c3.7,2.18,5.47,5.52,5.23,9.81c-0.27,4.74-2.47,8.22-6.98,10.06
				c-0.41,0.17-0.88,0.21-1.21,0.57c-3.99,0.69-7.53-0.47-10.88-2.54c-2.35-1.45-4.75-2.83-7.16-4.2
				C1485.12,404.83,1482.63,403.31,1480.02,402.02z" />
        <path class="st2" d="M1407.85,402c5.4,3.17,10.8,6.35,16.2,9.52c2.13,1.25,4.31,2.42,6.38,3.76c3.27,2.12,5.12,5.15,5.19,9.1
				c0.15,8.64-8.31,14.07-16.14,10.35c-2.01-0.95-3.9-2.14-5.83-3.26c-11.29-6.51-22.58-13.04-33.87-19.55
				c-3.09-1.78-5.13-4.28-5.71-7.9c-0.75-4.71,1.1-8.94,5.13-11.48c0.92-0.58,1.88-1.08,2.88-1.49c0.52,0.11,1.01-0.13,1.52-0.14
				c2.94-0.07,5.64,0.83,8.19,2.16c5.04,2.63,9.92,5.55,14.86,8.37C1407.03,401.64,1407.38,401.94,1407.85,402z" />
        <path class="st3" d="M1480.02,402.02c2.61,1.3,5.1,2.82,7.64,4.26c2.41,1.36,4.8,2.75,7.16,4.2c3.35,2.07,6.89,3.23,10.88,2.54
				c-0.19,0.41-0.57,0.61-0.93,0.82c-11.84,6.83-23.66,13.67-35.52,20.47c-6.96,3.99-15.16,0.39-16.88-7.35
				c-1.04-4.68,1.16-9.36,5.76-12.11c3.51-2.1,7.06-4.14,10.6-6.2c3.41-1.98,6.83-3.94,10.25-5.91
				C1479.34,402.52,1479.67,402.25,1480.02,402.02z" />
        <path class="st4" d="M1407.85,402c-0.47-0.05-0.82-0.35-1.21-0.57c-4.94-2.82-9.82-5.73-14.86-8.37
				c-2.55-1.33-5.25-2.23-8.19-2.16c-0.51,0.01-1,0.25-1.52,0.14c3.7-2.43,7.57-4.57,11.4-6.78c8.41-4.87,16.82-9.74,25.26-14.56
				c6.67-3.8,14.72-0.37,16.6,7.01c1.15,4.5-0.55,9.05-4.6,11.83c-3.32,2.28-6.91,4.15-10.39,6.19
				C1416.19,397.18,1412.02,399.58,1407.85,402z" />
      </g>
      <text transform="matrix(1 0 0 1 1546.1155 437.021)" class="st5 st6 st7">GDG Halsema</text>
    </g>
  </g>
  <g id="pin_manila">
    <path class="st9" d="M1037.6,737.58c0-17.04-15.4-30.49-33.05-27.18c-11.1,2.08-19.94,11.15-21.89,22.27
		c-1.22,6.95,0.22,13.49,3.39,18.89h-0.02l0.08,0.1c1.06,1.79,2.39,3.07,3.74,4.95l16.07,21.29c2,2.65,5.98,2.65,7.98,0l15.81-20.94
		c0,0,2.95-3.52,4.02-5.33l0.06-0.08h-0.01C1036.2,747.46,1037.6,742.69,1037.6,737.58z" />
    <circle class="st5" cx="1009.92" cy="737.45" r="14" />
    <g id="manila">
      <g>
        <path class="st1" d="M106.11,819.39c-7.29-4.23-14.59-8.42-21.85-12.7c-4.27-2.52-6.41-6.27-5.91-11.3
				c0.76-7.68,9.59-12.39,16.3-8.59c13.44,7.6,26.8,15.31,40.1,23.15c3.7,2.18,5.47,5.52,5.23,9.81c-0.27,4.74-2.47,8.22-6.98,10.06
				c-0.41,0.17-0.88,0.21-1.21,0.57c-3.99,0.69-7.53-0.47-10.88-2.54c-2.35-1.45-4.75-2.83-7.16-4.2
				C111.21,822.21,108.73,820.69,106.11,819.39z" />
        <path class="st2" d="M33.95,819.37c5.4,3.17,10.8,6.35,16.2,9.52c2.13,1.25,4.31,2.42,6.38,3.76c3.27,2.12,5.12,5.15,5.19,9.1
				c0.15,8.64-8.31,14.07-16.14,10.35c-2.01-0.95-3.9-2.14-5.83-3.26c-11.29-6.51-22.58-13.04-33.87-19.55
				c-3.09-1.78-5.13-4.28-5.71-7.9c-0.75-4.71,1.1-8.94,5.12-11.48c0.92-0.58,1.88-1.08,2.88-1.49c0.52,0.11,1.01-0.13,1.52-0.14
				c2.94-0.07,5.64,0.83,8.19,2.16c5.04,2.63,9.92,5.55,14.86,8.37C33.13,819.02,33.48,819.32,33.95,819.37z" />
        <path class="st3" d="M106.11,819.39c2.61,1.3,5.1,2.82,7.64,4.26c2.41,1.36,4.8,2.75,7.16,4.2c3.35,2.07,6.89,3.23,10.88,2.54
				c-0.19,0.41-0.57,0.61-0.93,0.82c-11.84,6.83-23.66,13.67-35.52,20.47c-6.96,3.99-15.16,0.39-16.88-7.35
				c-1.04-4.68,1.16-9.36,5.76-12.11c3.51-2.1,7.06-4.14,10.6-6.2c3.41-1.98,6.83-3.94,10.25-5.91
				C105.43,819.89,105.77,819.63,106.11,819.39z" />
        <path class="st4" d="M33.95,819.37c-0.47-0.05-0.82-0.35-1.21-0.57c-4.94-2.82-9.82-5.73-14.86-8.37
				c-2.55-1.33-5.25-2.23-8.19-2.16c-0.51,0.01-1,0.25-1.52,0.14c3.7-2.43,7.57-4.57,11.4-6.78c8.41-4.87,16.82-9.74,25.26-14.56
				c6.67-3.8,14.72-0.37,16.6,7.01c1.15,4.5-0.55,9.05-4.6,11.83c-3.32,2.28-6.91,4.15-10.39,6.19
				C42.28,814.55,38.11,816.96,33.95,819.37z" />
      </g>
      <text transform="matrix(1 0 0 1 172.2122 854.3951)" class="st5 st6 st7">GDG Manila</text>
    </g>
  </g>
  <g id="pin_baguio">
    <path class="st9" d="M1001,517.53c0-17.04-15.4-30.49-33.05-27.18c-11.1,2.08-19.94,11.15-21.89,22.27
		c-1.22,6.95,0.22,13.49,3.39,18.89h-0.02l0.08,0.1c1.06,1.79,2.39,3.07,3.74,4.95l16.07,21.29c2,2.65,5.98,2.65,7.98,0l15.81-20.94
		c0,0,2.95-3.52,4.02-5.33l0.06-0.08h-0.01C999.6,527.41,1001,522.64,1001,517.53z" />
    <circle class="st5" cx="973.32" cy="517.4" r="14" />
    <g id="baguio">
      <g>
        <path class="st1" d="M1436.14,574.97c-7.29-4.23-14.59-8.42-21.85-12.7c-4.27-2.52-6.41-6.27-5.91-11.3
				c0.76-7.68,9.59-12.39,16.3-8.59c13.44,7.6,26.8,15.31,40.1,23.15c3.7,2.18,5.47,5.52,5.23,9.81c-0.27,4.74-2.47,8.22-6.98,10.06
				c-0.41,0.17-0.88,0.21-1.21,0.57c-3.99,0.69-7.53-0.47-10.88-2.54c-2.35-1.45-4.75-2.83-7.16-4.2
				C1441.24,577.78,1438.75,576.26,1436.14,574.97z" />
        <path class="st2" d="M1363.97,574.95c5.4,3.17,10.8,6.35,16.2,9.52c2.13,1.25,4.31,2.42,6.38,3.76c3.27,2.12,5.12,5.15,5.19,9.1
				c0.15,8.64-8.31,14.07-16.14,10.35c-2.01-0.95-3.9-2.14-5.83-3.26c-11.29-6.51-22.58-13.04-33.87-19.55
				c-3.09-1.78-5.13-4.28-5.71-7.9c-0.75-4.71,1.1-8.94,5.12-11.48c0.92-0.58,1.88-1.08,2.88-1.49c0.52,0.11,1.01-0.13,1.52-0.14
				c2.94-0.07,5.64,0.83,8.19,2.16c5.04,2.63,9.92,5.55,14.86,8.37C1363.15,574.59,1363.5,574.89,1363.97,574.95z" />
        <path class="st3" d="M1436.14,574.97c2.61,1.3,5.1,2.82,7.64,4.26c2.41,1.36,4.8,2.75,7.16,4.2c3.35,2.07,6.9,3.23,10.88,2.54
				c-0.19,0.41-0.57,0.61-0.93,0.82c-11.84,6.83-23.66,13.67-35.52,20.47c-6.96,3.99-15.16,0.39-16.88-7.35
				c-1.04-4.68,1.16-9.36,5.76-12.11c3.51-2.1,7.06-4.14,10.6-6.2c3.41-1.98,6.83-3.94,10.25-5.91
				C1435.46,575.47,1435.79,575.2,1436.14,574.97z" />
        <path class="st4" d="M1363.97,574.95c-0.47-0.05-0.82-0.35-1.21-0.57c-4.94-2.82-9.82-5.73-14.86-8.37
				c-2.55-1.33-5.25-2.23-8.19-2.16c-0.51,0.01-1,0.25-1.52,0.14c3.7-2.43,7.57-4.57,11.4-6.78c8.41-4.87,16.82-9.74,25.26-14.56
				c6.67-3.8,14.72-0.37,16.6,7.01c1.15,4.5-0.55,9.05-4.6,11.83c-3.32,2.28-6.91,4.15-10.39,6.19
				C1372.31,570.13,1368.14,572.53,1363.97,574.95z" />
      </g>
      <text transform="matrix(1 0 0 1 1502.2345 609.9705)" class="st5 st6 st7">GDG Baguio</text>
    </g>
  </g>
</svg>