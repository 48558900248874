import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/material/material.module';
import { OngoingEventsModule } from 'src/app/components/events/ongoing-events/ongoing-events.module';
import { UpcomingEventsModule } from 'src/app/components/events/upcoming-events/upcoming-events.module';
import { ApplicationFormModule } from 'src/app/components/application-form/application-form.module';
import { FooterModule } from 'src/app/components/footer/footer.module';

import { HomeComponent } from './home.component';
import { CommunityMapComponent } from 'src/app/components/community-map/community-map.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModule,
    OngoingEventsModule,
    UpcomingEventsModule,
    ApplicationFormModule,
    FooterModule,
  ],
  exports: [HomeComponent],
  declarations: [HomeComponent, CommunityMapComponent],
})
export class HomeModule {}
