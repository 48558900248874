<mat-toolbar [class.topbar-fixed]="scrolltopvalue > 0">
  <button *ngIf="breakpointView.handset" mat-icon-button (click)="toggleMenu()" id="topbar-menu" [class.active]="toggleActive" aria-label="menu icon">
    <mat-icon>menu</mat-icon>
  </button>

  <!-- <button *ngIf="handset" mat-icon-button (click)="toggleMenu()" id="topbar-menu" [class.active]="toggleActive" aria-label="menu icon">
    <mat-icon>menu</mat-icon>
  </button> -->
  <span class="site-logo">
    <a [routerLink]="['']">
      <img src={{logo}} alt="gdgbaguio-logo">
    </a>
  </span>
  <div *ngIf="breakpointView.web" class="top-bar-nav">
    <mat-divider [vertical]="true"></mat-divider>
    <!-- <div [class.is-active]="(url == '/community') ? true : false" class="nav-link" [routerLink]="['/community']">
      <a >Community</a>
    </div> -->
    <div class="nav-link">
      <a mat-button disabled>Community</a>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div [class.is-active]="(url == '/events') ? true : false" class="nav-link" [routerLink]="['/events']">
      <a>Events</a>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div [class.is-active]="(url == '/team') ? true : false" class="nav-link" [routerLink]="['/team']">
      <a>Team</a>
    </div>
  </div>
  <span class="spacer"></span>
  <ng-container *ngIf="user$ | async as user; else loginIcon">
    <!-- <mat-icon aria-hidden="false" aria-label="Notifications" matBadge="7" [matBadgeHidden]="hidden" (click)="toggleBadgeVisibility()">
      notifications_none</mat-icon> -->
    <signedin-account-menu-card [user]="user" (logoutClick)="logout()">
    </signedin-account-menu-card>
  </ng-container>
  <ng-template #loginIcon>
    <!-- <mat-icon aria-hidden="false" aria-label="Notifications" disabled>notifications_none</mat-icon> -->
    <button mat-button color="primary" aria-label="Signin with Google" (click)="signInWithGoogle()"> Sign in</button>
  </ng-template>
</mat-toolbar>