import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { breakpointLayout, breakpointView } from 'src/app/model/constants';

@Injectable({ providedIn: 'root' })
export class BreakpointObserverService {
  breakpointView = breakpointView;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.initBreakpointObserver();
  }

  initBreakpointObserver() {
    this.breakpointObserver
      .observe(Breakpoints.HandsetPortrait)
      .subscribe((result) => {
        // console.log('result---', result);
        if (result.matches) {
          this.breakpointView.breakpointView = breakpointLayout.handsetportrait;
          this.breakpointViewObserver();
        }
      });

    this.breakpointObserver
      .observe(Breakpoints.HandsetLandscape)
      .subscribe((result) => {
        // console.log('result---', result);
        if (result.matches) {
          this.breakpointView.breakpointView =
            breakpointLayout.handsetlandscape;
          this.breakpointViewObserver();
        }
      });

    this.breakpointObserver
      .observe(Breakpoints.WebLandscape)
      .subscribe((result) => {
        // console.log('result---', result);
        if (result.matches) {
          this.breakpointView.breakpointView = breakpointLayout.weblandscape;
          this.breakpointViewObserver();
        }
      });

    this.breakpointObserver
      .observe(Breakpoints.WebPortrait)
      .subscribe((result) => {
        // console.log('result---', result);
        if (result.matches) {
          this.breakpointView.breakpointView = breakpointLayout.webportrait;
          this.breakpointViewObserver();
        }
      });

    this.breakpointObserver
      .observe(Breakpoints.TabletPortrait)
      .subscribe((result) => {
        // console.log('result---', result);
        if (result.matches) {
          this.breakpointView.breakpointView = breakpointLayout.tabletportrait;
          this.breakpointViewObserver();
        }
      });

    this.breakpointObserver
      .observe(Breakpoints.TabletLandscape)
      .subscribe((result) => {
        // console.log('result---', result);
        if (result.matches) {
          this.breakpointView.breakpointView = breakpointLayout.tabletlandscape;
          this.breakpointViewObserver();
        }
      });
  }

  breakpointViewObserver() {
    switch (this.breakpointView.breakpointView) {
      case breakpointLayout.weblandscape || breakpointLayout.webportrait:
        this.breakpointView.web = true;
        this.breakpointView.handset = false;
        this.breakpointView.tablet = false;
        break;
      case breakpointLayout.handsetportrait ||
        breakpointLayout.handsetlandscape:
        this.breakpointView.web = false;
        this.breakpointView.handset = true;
        this.breakpointView.tablet = false;
        break;
      case breakpointLayout.tabletportrait || breakpointLayout.tabletlandscape:
        this.breakpointView.web = false;
        this.breakpointView.handset = false;
        this.breakpointView.tablet = true;
        break;
      // default:
      //   break;
    }
  }
}
