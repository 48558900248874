<mat-horizontal-stepper [class.is-mobile]="breakpointView.handset" [class.is-web]="breakpointView.web" [class.is-tablet]="breakpointView.tablet"
  #stepper>
  <mat-step>
    <h1 *ngIf="data.formtype==applicationType.womentechmakers" mat-dialog-title>Join the movement</h1>
    <h1 *ngIf="data.formtype==applicationType.speaker" mat-dialog-title>Request Speakers</h1>
    <h1 *ngIf="data.formtype==applicationType.volunteer" mat-dialog-title>Become a Volunteer</h1>
    <h1 *ngIf="data.formtype==applicationType.sponsorship" mat-dialog-title>Sponsorship</h1>
    <div mat-dialog-content class="mat-typography">
      <p *ngIf="data.formtype==applicationType.womentechmakers">WTM Baguio aims to bridge the gender gap and increase equality in tech by providing
        the community an opportunity
        to meet individuals with the
        same drive and passion in tech; networking and exposing them to opportunities through engagement with different field leading speakers on
        diverse topics focusing on personal and professional advancement along with social issues.</p>
      <p *ngIf="data.formtype==applicationType.volunteer">
        Google Developer Group Baguio is opening a new initiative of group of volunteers. We are currently looking for: <br><br>
        ✔ Passionate and goal driven volunteers with a propensity in learning google technologies and developing apps. <br>
        ✔ Aspiring leaders of the future.
      </p>
      <p *ngIf="data.formtype==applicationType.speaker">
        Thank you for your interest in having an event with Google Developer Group (GDG) Baguio. GDG Baguio is a community of developers passionate
        about
        technology, particularly Google developer technologies. <br><br>
        Kindly fill-up the form at least two weeks before the event. We would be sending an email to confirm our availability and discuss further.
        Please
        reply back to us as early as possible to schedule your event and plan accordingly. <br>
        Thank you so much!
      </p>
      <p *ngIf="data.formtype==applicationType.sponsorship">
        To learn more about sponsorships, please email us at <a
          href="mailto:gdgbaguio@gmail.com?subject=GDG Baguio Sponsorship">gdgbaguio@gmail.com</a>
      </p>
    </div>
    <div class="stepper-buttons">
      <button *ngIf="data.formtype!=applicationType.sponsorship" mat-button matStepperNext color="primary">Next</button>
      <a *ngIf="data.formtype==applicationType.sponsorship" mat-raised-button href="mailto:gdgbaguio@gmail.com?subject=GDG Baguio Sponsorship"
        color="primary">Drop an email</a>
    </div>
  </mat-step>
  <ng-container *ngIf="data.formtype!=applicationType.speaker && data.formtype!=applicationType.sponsorship; else thisForm">
    <mat-step [stepControl]="applicationFormgroup">
      <h1>Provide information</h1>
      <form [formGroup]="applicationFormgroup" class="application-form">
        <input type="hidden" formControlName="formtype" readonly>
        <input type="hidden" formControlName="uid" readonly>
        <mat-form-field>
          <mat-label>Full name</mat-label>
          <input matInput placeholder="John Doe" formControlName="fullname" required>
          <!-- <input matInput placeholder="Last name, First name" matInput [(ngModel)]="data.fullname" formControlName="firstCtrl" required> -->
        </mat-form-field>
        <mat-form-field>
          <mat-label>Email address</mat-label>
          <input matInput placeholder="foo@bar.com" formControlName="email" required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Organization Name</mat-label>
          <input matInput placeholder="School Name / Company Name" formControlName="organization" required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Position</mat-label>
          <input matInput placeholder="Degree / Profession" matInput formControlName="position" required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Mobile number</mat-label>
          <input matInput #number maxlength="11" placeholder="09123456789" pattern="-?[0-9]*(\.[0-9]+)?" formControlName="mobilenumber" required>
          <mat-hint align="end">{{number.value?.length || 0}}/11</mat-hint>
        </mat-form-field>

        <div class="stepper-buttons">
          <button mat-button matStepperPrevious>Back</button>
          <button type="submit" mat-flat-button color="primary" (click)="submitApplicationRequest(applicationFormgroup)"
            [disabled]="!applicationFormgroup.valid">Submit</button>
        </div>
      </form>

      <!-- <div class="stepper-buttons">
      <button mat-button matStepperPrevious>Back</button>
      <button mat-flat-button matStepperNext color="primary">Submit</button>
    </div> -->
    </mat-step>
  </ng-container>
  <ng-template #thisForm>
    <mat-step [stepControl]="speakerRequestFormgroup">
      <h1>Provide information</h1>
      <form [formGroup]="speakerRequestFormgroup" class="application-form">
        <input type="hidden" formControlName="formtype" readonly>
        <input type="hidden" formControlName="uid" readonly>
        <mat-form-field>
          <mat-label>Your email address</mat-label>
          <input matInput placeholder="foo@bar.com" formControlName="email" required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Event name</mat-label>
          <input matInput placeholder="Awesome event" formControlName="eventname" required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Event Location</mat-label>
          <input matInput placeholder="Awesome location" formControlName="location" required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Event description</mat-label>
          <input matInput placeholder="Awesome description" formControlName="description" required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Target audience</mat-label>
          <input matInput placeholder="Awesome audience" formControlName="audience" required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Target Number of Audience</mat-label>
          <input matInput #totalaudiencenumber maxlength="4" placeholder="4999" pattern="-?[0-9]*(\.[0-9]+)?" formControlName="audiencenumber">
          <mat-hint align="end">{{totalaudiencenumber.value?.length || 0}}/4 digits</mat-hint>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Google technologies to be discussed</mat-label>
          <input matInput placeholder="Awesome technologies" formControlName="techtodiscuss" required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Start date" formControlName="startdate">
            <input matEndDate placeholder="End date" formControlName="enddate">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <div class="stepper-buttons">
          <button mat-button matStepperPrevious>Back</button>
          <button type="submit" mat-flat-button color="primary" (click)="submitSpeakerApplicationRequest(speakerRequestFormgroup)"
            [disabled]="!speakerRequestFormgroup.valid">Submit</button>
        </div>
      </form>
    </mat-step>
  </ng-template>


  <mat-step>
    <p>You are now done.</p>
    <div>
      <!-- <button mat-flat-button matStepperPrevious>Back</button>
      <button mat-flat-button (click)="stepper.reset()">Reset</button> -->
    </div>
  </mat-step>
</mat-horizontal-stepper>

<!--

<div mat-dialog-actions>
  <button mat-flat-button (click)="onNoClick()">No Thanks</button>
  <button mat-flat-button [mat-dialog-close]="data.fullname" cdkFocusInitial>Ok</button>
</div>

-->