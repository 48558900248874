import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FirestoreService } from 'src/app/core/firestore.service';
import { MaterialComponentService } from 'src/app/material/material-component.service';
import { Event, EventProperties } from 'src/app/model/interface';
import {
  applicationType,
  svgpaths,
  event_status,
} from 'src/app/model/constants';

import { catchError, map, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'landing',
  templateUrl: 'home.component.html',
})
export class HomeComponent implements OnInit, AfterViewInit {
  communityInfo: any;
  events: Event[];
  ongoing_events: EventProperties[];
  upcoming_events: EventProperties[];

  applicationType = applicationType;
  featured_event = svgpaths.featured_event;
  wtm_svg = svgpaths.wtm_svg;
  team_svg = svgpaths.team_svg;
  fb_svg = svgpaths.fb_svg;
  twitter_svg = svgpaths.twitter_svg;
  instagram_svg = svgpaths.instagram_svg;

  constructor(
    private materialComponentService: MaterialComponentService,
    private firestoreService: FirestoreService
  ) {}

  ngOnInit() {
    this.getInfo();
    this.getEventsCollection();
  }

  ngAfterViewInit() {}

  getInfo() {
    this.firestoreService
      .getCommunityInfo()
      .get()
      .pipe(
        take(1),
        catchError((error) => {
          this.materialComponentService.errorSnackBar(error);
          return EMPTY;
        })
      )
      .subscribe((info) => {
        this.communityInfo = info.data();
      });
  }

  openApplicationFormDialog(formType: string): void {
    this.materialComponentService.applicationDialog(formType);
  }

  getEventsCollection() {
    this.firestoreService
      .getEventsCollection()
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            key: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        ),
        catchError((error) => {
          this.materialComponentService.errorSnackBar(error);
          return EMPTY;
        })
      )
      .subscribe((allEvents) => {
        this.events = allEvents.reverse();
        this.ongoing_events = this.getOngoingUpcomingEvents(
          this.events,
          event_status.ongoing_status
        ).reverse();
        this.upcoming_events = this.getOngoingUpcomingEvents(
          this.events,
          event_status.upcoming_status
        ).reverse();
      });
  }

  getOngoingUpcomingEvents(arr: Event[], status) {
    return this.firestoreService.getOngoingUpcomingEvents(arr, status);
  }
}
