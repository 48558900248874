import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../../material/material.module';
import { FooterModule } from 'src/app/components/footer/footer.module';

import { TeamComponent } from './team.component';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MaterialModule, FooterModule],
  exports: [TeamComponent],
  declarations: [TeamComponent],
})
export class TeamModule {}
