import { Component, Input } from '@angular/core';
import { Event } from 'src/app/model/interface';

@Component({
  selector: 'recent-events',
  templateUrl: 'recent-events.component.html',
})
export class RecentEventsComponent {
  @Input() recent_events: Event;
  meetup_card_svg = '/assets/svg/inactive-event-card.svg';

  constructor() {}
}
