import { Injectable } from '@angular/core';
import { AuthenticationService } from '../core/authentication.service';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { dialogdataplaceholder } from '../model/constants';
import { Observable } from 'rxjs';

import { ApplicationFormComponent } from 'src/app/components/application-form/application-form.component';

@Injectable({ providedIn: 'root' })
export class MaterialComponentService {
  private sidenav: MatSidenav;
  formDialogRef: MatDialogRef<ApplicationFormComponent, any>;
  user$: Observable<firebase.User> = this.auth.user$;
  user: any;

  constructor(
    private readonly auth: AuthenticationService,
    private readonly snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.user$.subscribe((user) => {
      this.user = !user ? (this.user = dialogdataplaceholder) : user;
    });
  }
  /**
   * Application Form Dialogs
   */
  public applicationDialog(formType) {
    this.formDialogRef = this.dialog.open(ApplicationFormComponent, {
      data: {
        formtype: formType,
        fullname: this.user.displayName,
        uid: this.user.uid,
        email: this.user.email,
      },
      width: '500px',
      minWidth: '300px',
      maxWidth: '90vw',
    });
    // this.formDialogRef.addPanelClass(this.breakpointView);
    this.formDialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      // this.animal = result;
    });

    // return this.formDialogRef;
  }

  /**
   * SnackBars
   */
  public signInSuccessSnackBar() {
    this.snackBar.open(`We're glad you're here! 😎`, 'Close', {
      duration: 5000,
    });
  }

  public signOutSnackBar() {
    this.snackBar.open('Come back soon! 😁', 'Close', {
      duration: 5000,
    });
  }

  public submitSuccessSnackBar() {
    this.snackBar.open('Request submitted 👍', 'Close', {
      duration: 5000,
    });
  }

  public errorSnackBar(error) {
    this.snackBar.open(`${error.message} 😢`, 'Close', {
      duration: 4000,
    });
  }

  /**
   * SideNav
   */
  public setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  public toggle(): void {
    this.sidenav.toggle();
  }
}
