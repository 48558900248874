import { Injectable } from '@angular/core';

import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import {
  Event,
  EventHost,
  CommunityInfo,
  ApplicationFormData,
} from '../model/firestore.model';

import { EventProperties } from '../model/interface';

@Injectable({ providedIn: 'root' })
export class FirestoreService {
  private communityInfoCollectionPath = '/gdg-baguio';
  private eventsCollectionPath = '/events';
  private hostsCollectionPath = '/hosts';
  private applicationFormCollectionPath = '/group-applications';
  private communityID = 'eks9bb0yvgdB2djqXn1Y';

  eventRef: AngularFirestoreCollection<Event> = null;
  hostRef: AngularFirestoreCollection<EventHost> = null;
  infoRef: AngularFirestoreCollection<CommunityInfo> = null;
  applicationFormRef: AngularFirestoreCollection<ApplicationFormData> = null;

  constructor(private firestore: AngularFirestore) {
    this.eventRef = this.firestore.collection(this.eventsCollectionPath);
    ``;
    this.hostRef = firestore.collection(this.hostsCollectionPath);
    this.infoRef = firestore.collection(this.communityInfoCollectionPath);
    this.applicationFormRef = firestore.collection(
      this.applicationFormCollectionPath
    );
  }

  mergeMeetupEventsToFirestore(event: Event): void {
    this.eventRef.doc<Event>(event.id).set(event, { merge: true });
  }

  mergeMeetupEventHostsToFirestore(hosts: EventHost): void {
    this.hostRef.doc<EventHost>(hosts.id).set(hosts, { merge: true });
  }

  getEventsCollection() {
    return this.eventRef.snapshotChanges();
  }

  getOngoingUpcomingEvents(arr: Event[], status: string) {
    var dataCollection = [];

    for (let index = 0; index < arr.length; index++) {
      if (arr[index].status == status) {
        var event = {} as EventProperties;
        var eventObj = {} as Event;
        var eventProperties = {} as any;
        var eventButtons = {};

        // eventObj.id = arr[index].id;
        // eventObj.name = arr[index].name;
        // eventObj.local_date = arr[index].local_date;
        // eventObj.waitlist_count = arr[index].waitlist_count;
        // eventObj.yes_rsvp_count = arr[index].yes_rsvp_count;
        // eventObj.venue = arr[index].venue;
        // eventObj.link = arr[index].link;
        // eventObj.rsvp_limit = arr[index].rsvp_limit;
        // eventObj.short_description = arr[index].short_description;
        eventObj = arr[index];
        eventObj.description = arr[index].description
          .slice(0, 770)
          .concat(' ...');
        event.event = eventObj;

        if (
          arr[index].rsvp_limit < arr[index].yes_rsvp_count ||
          (arr[index].rsvp_limit == 0 && arr[index].yes_rsvp_count == 0)
        ) {
          eventButtons['registration'] = true;
          eventButtons['waitlist'] = false;
        } else if (
          arr[index].rsvp_limit == arr[index].yes_rsvp_count &&
          !(arr[index].rsvp_limit == 0 && arr[index].yes_rsvp_count == 0)
        ) {
          eventButtons['registration'] = false;
          eventButtons['waitlist'] = true;
        }
        eventProperties = eventButtons;
        event.properties = eventProperties;
        dataCollection.push(event);
      }
    }
    return dataCollection;
  }

  getPastEvents(arr: Event[]) {
    var pastEventCollection = [];

    for (let index = 0; index < arr.length; index++) {
      if (arr[index].status == 'past') {
        var eventObj = {} as Event;
        eventObj = arr[index];
        pastEventCollection.push(eventObj);
      }
    }
    return pastEventCollection;
  }

  getRecentEvents(arr: Event[]) {
    var recentEventCollection = [];
    for (let index = 0; index < 4; index++) {
      var eventObj = {} as Event;
      eventObj = arr[index];
      eventObj.description = arr[index].description
        .slice(0, 150)
        .concat(' ...');
      var date = new Date(arr[index].local_date).toDateString();
      eventObj.local_date = date;
      recentEventCollection.push(eventObj);
    }
    return recentEventCollection;
  }

  getEventDetails(event_id: string): AngularFirestoreDocument<Event> {
    return this.eventRef.doc(event_id);
  }

  getEventHosts(event_id: string): AngularFirestoreDocument<EventHost> {
    return this.hostRef.doc(event_id);
  }

  getCommunityInfo(): AngularFirestoreDocument<CommunityInfo> {
    return this.infoRef.doc(this.communityID);
  }

  createApplicationRequest(formdata: ApplicationFormData) {
    this.applicationFormRef
      .add({ ...formdata })
      .then(function () {
        console.log('Document successfully written!');
      })
      .catch(function (error) {
        console.error('Error writing document: ', error);
      });
  }
}
