<div class="site-article">
  <section class="article-row core-team">
    <div class="row-group">
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex>
          <h1>Core Team</h1>
          <p>We would like to introduce to you the people that help in making all our awesome events possible, from the preparation to the setup of
            the places we use to connect with the developers in our community!</p>
        </div>
      </div>
    </div>
    <div class="row-group core-team-row">
      <div fxLayout="row wrap" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap="40px grid">
        <div fxFlex="25" class="team-member">
          <mat-card class="member-card">
            <img mat-card-image src={{van_photo}} alt="Photo of a Shiba Inu">
            <mat-card-content>
              <mat-card-title>Vanessa May Orpilla</mat-card-title>
              <mat-card-subtitle>Community Manager <br> Corporate Relations & Partnership Head</mat-card-subtitle>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxFlex="25" class="team-member">
          <mat-card class="member-card">
            <img mat-card-image src={{roselle_photo}} alt="Photo of a Shiba Inu">
            <mat-card-content>
              <mat-card-title>Roselle Angelica Gumnad</mat-card-title>
              <mat-card-subtitle>Community Manager</mat-card-subtitle>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxFlex="25" class="team-member">
          <mat-card class="member-card">
            <img mat-card-image src={{reymart_photo}} alt="Photo of a Shiba Inu">
            <mat-card-content>
              <mat-card-title>Reymart Canuel</mat-card-title>
              <mat-card-subtitle>Lead volunteer</mat-card-subtitle>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxFlex="25" class="team-member">
          <mat-card class="member-card">
            <img mat-card-image src={{kim_photo}} alt="Photo of a Shiba Inu">
            <mat-card-content>
              <mat-card-title>Kimberly Joyce De Guzman</mat-card-title>
              <mat-card-subtitle>Women Techmaker Ambassador</mat-card-subtitle>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxFlex="25" class="team-member">
          <mat-card class="member-card">
            <img mat-card-image src={{macs_photo}} alt="Photo of a Shiba Inu">
            <mat-card-content>
              <mat-card-title>Denmark Macam</mat-card-title>
              <mat-card-subtitle>Tech Development Head</mat-card-subtitle>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxFlex="25" class="team-member">
          <mat-card class="member-card">
            <img mat-card-image src={{vj_photo}} alt="Photo of a Shiba Inu">
            <mat-card-content>
              <mat-card-title>Verne Jules Cabading</mat-card-title>
              <mat-card-subtitle>Tech Development Associate</mat-card-subtitle>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxFlex="25" class="team-member">
          <mat-card class="member-card">
            <img mat-card-image src={{bryan_photo}} alt="Photo of a Shiba Inu">
            <mat-card-content>
              <mat-card-title>Bryan Ribo</mat-card-title>
              <mat-card-subtitle>Tech Development Associate</mat-card-subtitle>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxFlex="25" class="team-member">
          <mat-card class="member-card">
            <img mat-card-image src={{kurt_photo}} alt="Photo of a Shiba Inu">
            <mat-card-content>
              <mat-card-title>Kurt Renzo Acosta</mat-card-title>
              <mat-card-subtitle>Tech Development Associate</mat-card-subtitle>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxFlex="25" class="team-member">
          <mat-card class="member-card">
            <img mat-card-image src={{janine_photo}} alt="Photo of a Shiba Inu">
            <mat-card-content>
              <mat-card-title>Janine Fernandez</mat-card-title>
              <mat-card-subtitle>Logistics Lead</mat-card-subtitle>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxFlex="25" class="team-member">
          <mat-card class="member-card">
            <img mat-card-image src={{clifford_photo}} alt="Photo of a Shiba Inu">
            <mat-card-content>
              <mat-card-title>Clifford Mcrey Benbinen</mat-card-title>
              <mat-card-subtitle>Documentations Head</mat-card-subtitle>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </section>
  <footer></footer>
</div>