import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { MaterialComponentService } from '../../material/material-component.service';

@Component({
  selector: 'top-bar',
  templateUrl: './top-bar.component.html',
})
export class TopBarComponent {
  @Input() url: string;
  @Input() breakpointView: any;
  @Output() signInEvent = new EventEmitter();
  @Output() logoutEvent = new EventEmitter();

  user$: Observable<firebase.User> = this.auth.user$;
  toggleActive: boolean = false;
  hidden = false;
  logo = '/assets/logo/gdgbaguio-logo.svg';
  progresValue: any;
  scrolltopvalue: number;

  constructor(
    private readonly auth: AuthenticationService,
    private materialComponentService: MaterialComponentService
  ) {}

  @HostListener('window:scroll', [])
  onWindowScroll() {
    var element = document.documentElement,
      body = document.body,
      scrollTop = 'scrollTop',
      scrollHeight = 'scrollHeight';
    this.progresValue =
      ((element[scrollTop] || body[scrollTop]) /
        ((element[scrollHeight] || body[scrollHeight]) -
          element.clientHeight)) *
      100;
    this.scrolltopvalue = element[scrollTop];
    // console.log('scroll TOP---' + element[scrollTop]);
    // if (element[scrollTop] > 0) {
    //   this.isfixed = true;
    // }
    // if ((element[scrollTop] = 0)) {
    //   this.isfixed = false;
    // }
    // console.log('scroll HEIGHT---' + element[scrollHeight]);
  }

  toggleMenu() {
    this.toggleActive = !this.toggleActive;
    this.materialComponentService.toggle();
    // console.log('Clicked');
  }

  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }

  signInWithGoogle() {
    this.signInEvent.emit(null);
  }

  logout() {
    this.logoutEvent.emit(null);
  }
}
