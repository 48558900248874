import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../../material/material.module';

import { CommunityComponent } from './community.component';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MaterialModule],
  exports: [CommunityComponent],
  declarations: [CommunityComponent],
})
export class CommunityModule {}
