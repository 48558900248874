<section class="article-row">
  <div class="row-group">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="50px">
      <div fxFlex>
        <h3>GDG Baguio</h3>
        <p><a href="">About</a></p>
        <p><a href="">Community</a></p>
        <p><a href="/events">Events</a></p>
        <p><a href="">Team</a></p>
      </div>
      <div fxFlex>
        <h3>Get in the way</h3>
        <p><a href="#speakersponsor">Become a sponsor</a></p>
        <p><a href="#speakersponsor">Request Speakers</a></p>
        <p><a href="#womentechmakers">Women Techmakers</a></p>
        <p><a href="#volunteer">Become a volunteer</a></p>
      </div>
    </div>
  </div>
</section>
<section class="article-row copyright">
  <div class="row-group">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="85">
        <img src={{logo}} alt="gdg-baguio" style="height: 25px;">
      </div>
      <div fxFlex="15">
        <span>©{{year}}</span>
      </div>
    </div>
  </div>
</section>

<section class="article-row developer">
  <div>
    <a href="https://angular.io/" target="_blank"><img src={{angular}} alt="angular"></a>
    <a href="https://firebase.google.com/" target="_blank"><img src={{firebase}} alt="firebase"></a>
    <a href="https://material.io/" target="_blank"><img src={{mdc}} alt="material design"></a>
    Designer/Developer: <a href="https://www.linkedin.com/in/denmark-macam-82504792" target="_blank">Denmark Macam</a>
  </div>
</section>