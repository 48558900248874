import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'community',
  templateUrl: 'community.component.html',
})

export class CommunityComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
